import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../api';
import { ExerciseType } from '../../entities/exerciseSession';
import { TagCategory } from '../../entities/tag/tag-category.entity';
import { Tag, TagCreationDto, TagUpdateDto } from '../../entities/tag/tag.entity';

@Injectable({
    providedIn: 'root',
})
export class TagsService {
    constructor(protected http: HttpClient) {}

    async getTagCategories(exerciseType?: ExerciseType): Promise<TagCategory[]> {
        const url = `${ApiService.url}tagCategories`;
        let queryParams = '';
        if (exerciseType) queryParams = `${queryParams}exerciseType=${exerciseType}`;

        return this.http.get<TagCategory[]>(url + '?' + queryParams).toPromise();
    }

    async createTagCategories(tagCategory?: TagCategory): Promise<TagCategory> {
        return this.http.post<TagCategory>(`${ApiService.url}tagCategories`, tagCategory).toPromise();
    }

    async updateTagCategories(tagCategory?: TagCategory): Promise<TagCategory[]> {
        return this.http.put<TagCategory[]>(`${ApiService.url}tagCategories`, tagCategory).toPromise();
    }

    async deleteTagCategories(tagCategoryUuid?: string) {
        return this.http.delete(`${ApiService.url}tagCategories/${tagCategoryUuid}`).toPromise();
    }

    async createTag(dto: TagCreationDto): Promise<Tag> {
        return this.http.post<Tag>(`${ApiService.url}tags`, dto).toPromise();
    }

    async updateTag(dto: TagUpdateDto): Promise<Tag> {
        return this.http.put<Tag>(`${ApiService.url}tags`, dto).toPromise();
    }

    async deleteTag(tagUuid?: string) {
        return this.http.delete(`${ApiService.url}tags/${tagUuid}`).toPromise();
    }
}
