import { Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { ConfigService } from '../../../config/services';
import { FormBuilder, FormGroup, NgControl } from '@angular/forms';
import { TherapyTemplate } from '../../entities/therapy/therapy-template';
import {
    ActionEmitter,
    ActionType,
    ButtonItemAdapterComponent,
    ItemType,
    TableConfig,
} from '../../../table/entities/table';
import { MediaContentAdapterComponent } from '../../../table/components/table-adapter/media-content-adapter.component';
import { StringItemAdapterComponent } from '../../../table/components/table-adapter/string-item-adapter.component';
import { Content } from '../../entities/content';
import { UPLOAD_MIME_TYPE_WHITELIST } from '../../../common/entities/white-list-mime-type';
import { FileItem } from 'ng2-file-upload';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { LoadingService } from '../../../common/services/loading/loading.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Logger } from '../../../logging/logging.service';
import { ModalController } from '@ionic/angular';
import { ProgramArticle } from '../../entities/webshop/program-article.entity';
import { TherapyTemplatesService } from '../../services/therapy-templates';
import {
    TherapyTemplateContentMetadata,
    UpdateTherapyTemplateContentDto,
} from '../../entities/therapy/therapy-template-content.model';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { LoadingProcess, LoadingStates } from '../../../table/components/curafida-table/loading.process';
import { ContentDetailModalComponent } from '../../../shop/components/content-detail-modal/content-detail-modal.component';

@Component({
    selector: 'lib-template-content-form',
    templateUrl: './template-content-form.component.html',
    styleUrls: ['./template-content-form.component.scss'],
})
export class TemplateContentFormComponent {
    isMobile: boolean;
    @Input()
    isShopEnabled: boolean;
    @Input()
    therapyTemplate: TherapyTemplate;

    @Input()
    isLoading: LoadingStates = LoadingProcess.initLoadingProcess();
    @Input()
    therapyForm: FormGroup;
    @Input()
    isNew: boolean;
    @Input()
    isUploadingMediaFile = false;
    @Input()
    isEditEnabled: boolean;
    @Input()
    isNewTherapyTemplate: boolean;
    @Input()
    article: ProgramArticle;
    @Input()
    therapyTemplateForm: FormGroup;

    whiteListMimeType = UPLOAD_MIME_TYPE_WHITELIST.join();
    // ngModel item
    therapyTemplateMediaTableConfig: TableConfig<Content[]> = new TableConfig<Content[]>();
    uploadProgress = 0;
    @Output()
    toggleEmit = new EventEmitter();
    @Output()
    cancelEmit = new EventEmitter();
    @Output()
    updateEmit = new EventEmitter();
    @Output()
    createEmit = new EventEmitter();
    protected readonly log: Logger;

    constructor(
        public configService: ConfigService,
        private therapyTemplatesService: TherapyTemplatesService,
        private formBuilder: FormBuilder,
        // Retrieve the dependency only from the local injector,
        // not from parent or ancestors.
        @Self()
        // We want to be able to use the component without a form,
        // so we mark the dependency as optional.
        @Optional()
        public ngControl: NgControl,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private modalController: ModalController,
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    editTherapyTemplateMediaTable(): void {
        if (this.therapyTemplateMediaTableConfig?.itemSettings?.length > 0) {
            const firstColumn = this.therapyTemplateMediaTableConfig.itemSettings[0];
            firstColumn.type = ItemType.REORDER;

            const deleteColumn = this.therapyTemplateMediaTableConfig.itemSettings.find(
                (i) => i.actionType === ActionType.DELETE,
            );
            deleteColumn.disabled = this.isEditEnabled;

            this.therapyTemplateMediaTableConfig.isReorderDisable = this.isEditEnabled;
        }
    }

    toggleEdit(isEditEnabled: boolean): void {
        const uploadedMediaFile = this.therapyForm.get('uploadedMediaFile');
        isEditEnabled ? uploadedMediaFile.enable() : uploadedMediaFile.disable();
    }

    async initTherapyTemplateMediaTable(therapyTemplate: TherapyTemplate): Promise<void> {
        this.therapyTemplateMediaTableConfig.isReorderDisable = true;
        this.therapyTemplateMediaTableConfig.emptyListLabel = 'CONTENT.ANY_THUMBNAIL';
        this.therapyTemplateMediaTableConfig.isOpenDetailEnable = false;
        if (therapyTemplate.contents) {
            this.therapyTemplateMediaTableConfig.list.items = Array.from(therapyTemplate.contents).sort((a, b) => {
                // Put Contents without a defined position at the end of the table
                if (a.jsonData?.ordinal === undefined) {
                    if (b.jsonData?.ordinal === undefined) {
                        return 0;
                    } else {
                        return 1;
                    }
                } else if (b.jsonData?.ordinal === undefined) {
                    return -1;
                }

                return a.jsonData.ordinal - b.jsonData.ordinal;
            });

            for (const content of this.therapyTemplateMediaTableConfig.list.items) {
                content.url = await this.therapyTemplatesService.createTempDownloadUrl(content.uuid, true);
            }
            this.therapyTemplateMediaTableConfig.list = PaginatedResponse.init(
                this.therapyTemplateMediaTableConfig.list.items,
            );

            this.therapyTemplateMediaTableConfig.itemSettings = [
                { id: '', prop: '', header: '', type: ItemType.NUMBERING, width: '10%', columnPosition: 0 },
                {
                    id: 'thumbnail',
                    prop: 'url',
                    header: 'Medium',
                    type: ItemType.ADAPTER,
                    adapter: MediaContentAdapterComponent,
                    actionType: ActionType.OPEN_NEW_PAGE,
                    width: '20%',
                    disabled: true,
                    columnPosition: 1,
                },
                {
                    id: 'description',
                    prop: 'description',
                    header: 'Beschreibung',
                    type: ItemType.ADAPTER,
                    adapter: StringItemAdapterComponent,
                    actionType: ActionType.OPEN_NEW_PAGE,
                    width: '55%',
                    columnPosition: 2,
                },
                {
                    id: 'action_delete',
                    prop: 'id',
                    header: '',
                    type: ItemType.ADAPTER,
                    adapter: ButtonItemAdapterComponent,
                    icon: 'trash',
                    actionType: ActionType.DELETE,
                    width: '15%',
                    color: 'danger',
                    columnPosition: 3,
                    disabled: true,
                },
            ];
        }
        this.therapyTemplate = therapyTemplate;
        this.isLoading = LoadingProcess.finishedSuccessfullyLoadingProcess();
    }

    registerOnChange(): void {
        this.onChange();
    }

    registerOnTouched(): void {
        this.onTouched();
    }

    writeValue(obj: any): void {
        this.therapyForm = obj;
    }

    async addMediaFileToTherapyTemplate(fileUploadEvent: FileItem): Promise<void> {
        // Prepare uploading
        const uploadMediaFileControl = this.therapyForm.get('uploadedMediaFile');
        uploadMediaFileControl.setValue(fileUploadEvent.file.name);
        uploadMediaFileControl.markAsDirty();

        const uploadFormData = new FormData();
        uploadFormData.append('file', fileUploadEvent.file.rawFile as File);

        // Upload the media file and add it to the table
        this.isUploadingMediaFile = true;
        this.loadingService.startLoadingModal('Datei wird hochgeladen.');

        this.uploadMediaFileForTherapyTemplate(uploadFormData)
            .then((newContent: Content) =>
                this.updateContentPosition(newContent, this.therapyTemplateMediaTableConfig.list.total),
            )
            .then(async (newContent: Content) => {
                await this.addContentToArticleContentTable(newContent);
                await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
            })
            .catch(async (error) => {
                console.error('An error occurred while adding a media file to the article', error);
                await this.toastService.showToast(ToastService.errorMessageSave, IonicColor.danger);
            })
            .finally(() => {
                uploadMediaFileControl.setValue('');
                this.isUploadingMediaFile = false;
                this.loadingService.stopLoadingModal();
                this.cancelEdit();
            });
    }

    cancelEdit() {
        if (this.isEditEnabled) {
            this.cancelEmit.emit();
        }
    }

    async setActionTherapyTemplateMediaTable(actionEmitter: ActionEmitter<Content>): Promise<void> {
        if (this.isEditEnabled) {
            if (actionEmitter.actionType === ActionType.OPEN_NEW_PAGE) {
                await this.openWebshopContentDetails(actionEmitter.item);
            } else if (actionEmitter.actionType === ActionType.DELETE && this.isEditEnabled) {
                return this.deleteTherapyTemplateContent(actionEmitter.item.uuid)
                    .then(() => this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success))
                    .catch((error) => {
                        console.error(`Error while deleting article Content`, error);
                        this.toastService.showToast(ToastService.errorMessageDelete, IonicColor.danger);
                    });
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private onChange() {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private onTouched() {}

    private async addContentToArticleContentTable(content: Content): Promise<void> {
        content.url = await this.therapyTemplatesService.createTempDownloadUrl(content.uuid, true);

        if (this.therapyTemplate.contents === null || this.therapyTemplate.contents === undefined) {
            this.therapyTemplate.contents = [content];
        } else {
            this.therapyTemplate.contents.push(content);
        }
        if (!this.therapyTemplateMediaTableConfig.list.items) this.therapyTemplateMediaTableConfig.list.items = [];
        this.therapyTemplateMediaTableConfig.list.items.push(content);
        this.therapyTemplateMediaTableConfig.list.count++;
        this.therapyTemplateMediaTableConfig.list.total++;
        this.therapyTemplateMediaTableConfig.list.limit++;
    }

    private async updateContentPosition(content: Content, position: number): Promise<Content> {
        let articleUuid: string;
        const updateDto = new UpdateTherapyTemplateContentDto();
        if (this.isShopEnabled && this.article) {
            articleUuid = this.article.uuid;
        }
        updateDto.jsonData = new TherapyTemplateContentMetadata(position);

        return this.therapyTemplatesService.updateContent(content.uuid, updateDto, articleUuid);
    }

    private async uploadMediaFileForTherapyTemplate(formData: FormData): Promise<Content> {
        return new Promise((resolve, reject) => {
            // Upload and handle progress events
            this.therapyTemplatesService
                .uploadMediaFileForTherapyTemplate(formData, this.therapyTemplate.id, this.article?.uuid)
                .subscribe(
                    (event: HttpEvent<Content>) => {
                        switch (event.type) {
                            case HttpEventType.Sent:
                                this.uploadProgress = 0;
                                break;
                            case HttpEventType.UploadProgress:
                                this.uploadProgress = event.loaded / event.total;
                                break;
                            case HttpEventType.Response:
                                resolve(event.body);
                                break;
                        }
                    },
                    (error) => {
                        this.loadingService.stopLoadingModal();
                        console.error('error: ', error);
                        reject(error);
                    },
                );
        });
    }

    private async deleteTherapyTemplateContent(contentUuid: string): Promise<void> {
        let articleUuid: string;
        const courseContentsIndex = this.therapyTemplate.contents.findIndex((c) => c.uuid === contentUuid);
        if (courseContentsIndex === -1) {
            return Promise.reject(`The Course does not have a Content with UUID ${contentUuid}`);
        }
        if (this.isShopEnabled && this.article) {
            articleUuid = this.article.uuid;
        }
        try {
            await this.therapyTemplatesService.deleteContent(contentUuid, articleUuid);
            this.therapyTemplate.contents.splice(courseContentsIndex, 1);

            const tableData = this.therapyTemplateMediaTableConfig.list;
            const tableIndex = tableData.items.findIndex((c) => c.uuid === contentUuid);
            if (tableIndex > -1) {
                tableData.items.splice(tableIndex, 1);
                tableData.count = tableData.limit = tableData.total = tableData.items.length;
            }
        } catch (e) {
            this.log.error(e);
            await this.toastService.showToast(
                'Beim Erstellen der Vorlage ist ein Fehler aufgetreten.',
                IonicColor.danger,
            );
        } finally {
            this.cancelEdit();
        }
    }

    private async openWebshopContentDetails(content: Content): Promise<void> {
        const modal = await this.modalController.create({
            component: ContentDetailModalComponent,
            cssClass: '',
            componentProps: {
                content,
            },
        });
        await modal.present();

        await modal.onDidDismiss();
    }
}
