// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kiosk-training-exercise-info {
  z-index: 100;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.kiosk-training-center-div {
  width: 700px;
  height: 670px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.kiosk-training-exercise-number {
  width: 3rem;
  height: 3rem;
  --background: #d9d9d9 !important;
  --color: black;
}

.kiosk-training-exercise-info {
  margin-top: 10px;
}

.kiosk-training-exercise-image {
  max-height: 200px;
}`, "",{"version":3,"sources":["webpack://./../../libs/kiosk/components/kiosk-training-modal/kiosk-training-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EAEA,gCAAA;EACA,cAAA;AAAJ;;AAGA;EACI,gBAAA;AAAJ;;AAGA;EACI,iBAAA;AAAJ","sourcesContent":[".kiosk-training-exercise-info {\n    z-index: 100;\n    position: absolute;\n    bottom: 20px;\n    left: 20px;\n}\n\n.kiosk-training-center-div {\n    width: 700px;\n    height: 670px;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}\n\n.kiosk-training-exercise-number {\n    width: 3rem;\n    height: 3rem;\n    //align-content: center;\n    --background: #d9d9d9 !important;\n    --color: black;\n}\n\n.kiosk-training-exercise-info {\n    margin-top: 10px;\n}\n\n.kiosk-training-exercise-image {\n    max-height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
