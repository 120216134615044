import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalConfig } from '../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../common/entities/modal/modal-typ';
import { ButtonConfig } from '../../../common/entities/modal/modal-button';
import { ModalAlertService } from '../../../common/services/modal';
import { User, UserRoles } from '../../../auth/entities/user';
import { StyleService } from '../../../common/services/style/style.service';
import { UsersService } from '../../services/user';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { ConfigService } from '../../../config/services';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { RoutingSegment } from '../../../common/entities/routing-segment';
import { SupervisorService } from '../../services/supervisor';
import { CurafidaAuthService } from '../../../auth/services';
import { defaultInputValidator, noWhitespaceValidator } from '../../../common/validators/curafida-validators';
import { CreateGroupDto, Group, GroupCategory, GroupLevel, UpdateGroupDto } from '../../entities/group';
import { GroupService } from '../../services/group';
import { BasePageConfig } from '../../../config/entities';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { Logger, LoggingService } from '../../../logging/logging.service';
import {
    CreateProvidedServiceDto,
    OrderSettings,
    ProvidedService,
    UpdateOrderSettingsDto,
    UpdateProvidedServiceDto,
} from '../../../order/entities/provided-service';
import { ConsultationSettingsModalComponent } from '../../../consultation/components/consultation-settings-modal/consultation-settings-modal.component';
import {
    ActionEmitter,
    ActionType,
    ButtonItemAdapterComponent,
    ItemType,
    TableConfig,
} from '../../../table/entities/table';
import { StringItemAdapterComponent } from '../../../table/components/table-adapter/string-item-adapter.component';
import { ConsultationSettings, UpdateConsultationSettingsDto } from '../../../consultation/entities/provided-service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CurafidaSession } from '../../../auth/entities/user/curafida-session';
import { Subscription } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'group-main-info',
    templateUrl: './group-main-info.component.html',
    styleUrls: ['./group-main-info.component.scss'],
})
export class GroupMainInfoComponent implements OnChanges {
    @Input()
    isOrganization: boolean;
    @Input()
    pageConfig: BasePageConfig;
    @Output()
    updateTitle: EventEmitter<{ name: string; shortName?: string }> = new EventEmitter<{
        name: string;
        shortName?: string;
    }>();
    loggedInUser: User;
    isMobile = false;
    deleteString: string;
    category = [
        { value: GroupCategory.NO_CATEGORY, label: 'NO_CATEGORY' },
        { value: GroupCategory.CENTRAL_FACILITY, label: 'CENTRAL_FACILITY' },
        { value: GroupCategory.COOPERATION_FACILITY, label: 'COOPERATION_FACILITY' },
    ];
    ordersEnabled = [
        { value: false, label: 'Deaktiviert' },
        {
            value: true,
            label: 'Aktiviert',
            subtitle: '(Ihre Gruppe kann beim Auftrag als Auftragnehmer ausgewählt werden.)',
        },
    ];
    orderJustifyingIndication = [
        { value: false, label: 'Deaktiviert' },
        {
            value: true,
            label: 'Aktiviert',
            subtitle: '(Bei Annahme eines Auftrags erfolgt die Abfrage, ob eine rechtfertigende Indikation vorliegt.)',
        },
    ];
    orderProvidedServicesEnabled = [
        { value: false, label: 'Deaktiviert' },
        {
            value: true,
            label: 'Aktiviert',
            subtitle: '(Bei Annahme eines Auftrags erfolgt eine Abfrage, welche Leistungen angefordert werden sollen.)',
        },
    ];
    consultationsEnabled = [
        { value: false, label: 'Deaktiviert' },
        {
            value: true,
            label: 'Aktiviert',
            subtitle: '(Ihre Gruppe kann beim Konsil als Konsildienst ausgewählt werden.)',
        },
    ];
    consultationProvidedServicesEnabled = [
        { value: false, label: 'Deaktiviert' },
        {
            value: true,
            label: 'Aktiviert',
            subtitle:
                '(Bei der Annahme eines Konsils erfolgt eine Abfrage, welche Leistungen angefordert werden sollen.)',
        },
    ];
    orderSettings: OrderSettings;
    consultationSettings: ConsultationSettings;
    consultationServicesTable: TableConfig<ProvidedService[]> = new TableConfig<ProvidedService[]>();
    clientServicesTable: TableConfig<ProvidedService[]> = new TableConfig<ProvidedService[]>();
    providerServicesTable: TableConfig<ProvidedService[]> = new TableConfig<ProvidedService[]>();
    organizationsOfUser: PaginatedResponse<Group[]>;
    parentList = [];
    isEditEnabled = false;
    groupForm: FormGroup;
    consultationSettingsForm: FormGroup;
    orderSettingsForm: FormGroup;
    isNewGroup: boolean;
    isGroupManager: boolean;
    isOrganizationManager: boolean;
    RoutingSegment = RoutingSegment;
    UserRoles = UserRoles;
    GroupCategory = GroupCategory;
    session: CurafidaSession;
    protected readonly log: Logger;
    private langSubscription: Subscription;

    constructor(
        protected modalCtrl: ModalController,
        public router: Router,
        private modalAlertService: ModalAlertService,
        public route: ActivatedRoute,
        protected formBuilder: FormBuilder,
        private translate: TranslateService,
        private styleService: StyleService,
        protected usersService: UsersService,
        protected groupService: GroupService,
        protected supervisorService: SupervisorService,
        private toastService: ToastService,
        private authService: CurafidaAuthService,
        public configService: ConfigService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.initStrings();
        this.langSubscription = this.translate.onLangChange.subscribe(() => {
            this.initStrings();
        });
    }

    private _group: Group;

    get group(): Group {
        return this._group;
    }

    @Input()
    set group(value: Group) {
        this._group = value;
    }

    // OnChanges is used because the component initializes before it receives the input
    async ngOnChanges() {
        this.session = await this.authService.getSession();
        this.loggedInUser = this.session.user;
        this.isGroupManager = this.usersService.isUserGroupManager(this.loggedInUser);
        this.isOrganizationManager = this.usersService.isUserOrganizationManager(this.loggedInUser);
        this.isMobile = this.styleService.isMobile$;
        this.isNewGroup = this.route.snapshot.paramMap.get('groupPath') === 'new';
        if (this.isOrganization) {
            await this.initOrgForm();
        } else {
            if (!this.isNewGroup) {
                await this.getConsultationSettings();
                await this.getOrderSettings();
            }
            await this.getParentList();
            await this.initGroupForm();
        }
        if (this.isNewGroup) {
            this.isEditEnabled = true;
            this.group = new Group();
        }
    }

    updateGroupEntity() {
        this.group.name = this.groupForm.value.name ? this.groupForm.value.name : this.group.name;
        this.group.shortName = this.groupForm.value.shortName ? this.groupForm.value.shortName : this.group.shortName;
        this.group.parent = this.groupForm.value.parent ? this.groupForm.value.parent : this.group.parent;
        this.group.category =
            this.groupForm.value.category !== GroupCategory.NO_CATEGORY
                ? this.groupForm.value.category
                : this.group.category;
    }

    async saveGroupInformation() {
        if (this.groupForm.valid && this.groupForm.dirty) {
            this.updateGroupEntity();
            if (this.isNewGroup) {
                try {
                    if (this.isOrganization) {
                        const newOrgDto = await this.createOrgDto();
                        const newOrgParentPath = await this.authService.getTenantId();
                        this.group = await this.groupService.postGroup(newOrgDto, newOrgParentPath);
                        this.isNewGroup = false;
                    } else {
                        const newGroupDto = this.createGroupDto();
                        const newGroupParentPath = await this.getParentPath(newGroupDto.parent);
                        this.group = await this.groupService.postGroup(newGroupDto, newGroupParentPath);
                        this.isNewGroup = false;
                    }
                    await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
                    if (this.isOrganization) {
                        await this.router.navigate(
                            [
                                RoutingSegment.MEMBER,
                                RoutingSegment.ADMINISTRATION,
                                RoutingSegment.ORGANIZATION,
                                RoutingSegment.DETAIL,
                                this.group.path,
                            ],
                            { replaceUrl: true },
                        );
                    } else {
                        await this.router.navigate(
                            [
                                RoutingSegment.MEMBER,
                                RoutingSegment.ADMINISTRATION,
                                RoutingSegment.ORGANIZATION,
                                RoutingSegment.GROUP,
                                RoutingSegment.DETAIL,
                                this.group.path,
                            ],
                            { replaceUrl: true },
                        );
                    }
                } catch (e) {
                    this.log.error('Error in saveGroupInformation', e);
                    await this.toastService.showToast(
                        'Beim Speichern der Daten ist ein Fehler aufgetreten',
                        IonicColor.danger,
                    );
                }
            } else {
                try {
                    let newGroup: Group;
                    if (this.isOrganization) {
                        newGroup = await this.groupService.putGroup(this.updateOrgDto(), this.group.path);
                    } else {
                        newGroup = await this.groupService.putGroup(this.updateGroupDto(), this.group.path);
                    }
                    this.group = newGroup;
                    this.updateTitle.emit({
                        name: newGroup.name,
                        shortName: newGroup.shortName,
                    });
                    this.toggleEdit();
                    await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
                } catch (e) {
                    this.log.error('Error in saveGroupInformation', e);
                    await this.toastService.showToast(
                        'Beim Speichern der Daten ist ein Fehler aufgetreten',
                        IonicColor.danger,
                    );
                }
            }
        }
        if (this.group.category === GroupCategory.CENTRAL_FACILITY && !this.isNewGroup) {
            if (this.consultationSettingsForm.valid && this.consultationSettingsForm.dirty) {
                try {
                    this.consultationSettings = await this.groupService.updateConsultationSettings(
                        this.group.path,
                        this.updateConsultationSettingsDto(),
                    );
                    this.toggleEdit();
                    await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
                } catch (e) {
                    this.log.error('Error in saveGroupInformation', e);
                    await this.toastService.showToast(
                        'Beim Speichern der Daten ist ein Fehler aufgetreten',
                        IonicColor.danger,
                    );
                }
            }
            if (this.orderSettingsForm.valid && this.orderSettingsForm.dirty) {
                try {
                    this.orderSettings = await this.groupService.updateOrderSettings(
                        this.group.path,
                        this.updateOrderSettingsDto(),
                    );
                    this.toggleEdit();
                    await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
                } catch (e) {
                    this.log.error('Error in saveGroupInformation', e);
                    await this.toastService.showToast(
                        'Beim Speichern der Daten ist ein Fehler aufgetreten',
                        IonicColor.danger,
                    );
                }
            }
        }
    }

    async cancelEdit() {
        if (this.isEditEnabled) {
            this.toggleEdit();
            if (this.isOrganization) {
                await this.initOrgForm();
            } else {
                await this.initGroupForm();
            }
        }
    }

    async initGroupForm() {
        try {
            this.isEditEnabled = this.group == null;
            const name = this.group == null ? '' : this.group.name;
            const category = this.group?.category == null ? GroupCategory.NO_CATEGORY : this.group.category;
            let groupPath;
            if (this.group != null) {
                groupPath = this.group.path.split('/');
            } else {
                groupPath = this.session.tenantId.split('/');
            }
            const tenantName = this.group == null ? groupPath[groupPath.length - 1] : groupPath[groupPath.length - 3];
            const parent =
                this.group == null ? (this.parentList.length === 1 ? this.parentList[0].value : '') : this.group.parent;
            const consultationsEnabled =
                this.consultationSettings == null ? false : this.consultationSettings.consultationsEnabled;
            const consultationProvidedServicesEnabled =
                this.consultationSettings == null
                    ? false
                    : this.consultationSettings.consultationProvidedServicesEnabled;
            const ordersEnabled = this.orderSettings == null ? false : this.orderSettings.ordersEnabled;
            const orderJustifyingIndicationEnabled =
                this.orderSettings == null ? false : this.orderSettings.justifyingIndicationEnabled;
            const orderProvidedServicesEnabled =
                this.orderSettings == null ? false : this.orderSettings.orderProvidedServicesEnabled;
            this.groupForm = this.formBuilder.group({
                name: new FormControl({ value: name, disabled: !this.isEditEnabled }, [
                    Validators.required,
                    Validators.minLength(5),
                    defaultInputValidator,
                    noWhitespaceValidator,
                    Validators.pattern(/^[^/]*$/),
                ]),
                tenantName: new FormControl({ value: tenantName, disabled: true }),
                parent: new FormControl(
                    { value: parent, disabled: !this.isEditEnabled || this.parentList.length === 1 },
                    [Validators.required, defaultInputValidator],
                ),
                category: new FormControl({ value: category, disabled: !this.isEditEnabled }, [defaultInputValidator]),
            });
            this.consultationSettingsForm = this.formBuilder.group({
                consultationsEnabled: new FormControl({
                    value: consultationsEnabled,
                    disabled: !this.isEditEnabled,
                }),
                consultationProvidedServicesEnabled: new FormControl({
                    value: consultationProvidedServicesEnabled,
                    disabled: !this.isEditEnabled,
                }),
            });
            this.orderSettingsForm = this.formBuilder.group({
                ordersEnabled: new FormControl({
                    value: ordersEnabled,
                    disabled: !this.isEditEnabled,
                }),
                orderJustifyingIndicationEnabled: new FormControl({
                    value: orderJustifyingIndicationEnabled,
                    disabled: !this.isEditEnabled,
                }),
                orderProvidedServicesEnabled: new FormControl({
                    value: orderProvidedServicesEnabled,
                    disabled: !this.isEditEnabled,
                }),
            });
        } catch (e) {
            this.log.error('Error in initGroupForm', e);
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
        }
    }

    async initOrgForm() {
        try {
            this.isEditEnabled = this.group == null;
            const name = this.group == null ? '' : this.group.name;
            let orgPath;
            if (this.group != null) {
                orgPath = this.group.path.split('/');
            } else {
                orgPath = this.session.tenantId.split('/');
            }
            const tenantName = this.group == null ? orgPath[orgPath.length - 1] : orgPath[orgPath.length - 2];
            const shortName = this.group == null ? '' : this.group.shortName;
            this.groupForm = this.formBuilder.group({
                name: new FormControl({ value: name, disabled: !this.isEditEnabled }, [
                    Validators.required,
                    Validators.minLength(5),
                    defaultInputValidator,
                    noWhitespaceValidator,
                    Validators.pattern(/^[^/]*$/),
                ]),
                tenantName: new FormControl({ value: tenantName, disabled: true }),
                shortName: new FormControl({ value: shortName, disabled: !this.isEditEnabled }, [
                    Validators.required,
                    Validators.minLength(2),
                    defaultInputValidator,
                ]),
            });
        } catch (e) {
            this.log.error('Error in initOrgForm', e);
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
        }
    }

    updateGroupDto() {
        const updateGroupDto = new UpdateGroupDto();
        updateGroupDto.uuid = this.group.uuid;
        updateGroupDto.name = this.groupForm.value.name;
        updateGroupDto.parent = this.groupForm.value.parent;
        updateGroupDto.category =
            this.groupForm.value.category !== GroupCategory.NO_CATEGORY ? this.groupForm.value.category : null;
        return updateGroupDto;
    }

    updateOrgDto() {
        const updateOrgDto = new UpdateGroupDto();
        updateOrgDto.uuid = this.group.uuid;
        updateOrgDto.name = this.groupForm.value.name;
        updateOrgDto.parent = this.group.parent;
        updateOrgDto.shortName = this.groupForm.value.shortName;
        return updateOrgDto;
    }

    updateOrderSettingsDto() {
        const updateOrderSettingsDto = new UpdateOrderSettingsDto();
        if (!(this.orderSettingsForm.value.ordersEnabled == null)) {
            updateOrderSettingsDto.ordersEnabled = this.orderSettingsForm.value.ordersEnabled;
        }
        if (!(this.orderSettingsForm.value.orderJustifyingIndicationEnabled == null)) {
            updateOrderSettingsDto.justifyingIndicationEnabled =
                this.orderSettingsForm.value.orderJustifyingIndicationEnabled;
        }
        if (!(this.orderSettingsForm.value.orderProvidedServicesEnabled == null)) {
            updateOrderSettingsDto.orderProvidedServicesEnabled =
                this.orderSettingsForm.value.orderProvidedServicesEnabled;
        }
        return updateOrderSettingsDto;
    }

    updateConsultationSettingsDto() {
        const updateConsultationSettingsDto = new UpdateConsultationSettingsDto();
        if (!(this.consultationSettingsForm.value.consultationsEnabled == null)) {
            updateConsultationSettingsDto.consultationsEnabled =
                this.consultationSettingsForm.value.consultationsEnabled;
        }
        if (!(this.consultationSettingsForm.value.consultationProvidedServicesEnabled == null)) {
            updateConsultationSettingsDto.consultationProvidedServicesEnabled =
                this.consultationSettingsForm.value.consultationProvidedServicesEnabled;
        }
        return updateConsultationSettingsDto;
    }

    createGroupDto() {
        const createGroupDto = new CreateGroupDto();
        createGroupDto.name = this.groupForm.value.name;
        createGroupDto.parent = this.groupForm.controls.parent.value;
        createGroupDto.category =
            this.groupForm.value.category !== GroupCategory.NO_CATEGORY ? this.groupForm.value.category : null;
        return createGroupDto;
    }

    async createOrgDto() {
        try {
            const createOrgDto = new CreateGroupDto();
            createOrgDto.name = this.groupForm.value.name;
            const tenant = await this.groupService.getGroup(this.session.tenantId);
            createOrgDto.parent = tenant.uuid;
            createOrgDto.shortName = this.groupForm.value.shortName;
            createOrgDto.category = GroupCategory.ORGANIZATION;
            return createOrgDto;
        } catch (e) {
            this.log.error('Error in createOrgDto', e);
            await this.toastService.showToast('Beim Speichern der Daten ist ein Fehler aufgetreten', IonicColor.danger);
        }
    }

    toggleEdit() {
        this.isEditEnabled = !this.isEditEnabled;
        const name = this.groupForm.get('name');
        this.isEditEnabled ? name.enable() : name.disable();
        if (this.isOrganization) {
            const shortName = this.groupForm.get('shortName');
            this.isEditEnabled ? shortName.enable() : shortName.disable();
        } else {
            const parent = this.groupForm.get('parent');
            const category = this.groupForm.get('category');
            const consultationsEnabled = this.consultationSettingsForm.get('consultationsEnabled');
            const consultationProvidedServicesEnabled = this.consultationSettingsForm.get(
                'consultationProvidedServicesEnabled',
            );
            const ordersEnabled = this.orderSettingsForm.get('ordersEnabled');
            const orderJustifyingIndicationEnabled = this.orderSettingsForm.get('orderJustifyingIndicationEnabled');
            const orderProvidedServicesEnabled = this.orderSettingsForm.get('orderProvidedServicesEnabled');
            this.isEditEnabled && this.parentList.length > 1 ? parent.enable() : parent.disable();
            this.isEditEnabled ? category.enable() : category.disable();
            if (this.isEditEnabled) {
                consultationsEnabled.enable();
                consultationProvidedServicesEnabled.enable();
                ordersEnabled.enable();
                orderJustifyingIndicationEnabled.enable();
                orderProvidedServicesEnabled.enable();
            } else {
                consultationsEnabled.disable();
                consultationProvidedServicesEnabled.disable();
                ordersEnabled.disable();
                orderJustifyingIndicationEnabled.disable();
                orderProvidedServicesEnabled.disable();
            }
        }
    }

    changeConsultationsEnabled(value) {
        this.consultationSettingsForm.patchValue({ consultationsEnabled: value });
        this.consultationSettingsForm.markAsDirty();
    }

    changeConsultationProvidedServicesActivation(value) {
        this.consultationSettingsForm.patchValue({ consultationProvidedServicesEnabled: value });
        this.consultationSettingsForm.markAsDirty();
    }

    changeOrdersEnabled(value) {
        this.orderSettingsForm.patchValue({ ordersEnabled: value });
        this.orderSettingsForm.markAsDirty();
    }

    changeOrderJustifyingIndicationActivation(value) {
        this.orderSettingsForm.patchValue({ orderJustifyingIndicationEnabled: value });
        this.orderSettingsForm.markAsDirty();
    }

    changeOrderProvidedServicesActivation(value) {
        this.orderSettingsForm.patchValue({ orderProvidedServicesEnabled: value });
        this.orderSettingsForm.markAsDirty();
    }

    async addOrderProvidedServices(isProviderService: boolean, isClientService: boolean) {
        const result = await this.openProvidedServicesModal();
        if (result) {
            const createProvidedGroupServiceDto = new CreateProvidedServiceDto();
            createProvidedGroupServiceDto.name = result.requiredInput;
            createProvidedGroupServiceDto.description = result.optionalInput;
            createProvidedGroupServiceDto.isProviderService = isProviderService;
            createProvidedGroupServiceDto.isClientService = isClientService;
            try {
                await this.groupService.createOrderProvidedService(this.group.path, createProvidedGroupServiceDto);
            } catch (e) {
                this.log.error('Error in addOrderProvidedServices', e);
                await this.toastService.showToast(
                    'Beim Versenden der Daten ist ein Fehler aufgetreten',
                    IonicColor.danger,
                );
            }
            await this.getOrderSettings();
        }
    }

    async removeOrderProvidedServices(providedGroupService: ProvidedService) {
        const result = await this.openDeleteServiceModal(providedGroupService);
        if (result) {
            try {
                await this.groupService.deleteOrderProvidedService(this.group.path, providedGroupService.uuid);
            } catch (e) {
                this.log.error('Error in removeOrderProvidedServices', e);
                await this.toastService.showToast(
                    'Beim Löschen der Daten ist ein Fehler aufgetreten',
                    IonicColor.danger,
                );
            }
            await this.getOrderSettings();
        } else {
            return;
        }
    }

    async editOrderProvidedServices(providedGroupService: ProvidedService) {
        const result = await this.openProvidedServicesModal(providedGroupService);
        if (result) {
            const updateProvidedGroupServiceDto = new UpdateProvidedServiceDto();
            updateProvidedGroupServiceDto.uuid = providedGroupService.uuid;
            updateProvidedGroupServiceDto.name = result.requiredInput;
            updateProvidedGroupServiceDto.description = result.optionalInput;
            updateProvidedGroupServiceDto.isProviderService = providedGroupService.isProviderService;
            updateProvidedGroupServiceDto.isClientService = providedGroupService.isClientService;
            try {
                await this.groupService.updateOrderProvidedService(this.group.path, updateProvidedGroupServiceDto);
            } catch (e) {
                this.log.error('Error in editOrderProvidedServices', e);
                await this.toastService.showToast(
                    'Beim Versenden der Daten ist ein Fehler aufgetreten',
                    IonicColor.danger,
                );
            }
            await this.getOrderSettings();
        }
    }

    async addConsultationProvidedServices() {
        const result = await this.openProvidedServicesModal();
        if (result) {
            const createProvidedGroupServiceDto = new CreateProvidedServiceDto();
            createProvidedGroupServiceDto.name = result.requiredInput;
            createProvidedGroupServiceDto.description = result.optionalInput;
            try {
                await this.groupService.createConsultationProvidedService(
                    this.group.path,
                    createProvidedGroupServiceDto,
                );
            } catch (e) {
                this.log.error('Error in addConsultationProvidedServices', e);
                await this.toastService.showToast(
                    'Beim Versenden der Daten ist ein Fehler aufgetreten',
                    IonicColor.danger,
                );
            }
            await this.getConsultationSettings();
        }
    }

    async removeConsultationProvidedServices(providedGroupService: ProvidedService) {
        const result = await this.openDeleteServiceModal(providedGroupService);
        if (result) {
            try {
                await this.groupService.deleteConsultationProvidedService(this.group.path, providedGroupService.uuid);
            } catch (e) {
                this.log.error('Error in removeConsultationProvidedServices', e);
                await this.toastService.showToast(
                    'Beim Löschen der Daten ist ein Fehler aufgetreten',
                    IonicColor.danger,
                );
            }
            await this.getConsultationSettings();
        } else {
            return;
        }
    }

    async editConsultationProvidedServices(providedGroupService: ProvidedService) {
        const result = await this.openProvidedServicesModal(providedGroupService);
        if (result) {
            const updateProvidedGroupServiceDto = new UpdateProvidedServiceDto();
            updateProvidedGroupServiceDto.uuid = providedGroupService.uuid;
            updateProvidedGroupServiceDto.name = result.requiredInput;
            updateProvidedGroupServiceDto.description = result.optionalInput;
            try {
                await this.groupService.updateConsultationProvidedService(
                    this.group.path,
                    updateProvidedGroupServiceDto,
                );
            } catch (e) {
                this.log.error('Error in editConsultationProvidedServices', e);
                await this.toastService.showToast(
                    'Beim Versenden der Daten ist ein Fehler aufgetreten',
                    IonicColor.danger,
                );
            }
            await this.getConsultationSettings();
        }
    }

    async openProvidedServicesModal(providedGroupService?: ProvidedService) {
        const modal = await this.modalCtrl.create({
            component: ConsultationSettingsModalComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                title: providedGroupService ? 'Anforderbare Leistung bearbeiten' : 'Anforderbare Leistung erstellen',
                requiredInputLabel: 'Name',
                optionalInputLabel: 'Beschreibung',
                requiredInputDefault: providedGroupService ? providedGroupService.name : null,
                optionalInputDefault: providedGroupService ? providedGroupService.description : null,
                isMobile: this.isMobile,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data && data.action === 'right') {
            return data;
        }
    }

    async openDeleteServiceModal(providedGroupService: ProvidedService) {
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.title = this.translate.instant('MEETING.MEETING_TEMPLATE.DELETE_MODAL.TITLE', {
            title: providedGroupService.name,
        });
        modalConfig.titleIcon = 'warning-outline';
        modalConfig.description = this.translate.instant('MEETING.MEETING_TEMPLATE.DELETE_MODAL.DELETE_CONFIRMATION', {
            title: providedGroupService.name,
        });

        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'Löschen';
        modalConfig.buttonRight.buttonColor = 'danger';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            return true;
        }
    }

    async setActionOnProvidedGroupService(actionEmitter: ActionEmitter<ProvidedService>) {
        if (actionEmitter.actionType === ActionType.UPDATE) {
            await this.editConsultationProvidedServices(actionEmitter.item);
        }
        if (actionEmitter.actionType === ActionType.DELETE) {
            await this.removeConsultationProvidedServices(actionEmitter.item);
        }
    }

    async setActionOnOrderProvidedService(actionEmitter: ActionEmitter<ProvidedService>) {
        if (actionEmitter.actionType === ActionType.UPDATE) {
            await this.editOrderProvidedServices(actionEmitter.item);
        }
        if (actionEmitter.actionType === ActionType.DELETE) {
            await this.removeOrderProvidedServices(actionEmitter.item);
        }
    }

    private initStrings() {
        if (this.router.url.includes(RoutingSegment.GROUP)) {
            this.translate.get('DELETE_GROUP_BUTTON').subscribe((value) => (this.deleteString = value));
        } else if (this.router.url.includes(RoutingSegment.ORGANIZATION)) {
            this.translate.get('DELETE_ORG_BUTTON').subscribe((value) => (this.deleteString = value));
        }
    }

    private async getParentList() {
        try {
            this.organizationsOfUser = await this.usersService.getGroupsOfUser(
                this.loggedInUser.username,
                null,
                null,
                null,
                null,
                null,
                null,
                false,
                true,
                GroupLevel.ORGANIZATION,
            );
            for (const org of this.organizationsOfUser.items) {
                this.parentList.push({ value: org.uuid, label: org.name });
            }
            // If the user doesn't have the rights to see the organization level, then extract it from the path of their group
            if ((!this.parentList || this.parentList.length === 0) && this.group != null) {
                const groupPath = this.group.path.split('/');
                const parentName = groupPath[groupPath.length - 2];
                this.parentList.push({ value: this.group.parent, label: parentName });
            }
        } catch (e) {
            this.log.error('Error in getParentList', e);
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
        }
    }

    private async getParentPath(uuid: string) {
        const parentGroup = this.organizationsOfUser.items.find((parent) => parent.uuid === uuid);
        return parentGroup.path;
    }

    private async getConsultationSettings() {
        this.consultationServicesTable.itemSettings = [
            {
                prop: 'name',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '40%',
                columnPosition: 1,
            },
            {
                prop: 'description',
                header: 'Beschreibung',
                maxTextLines: 3,
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '40%',
                columnPosition: 2,
            },
        ];
        if (this.loggedInUser.roles.includes(UserRoles.manage_consultation_services)) {
            this.consultationServicesTable.itemSettings.push({
                prop: '',
                header: '',
                adapter: ButtonItemAdapterComponent,
                type: ItemType.ADAPTER,
                actionType: ActionType.UPDATE,
                icon: 'create',
                width: '10%',
                columnPosition: 3,
            });
            this.consultationServicesTable.itemSettings.push({
                prop: '',
                header: '',
                adapter: ButtonItemAdapterComponent,
                type: ItemType.ADAPTER,
                actionType: ActionType.DELETE,
                icon: 'trash',
                color: 'danger',
                width: '10%',
                columnPosition: 4,
            });
        }
        this.consultationServicesTable.isOpenDetailEnable = false;
        try {
            this.consultationSettings = await this.groupService.getConsultationSettings(this.group.path, false, true);
            this.consultationServicesTable.list.items =
                this.consultationSettings?.consultationProvidedServices.length > 0
                    ? this.consultationSettings?.consultationProvidedServices
                    : [];
            if (this.consultationServicesTable.list.items.length > 1) {
                this.consultationServicesTable.list.items.sort((a, b) => a.name.localeCompare(b.name));
            }
            this.consultationServicesTable.list.total =
                this.consultationServicesTable.list.limit =
                this.consultationServicesTable.list.count =
                    this.consultationServicesTable.list.items.length;
            this.consultationServicesTable.list.offset = 0;
        } catch (e) {
            this.log.error('Error in getConsultationSettings', e);
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
        }
    }

    private async getOrderSettings() {
        this.clientServicesTable.itemSettings = [
            {
                prop: 'name',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '40%',
                columnPosition: 1,
            },
            {
                prop: 'description',
                header: 'Beschreibung',
                maxTextLines: 3,
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '40%',
                columnPosition: 2,
            },
        ];
        if (this.loggedInUser.roles.includes(UserRoles.manage_order_services)) {
            this.clientServicesTable.itemSettings.push({
                prop: '',
                header: '',
                adapter: ButtonItemAdapterComponent,
                type: ItemType.ADAPTER,
                actionType: ActionType.UPDATE,
                icon: 'create',
                width: '10%',
                columnPosition: 3,
            });
            this.clientServicesTable.itemSettings.push({
                prop: '',
                header: '',
                adapter: ButtonItemAdapterComponent,
                type: ItemType.ADAPTER,
                actionType: ActionType.DELETE,
                icon: 'trash',
                color: 'danger',
                width: '10%',
                columnPosition: 4,
            });
        }
        this.clientServicesTable.isOpenDetailEnable = false;
        this.providerServicesTable.itemSettings = [
            {
                prop: 'name',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '40%',
                columnPosition: 1,
            },
            {
                prop: 'description',
                header: 'Beschreibung',
                maxTextLines: 3,
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '40%',
                columnPosition: 2,
            },
        ];
        if (this.loggedInUser.roles.includes(UserRoles.manage_order_services)) {
            this.providerServicesTable.itemSettings.push({
                prop: '',
                header: '',
                adapter: ButtonItemAdapterComponent,
                type: ItemType.ADAPTER,
                actionType: ActionType.UPDATE,
                icon: 'create',
                width: '10%',
                columnPosition: 3,
            });
            this.providerServicesTable.itemSettings.push({
                prop: '',
                header: '',
                adapter: ButtonItemAdapterComponent,
                type: ItemType.ADAPTER,
                actionType: ActionType.DELETE,
                icon: 'trash',
                color: 'danger',
                width: '10%',
                columnPosition: 4,
            });
        }
        this.providerServicesTable.isOpenDetailEnable = false;
        this.clientServicesTable.list.items = [];
        this.providerServicesTable.list.items = [];
        try {
            this.orderSettings = await this.groupService.getOrderSettings(this.group.path, false, true);
            const allProvidedServices = this.orderSettings?.orderProvidedServices
                ? this.orderSettings.orderProvidedServices
                : [];
            for (const providedService of allProvidedServices) {
                if (providedService.isClientService) this.clientServicesTable.list.items.push(providedService);
                if (providedService.isProviderService) this.providerServicesTable.list.items.push(providedService);
            }
            if (this.clientServicesTable.list.items.length > 1) {
                this.clientServicesTable.list.items.sort((a, b) => a.name.localeCompare(b.name));
            }
            if (this.providerServicesTable.list.items.length > 1) {
                this.providerServicesTable.list.items.sort((a, b) => a.name.localeCompare(b.name));
            }
            this.clientServicesTable.list.total =
                this.clientServicesTable.list.limit =
                this.clientServicesTable.list.count =
                    this.clientServicesTable.list.items.length;
            this.clientServicesTable.list.offset = 0;
            this.providerServicesTable.list.total =
                this.providerServicesTable.list.limit =
                this.providerServicesTable.list.count =
                    this.providerServicesTable.list.items.length;
            this.providerServicesTable.list.offset = 0;
        } catch (e) {
            this.log.error('Error in getOrderSettings', e);
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
        }
    }
}
