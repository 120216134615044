import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Platform } from '@ionic/angular';
import { CurafidaAuthService } from '../../../auth/services';
import { ApiService } from '../../../api';
import { Device } from '../../entities/device/device';

@Injectable({
    providedIn: 'root',
})
export class DeviceService {
    devices: ReplaySubject<Device[]> = new ReplaySubject(1);
    isBrowser: boolean;

    constructor(
        public http: HttpClient,
        protected authService: CurafidaAuthService,
        private iab: InAppBrowser,
        private plt: Platform,
    ) {}

    checkCurrentPlatform() {
        if (this.plt.is('mobile')) {
            return false;
        } else return !this.plt.is('tablet');
    }

    async garminHealthAuth() {
        const url = `${ApiService.url}garminHealthApi/requestAuth/${this.authService.getSession().user.username}`;
        const oauth_link = await this.http
            .get(url)
            .toPromise()
            .then((link) => {
                return link;
            })
            .catch((e) => {
                return e;
            });
        this.validateRequestTokenbyUserAuthorization(oauth_link);
    }

    async validateRequestTokenbyUserAuthorization(link: string) {
        const inAppRef = this.iab.create(link, '_blank', 'location=yes');
        const isBrowser = await this.checkCurrentPlatform();
        if (!isBrowser) {
            inAppRef.on('loadstart').subscribe((location) => {
                if (location.url === 'https://app.edumovo.de/') {
                    inAppRef.close();
                }
            });
        }
    }

    async requestGarminAuthAccessToken(oauth_token: string, oauth_verifier: string) {
        return this.http
            .get(
                ApiService.url +
                    'garminHealthApi/callback?oauth_token=' +
                    oauth_token +
                    '&oauth_verifier=' +
                    oauth_verifier,
            )
            .toPromise()
            .then((success) => {
                return success;
            })
            .catch((e) => {
                // e = this.handleError(e);
                console.error(`error`, e);
                return e;
            });
    }

    async checkAuthGarmin(): Promise<boolean> {
        const url = `${ApiService.url}garminHealthApi/isAccountActive/${this.authService.getSession().user.username}`;
        return this.http
            .get(url)
            .toPromise()
            .then((state) => {
                return state;
            })
            .catch((e) => {
                console.error('error to checkAuthFitbit', e);
                return false;
            }) as Promise<boolean>;
        // TODO: Correct/check this response.
    }

    async revokeGarminAuth() {
        const url = `${ApiService.url}garminHealthApi/revokeAuth/${this.authService.getSession().user.username}`;
        return this.http
            .delete(url, ApiService.options)
            .toPromise()
            .then((state) => {
                return state;
            })
            .catch((e) => {
                console.error('error to revokeAuthFitbit', e);
                return false;
            }) as Promise<boolean>;
    }
}
