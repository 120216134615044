import { Pipe, PipeTransform } from '@angular/core';
import { FilterTagCategory } from '../../common/entities/filter-tag';
import { TagCategory } from '../entities/tag/tag-category.entity';

@Pipe({
    standalone: true,
    name: 'userTagFilter',
    pure: true,
})
export class TagFilterPipe implements PipeTransform {
    transform(value: TagCategory): FilterTagCategory {
        return {
            label: value.label,
            uuid: value.uuid,
            tags: value.tags,
        };
    }
}
