// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kiosk-announcement-body {
  white-space: pre-line;
}

#attachment ion-button {
  margin-top: 2em;
}

.kiosk-announcement-info {
  width: 55%;
}

.kiosk-announcement-content {
  width: 40%;
}`, "",{"version":3,"sources":["webpack://./../../libs/kiosk/components/kiosk-announcements-modal/kiosk-announcements-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,UAAA;AACJ","sourcesContent":[".kiosk-announcement-body {\n    white-space: pre-line;\n}\n\n#attachment ion-button {\n    margin-top: 2em;\n}\n\n.kiosk-announcement-info {\n    width: 55%;\n}\n\n.kiosk-announcement-content {\n    width: 40%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
