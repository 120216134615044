import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
    AlternativeExerciseSession,
    ExerciseSessionHome,
    ExerciseSessionOfUser,
    ExerciseSessionState,
    ExerciseType,
} from '../../../entities/exerciseSession';
import { UserRoles } from '../../../../auth/entities/user';
import { UserExerciseSessionsService } from '../../../services/user-exercise-sessions';
import { CurafidaAuthService } from '../../../../auth/services';
import { ExerciseSessionsService } from '../../../services/exercise-sessions';
import {
    ActionEmitter,
    ActionType,
    ButtonItemAdapterComponent,
    DateFormat,
    ItemType,
    TableConfig,
    TableUpdateValue,
} from '../../../../table/entities/table';
import { SegmentType } from '../../../../common/entities/segment.type';
import { CurafidaSegmentItem } from '../../../../common/entities/curafida-segment.item';
import { PaginatedResponse, SortBy, SortOrder } from '../../../../common/entities/paginated-response';
import { TherapiesService } from '../../../services/therapies';
import { Therapy } from '../../../entities/therapy';
import { ExerciseSessionTypeAdapterComponent } from '../../table-adapter/exercise-session-type-adapter.component';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';

@Component({
    selector: 'lib-alternative-appointment',
    templateUrl: './alternative-appointment.component.html',
    styleUrls: ['./alternative-appointment.component.scss'],
})
export class AlternativeAppointmentComponent implements OnInit {
    exerciseSessionOfUser: ExerciseSessionOfUser;
    title = 'Alternativsitzungen auswählen';
    alternativeExercisesSession: PaginatedResponse<AlternativeExerciseSession[]> = new PaginatedResponse<
        AlternativeExerciseSession[]
    >();
    userRole: (UserRoles | string)[] = [];
    isProcessToSaveStarted = false;
    isProcessToSaveFinished = false;
    showModal = false;
    hasPatientRole: boolean;
    segmentAppointment: CurafidaSegmentItem<SegmentType>[] = [];
    otherAppointmentTable = new TableConfig<ExerciseSessionHome[]>();
    SegmentType = SegmentType;
    segmentType = SegmentType.ALTERNATIVE_APPOINTMENT;
    limit = 10;
    offset = 0;
    searchTerm = '';
    therapies: PaginatedResponse<Therapy[]>;
    alternativeAppointmentTable = new TableConfig<AlternativeExerciseSession[]>();

    constructor(
        private modalController: ModalController,
        private userExerciseSessionService: UserExerciseSessionsService,
        private authService: CurafidaAuthService,
        private exerciseSessionsService: ExerciseSessionsService,
        private therapiesService: TherapiesService,
    ) {}

    async ngOnInit() {
        this.userRole = await this.authService.getRoles();
        this.hasPatientRole = this.userRole.includes(UserRoles.PATIENT);
        if (!this.hasPatientRole) {
            this.therapies = await this.therapiesService.getTherapies({
                exerciseType: ExerciseType.COURSE,
            });
            this.initTabs();
        }
        if (this.userRole.includes(UserRoles.PATIENT)) {
            this.alternativeExercisesSession =
                await this.userExerciseSessionService.getExerciseSessionAlternativesWithRestrictions(
                    this.exerciseSessionOfUser.username,
                    this.exerciseSessionOfUser.exerciseSession.id,
                );
        } else {
            await this.getOtherAppointmentList({ offset: 0, limit: 10 }, '');
            await this.getAlternativeAppointment();

            this.otherAppointmentTable.itemSettings = [
                {
                    prop: 'participantsName',
                    header: 'Patient',
                    type: ItemType.ADAPTER,
                    adapter: StringItemAdapterComponent,
                    width: '25%',
                    columnPosition: 0,
                },
                {
                    prop: 'startDate',
                    header: 'Sitzung',
                    adapter: StringItemAdapterComponent,
                    format: DateFormat.DATE_AND_HOUR_2_LINES,
                    type: ItemType.ADAPTER,
                    width: '15%',
                    columnPosition: 1,
                },
                {
                    prop: 'exerciseTitle',
                    header: 'Sitzung',
                    type: ItemType.ADAPTER,
                    adapter: StringItemAdapterComponent,
                    width: '30%',
                    columnPosition: 2,
                },
                {
                    prop: 'maxGroupSize',
                    header: 'Typ',
                    type: ItemType.ADAPTER,
                    adapter: ExerciseSessionTypeAdapterComponent,
                    width: '10%',
                    columnPosition: 4,
                },
                {
                    prop: '',
                    header: '',
                    adapter: ButtonItemAdapterComponent,
                    type: ItemType.ADAPTER,
                    label: 'Patient zuordnen',
                    actionType: ActionType.UPDATE,
                    width: '20%',
                    columnPosition: 4,
                },
            ];
            this.alternativeAppointmentTable.itemSettings = [
                {
                    prop: 'participantsName',
                    header: 'Patient',
                    type: ItemType.ADAPTER,
                    adapter: StringItemAdapterComponent,
                    width: '25%',
                    columnPosition: 0,
                },
                {
                    prop: 'startTime',
                    header: 'Sitzung',
                    adapter: StringItemAdapterComponent,
                    format: DateFormat.DATE_AND_HOUR_2_LINES,
                    type: ItemType.ADAPTER,
                    width: '15%',
                    columnPosition: 1,
                },
                {
                    prop: 'exerciseTitle',
                    header: 'Sitzung',
                    type: ItemType.ADAPTER,
                    adapter: StringItemAdapterComponent,
                    width: '30%',
                    columnPosition: 2,
                },
                {
                    prop: 'maxGroupSize',
                    header: 'Typ',
                    type: ItemType.ADAPTER,
                    adapter: ExerciseSessionTypeAdapterComponent,
                    width: '10%',
                    columnPosition: 4,
                },
                {
                    prop: '',
                    header: '',
                    adapter: ButtonItemAdapterComponent,
                    type: ItemType.ADAPTER,
                    label: 'Patient zuordnen',
                    actionType: ActionType.UPDATE,
                    width: '20%',
                    columnPosition: 4,
                },
            ];
        }
        this.showModal = true;
    }

    dismissModal(value: boolean) {
        if (!this.isProcessToSaveStarted || (this.isProcessToSaveStarted && this.isProcessToSaveFinished)) {
            this.modalController.dismiss({
                reloadTable: value,
            });
        }
    }

    async selectAlternativeAppointment(exerciseSesion: AlternativeExerciseSession) {
        this.isProcessToSaveStarted = true;
        this.showModal = false;
        if (this.userRole.includes(UserRoles.PATIENT)) {
            await this.userExerciseSessionService.createAlternativeAppointmentWithRestriction(
                this.exerciseSessionOfUser.username,
                this.exerciseSessionOfUser.exerciseSession.id,
                exerciseSesion.id,
            );
        }
        this.isProcessToSaveFinished = true;
        this.dismissModal(true);
    }

    initTabs() {
        this.segmentAppointment.push(
            new CurafidaSegmentItem({
                name: 'Mögliche Alternativsitzungen',
                value: SegmentType.ALTERNATIVE_APPOINTMENT,
            }),
        );
        this.segmentAppointment.push(
            new CurafidaSegmentItem({ name: 'Mögliche Sondersitzungen', value: SegmentType.OTHER_APPOINTMENT }),
        );
    }

    async getOtherAppointmentList(value: TableUpdateValue, searchTerm: any) {
        this.offset = value.offset;
        this.limit = value.limit;
        this.searchTerm = searchTerm;
        this.otherAppointmentTable.list = (await this.exerciseSessionsService.getExerciseSessions({
            offset: value.offset,
            limit: this.limit,
            sortBy: SortBy.START_TIME,
            sortOrder: SortOrder.ASC,
            exerciseType: ExerciseType.COURSE,
            options: { includeParticipants: true, exerciseSessionStates: [ExerciseSessionState.PLANNED] },
        })) as PaginatedResponse<ExerciseSessionHome[]>;
        for (const item of this.otherAppointmentTable?.list?.items) {
            if (item.appointment?.startTime) item.startDate = item.appointment.startTime;
            const therapy = this.therapies?.items?.find((therapyItem) => therapyItem.id === item?.therapy_id);
            item.exerciseTitle = therapy ? `${therapy.title}:\n${item?.title}` : `${item?.title}`;
            item.participantsName = '';
            for (const participant of item.participants) {
                item.participantsName =
                    item.participantsName + participant.user.firstname + ' ' + participant.user.lastname + '\n';
            }
        }
        this.segmentAppointment[1].badge = this.otherAppointmentTable.list.total;
    }

    async getAlternativeAppointment() {
        this.alternativeExercisesSession = await this.userExerciseSessionService.getExerciseSessionAlternatives(
            this.exerciseSessionOfUser.username,
            this.exerciseSessionOfUser.exerciseSession.id,
        );
        for (const item of this.alternativeExercisesSession.items) {
            const therapy = this.therapies?.items?.find((therapyItem) => therapyItem.id === item?.therapy_id);
            item.exerciseTitle = therapy ? `${therapy.title}:\n${item?.title}` : `${item?.title}`;
            item.participantsName = '';
            const exerciseSession = await this.exerciseSessionsService.getExerciseSession(item.id);
            for (const participant of exerciseSession.participants) {
                item.participantsName =
                    item.participantsName + participant.user.firstname + ' ' + participant.user.lastname + '\n';
            }
        }
        this.alternativeAppointmentTable.list = this.alternativeExercisesSession;
        this.segmentAppointment[0].badge = this.alternativeAppointmentTable.list.total;
    }

    async setPatientToAppointment(value: ActionEmitter<ExerciseSessionHome>) {
        if (this.segmentType === SegmentType.ALTERNATIVE_APPOINTMENT) {
            await this.userExerciseSessionService.createAlternativeAppointment(
                this.exerciseSessionOfUser.username,
                this.exerciseSessionOfUser.exerciseSession.id,
                value.item.id,
            );
        }

        if (this.segmentType === SegmentType.OTHER_APPOINTMENT) {
            await this.userExerciseSessionService.createAlternativeAppointment(
                this.exerciseSessionOfUser.username,
                this.exerciseSessionOfUser.exerciseSession.id,
                value.item.id,
                true,
                true,
            );
        }
        this.dismissModal(true);
    }
}
