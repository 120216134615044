import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    CreateProgramArticleVariantDto,
    ProgramArticle,
    ProgramArticleVariant,
    UpdateProgramArticleVariantDto,
} from '../../../entities/webshop/program-article.entity';
import { ArticleVariantTypeEnum, PUBLISH_STATUS_LABELS } from '../../../../common/entities/webshop.model';
import { SelectInputItem } from '../../../../common/components/curafida-input/curafida-select-input/curafida-select-input.component';
import { isEurPrice, numberValidator } from '../../../../common/validators/curafida-validators';
import { formatCurrency } from '@angular/common';
import { Router } from '@angular/router';
import { TherapyWebshopService } from '../../../services/webshop/therapy-webshop.service';
import { InputMode } from '../../../../common/components/curafida-input/curafida-text-input/curafida-text-input.component';
import { RoutingSegment } from '../../../../common/entities/routing-segment';
import { Therapy } from '../../../entities/therapy';
import { StyleService } from '../../../../common/services/style/style.service';
import { ExerciseType } from '../../../entities/exerciseSession';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ArticleCosts } from '../../../entities/webshop/article-cost.definition';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lib-course-article-variant-card',
    templateUrl: './course-article-variant-card.component.html',
    styleUrls: ['./course-article-variant-card.component.scss'],
})
export class CourseArticleVariantCardComponent implements OnInit {
    InputMode = InputMode;

    @Input()
    therapy: Therapy;

    @Input()
    therapyTemplateId: number;

    @Input()
    isNewTherapy = true;

    isMobile;

    courseArticle: ProgramArticle;
    courseArticleVariant: ProgramArticleVariant = null;
    courseArticleVariantForm: FormGroup;
    isNewArticleVariant = false;
    isArticleVariantEditEnabled = false;
    publishStatusLabels = Array.from(PUBLISH_STATUS_LABELS.entries(), SelectInputItem.fromKeyValuePair);

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly therapyWebshopService: TherapyWebshopService,
        private styleService: StyleService,
    ) {
        this.isMobile = this.styleService.isMobile$;
    }

    private static parseEurPrice(price: string): number {
        return Number.parseFloat(price.trim().replace(',', '.'));
    }

    async ngOnInit(): Promise<void> {
        if (!this.isNewTherapy) {
            await this.initCourseArticleVariantCard();
        }
    }

    async initCourseArticleVariantCard(): Promise<void> {
        this.courseArticle = await this.therapyWebshopService
            .getArticleOfTherapyTemplate(this.therapyTemplateId)
            .catch((error) => {
                if (error.status === 404) {
                    return null;
                } else {
                    return Promise.reject(error);
                }
            });

        if (this.courseArticle) {
            this.isNewArticleVariant = false;
            this.isArticleVariantEditEnabled = false;

            this.courseArticleVariant = await this.therapyWebshopService
                .getArticleVariantOfTherapy(this.therapy.id)
                .catch(() => {
                    this.isNewArticleVariant = true;
                    this.isArticleVariantEditEnabled = true;
                    return ProgramArticleVariant.createDefault(this.therapy.id, this.courseArticle);
                });
            const costType = this.courseArticleVariant.price === 0 ? ArticleCosts[0].value : ArticleCosts[1].value;
            this.courseArticleVariantForm = this.formBuilder.group({
                summary: new FormControl(
                    { value: this.courseArticleVariant.summary, disabled: !this.isArticleVariantEditEnabled },
                    [Validators.maxLength(255)],
                ),
                description: new FormControl(
                    { value: this.courseArticleVariant.description, disabled: !this.isArticleVariantEditEnabled },
                    [Validators.required],
                ),
                publishStatus: new FormControl(
                    { value: this.courseArticleVariant.publishStatus, disabled: !this.isArticleVariantEditEnabled },
                    [Validators.required],
                ),
                costType: new FormControl({ value: costType, disabled: !this.isArticleVariantEditEnabled }, [
                    Validators.required,
                ]),
                price: new FormControl({ value: this.formatEurPrice(), disabled: !this.isArticleVariantEditEnabled }, [
                    Validators.required,
                    Validators.max(999),
                    Validators.min(1),
                    isEurPrice,
                ]),
                currency: new FormControl(
                    { value: this.courseArticleVariant.currency, disabled: !this.isArticleVariantEditEnabled },
                    [Validators.required],
                ),
                vat: new FormControl(
                    {
                        value: Math.trunc(this.courseArticleVariant.vat * 100),
                        disabled: !this.isArticleVariantEditEnabled,
                    },
                    [Validators.required, Validators.pattern(/^\d+$/), Validators.min(0), Validators.max(100)],
                ),
                medicalRequirements: new FormControl({
                    value: this.courseArticleVariant.medicalRequirements,
                    disabled: !this.isArticleVariantEditEnabled,
                }),
                location: new FormControl(
                    { value: this.courseArticleVariant.location, disabled: !this.isArticleVariantEditEnabled },
                    [Validators.maxLength(255)],
                ),
                seatsMax: new FormControl(
                    { value: this.courseArticleVariant.seatsMax, disabled: !this.isArticleVariantEditEnabled },
                    [numberValidator, Validators.min(1)],
                ),
                seatsMin: new FormControl(
                    { value: this.courseArticleVariant.seatsMin, disabled: !this.isArticleVariantEditEnabled },
                    [numberValidator, Validators.min(0)],
                ),
            });
            if (costType === 'FREE') this.changeCostType('FREE');
        }
    }

    async saveNewArticleVariant(): Promise<void> {
        await this.therapyWebshopService.createArticleVariant(
            this.getCreateArticleVariantFormData(),
            this.courseArticle.uuid,
        );
        await this.initCourseArticleVariantCard();
    }

    async saveArticleVariant(): Promise<void> {
        await this.therapyWebshopService.updateArticleVariant(
            this.createArticleVariantDto(this.courseArticleVariant.uuid),
            this.courseArticle.uuid,
        );
        await this.initCourseArticleVariantCard();
        this.courseArticleVariantForm.disable();
    }

    async cancelEditArticleVariant(): Promise<void> {
        this.courseArticleVariantForm.disable();
        await this.initCourseArticleVariantCard();
    }

    editArticleVariant(): void {
        this.isArticleVariantEditEnabled = true;
        this.courseArticleVariantForm.enable();
    }

    async navigateToTherapyTemplate(): Promise<void> {
        await this.router.navigate([
            RoutingSegment.MEMBER,
            RoutingSegment.ADMINISTRATION,
            RoutingSegment.MEETINGS,
            RoutingSegment.THERAPY_TEMPLATE,
            RoutingSegment.DETAIL,
            this.therapyTemplateId,
            'edit',
        ]);
    }

    changeCostType(value) {
        if (value === 'FREE') {
            this.courseArticleVariantForm.controls.price.clearValidators();
            this.courseArticleVariantForm.controls.price.markAsDirty();
            this.courseArticleVariantForm.controls.price.setValue(null);
        } else {
            this.courseArticleVariantForm.controls.price.clearValidators();
            this.courseArticleVariantForm.controls.price.addValidators(Validators.required);
            this.courseArticleVariantForm.controls.price.addValidators(Validators.min(1));
            this.courseArticleVariantForm.controls.price.addValidators(Validators.max(999));
            this.courseArticleVariantForm.controls.price.addValidators(isEurPrice);
            this.courseArticleVariantForm.controls.price.setValue(1);
        }
        this.courseArticleVariantForm.patchValue({ costType: value });
        this.courseArticleVariantForm.markAsDirty();
    }

    private formatEurPrice(): string {
        return formatCurrency(
            this.courseArticleVariant.price,
            'de-DE',
            '',
            this.courseArticleVariant.currency,
            '0.2-2',
        );
    }

    private getCreateArticleVariantFormData(): CreateProgramArticleVariantDto {
        // Create the DTO with some fixed values and the form tableData
        const courseArticleFormData: CreateProgramArticleVariantDto = Object.assign(
            {
                articleUuid: this.courseArticle.uuid,
                therapyId: this.therapy.id,
            },
            this.courseArticleVariantForm.value,
        );

        if (this.courseArticleVariantForm.controls.costType.value === ArticleCosts[0].value) {
            courseArticleFormData.price = 0;
        } else {
            courseArticleFormData.price = CourseArticleVariantCardComponent.parseEurPrice(
                courseArticleFormData.price as any,
            );
        }
        courseArticleFormData.vat = Number.parseInt(courseArticleFormData.vat as any, 10) / 100;
        courseArticleFormData.seatsMax = Number.parseInt(courseArticleFormData.seatsMax as any, 10);
        courseArticleFormData.seatsMin = Number.parseInt(courseArticleFormData.seatsMin as any, 10);

        // @ts-ignore
        delete courseArticleFormData.costType;
        return courseArticleFormData;
    }

    private createArticleVariantDto(uuid: string): UpdateProgramArticleVariantDto {
        // Create the DTO with some fixed values and the form tableData
        const courseArticleFormData: UpdateProgramArticleVariantDto = Object.assign(
            {
                uuid,
            },
            this.courseArticleVariantForm.value,
        );

        courseArticleFormData.articleVariantType = ArticleVariantTypeEnum.PROGRAM_ARTICLE_VARIANT;
        if (this.courseArticleVariantForm.controls.costType.value === ArticleCosts[0].value) {
            courseArticleFormData.price = 0;
        } else {
            courseArticleFormData.price = CourseArticleVariantCardComponent.parseEurPrice(
                courseArticleFormData.price as any,
            );
        }
        courseArticleFormData.vat = Number.parseInt(courseArticleFormData.vat as any, 10) / 100;
        if (this.therapy.exerciseType === ExerciseType.COURSE) {
            courseArticleFormData.seatsMax = Number.parseInt(courseArticleFormData.seatsMax as any, 10);
            courseArticleFormData.seatsMin = Number.parseInt(courseArticleFormData.seatsMin as any, 10);
        }

        // @ts-ignore
        delete courseArticleFormData.costType;
        return courseArticleFormData;
    }
}
