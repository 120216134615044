import { Tag } from '../../../therapy/entities/tag/tag.entity';
import { NoteTag } from '../../../note/administration/characteristic/note-tags.model';
import { SortOrder } from './sort-order';

export interface PaginationParameter {
    offset: number;
    limit: number;
}

export class PaginatedResponse<T> implements PaginationParameter {
    role: string;
    count = 0;
    offset = 0;
    limit = 0;
    items: T;
    filterByCols: string[];
    total = 0;

    /** @deprecated Use orderBy */
    sortBy: string;

    /** @deprecated Use orderBy */
    sortOrder: string;

    orderBy?: { column: string; order: SortOrder.ASC | SortOrder.DESC }[];

    filter: string;
    tags: NoteTag[] | Tag[];

    constructor() {
        this.count = 0;
        this.offset = 0;
        this.limit = 10;
        this.total = 0;
        this.items = <T>[];
    }

    public static init<T>(items: T[]): PaginatedResponse<T[]> {
        const paginatedList = new PaginatedResponse<T[]>();
        paginatedList.items = items ? items : [];
        paginatedList.offset = 0;
        paginatedList.limit = items?.length ? items.length : 0;
        paginatedList.count = items?.length ? items.length : 0;
        paginatedList.total = items?.length ? items.length : 0;
        return paginatedList;
    }
}
