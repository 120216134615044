import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
    ActionEmitter,
    ActionType,
    DateFormat,
    ItemType,
    TableConfig,
    TableUpdateValue,
} from '../../../../table/entities/table';
import { PaginatedResponse } from '../../../../common/entities/paginated-response';
import { RoutingSegment } from '../../../../common/entities/routing-segment';
import { Router } from '@angular/router';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { TherapyStatsService } from '../../../services/therapy-stats';
import { ParentTherapyProgress } from '../../../entities/user-therapy-stats/parent-therapy-progress';
import { UsersService } from '../../../../user/services/user';
import { differenceInDays } from 'date-fns';

export type ProgramDisplayStats = ParentTherapyProgress & {
    patientName: string;
    daysProgressed: string;
    progressTotalPercent: string;
    progressWhereAvailablePercent: string;
};

@Component({
    selector: 'curafida-program-overview',
    templateUrl: './program-overview.component.html',
    styleUrls: ['./program-overview.component.scss'],
})
export class ProgramOverviewComponent implements OnInit {
    isLoading = true;
    isLoadingSuccess = false;

    @Output()
    programCount$ = new EventEmitter<number>();

    limit = 10;
    programTableConfig: TableConfig<ProgramDisplayStats[]> = new TableConfig<ProgramDisplayStats[]>();

    protected readonly log: Logger;
    private offset = 0;

    constructor(
        private router: Router,
        private toastService: ToastService,
        private therapyStatsService: TherapyStatsService,
        private loggingService: LoggingService,
        private usersService: UsersService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit() {
        await this.initTrainingTable();
        await this.setTrainingSegmentType();
    }

    parentTherapyProgressesToProgramDisplayStats(
        parentTherapyProgress: ParentTherapyProgress[],
    ): Promise<ProgramDisplayStats[]> {
        const getDaysProgressed = ({ startDate, endDate }: ParentTherapyProgress): string => {
            if (!startDate || !endDate) return '-';
            /*
             * differenceInDays() only counts the full days between two dates.
             * Since the start date is at the start of day (00:00) and the end date is at the end of day (23:59)
             * this will not return a full day, so we add one to make up for this.
             * See https://gitlab.ztm-badkissingen.de/curafida/development/ionic-common/-/issues/7902#note_186590
             */
            const total = differenceInDays(new Date(endDate), new Date(startDate)) + 1;
            const completed =
                differenceInDays(new Date(), new Date(startDate)) >= 0
                    ? differenceInDays(new Date(), new Date(startDate)) + 1
                    : 0;
            return `${Math.min(completed, total)} von ${total}`;
        };

        const floatToPercent = (value: number): string => {
            return `${Math.round(value * 100)}%`;
        };

        const parentTherapyProgressToDisplayStats = async (
            parentTherapyProgress: ParentTherapyProgress,
        ): Promise<ProgramDisplayStats> => {
            const user = await this.usersService.getUser(parentTherapyProgress.username);
            const { firstname, lastname } = user;
            return {
                ...parentTherapyProgress,
                patientName: `${lastname}, ${firstname}`,
                daysProgressed: getDaysProgressed(parentTherapyProgress),
                progressTotalPercent: floatToPercent(parentTherapyProgress.progressTotal),
                progressWhereAvailablePercent: floatToPercent(parentTherapyProgress.progressWhereAvailable),
            };
        };

        return Promise.all(parentTherapyProgress.map(parentTherapyProgressToDisplayStats));
    }

    async initTrainingTable() {
        this.programTableConfig.emptyListLabel = 'PROGRAM.ANY_ITEM';
        this.programTableConfig.itemSettings = [
            {
                id: 'patient',
                prop: 'patientName',
                header: 'Patient',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '20%',
                columnPosition: 0,
                sortOrderMobile: 3,
                showColNameOnMobile: true,
            },
            {
                id: 'title',
                prop: 'therapyTitle',
                header: 'Programme',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '20%',
                columnPosition: 1,
                sortOrderMobile: 0,
                isMobileBold: true,
            },
            {
                id: 'startDate',
                prop: 'startDate',
                header: 'Start',
                adapter: StringItemAdapterComponent,
                format: DateFormat.DATE,
                type: ItemType.ADAPTER,
                width: '15%',
                columnPosition: 2,
                sortOrderMobile: 1,
                showColNameOnMobile: true,
            },
            {
                id: 'daysProgressed',
                prop: 'daysProgressed',
                header: 'Fortschritt (Tage)',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '15%',
                columnPosition: 4,
                sortOrderMobile: 5,
                showColNameOnMobile: true,
            },
            {
                id: 'progressTotalPercent',
                prop: 'progressTotalPercent',
                header: 'Fortschritt (Gesamt)',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '15%',
                columnPosition: 5,
                sortOrderMobile: 5,
                showColNameOnMobile: true,
            },
            {
                id: 'progressWhereAvailablePercent',
                prop: 'progressWhereAvailablePercent',
                header: 'Quote (Aktiv)',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '15%',
                columnPosition: 6,
                sortOrderMobile: 5,
                showColNameOnMobile: true,
            },
        ];
        this.programTableConfig.isOpenDetailEnable = true;
    }

    async setTrainingSegmentType() {
        this.limit = 10;
        await this.setProgramList({ offset: 0, limit: this.limit });
    }

    async setProgramList(value: TableUpdateValue): Promise<void> {
        this.isLoading = true;
        this.isLoadingSuccess = false;
        this.limit = value.limit;
        this.offset = value.offset;

        try {
            const parentTherapyProgress = await this.therapyStatsService.getParentTherapyProgress(
                undefined,
                this.limit,
                this.offset,
            );

            const programDisplayStats = await this.parentTherapyProgressesToProgramDisplayStats(
                parentTherapyProgress.items,
            );

            this.programTableConfig.list = {
                ...parentTherapyProgress,
                items: programDisplayStats,
            };

            this.isLoadingSuccess = true;
            this.isLoading = false;
        } catch (e) {
            this.log.error('Error in setProgramList', e);
            this.isLoadingSuccess = false;
            this.isLoading = false;
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            this.programTableConfig.list = new PaginatedResponse<ProgramDisplayStats[]>();
        }
    }

    async openDetailPage(programId: string): Promise<void> {
        await this.router.navigate([
            RoutingSegment.MEMBER,
            RoutingSegment.PATIENT_MANAGEMENT,
            RoutingSegment.PROGRAM_MANAGEMENT,
            RoutingSegment.DETAIL,
            programId,
        ]);
    }

    async openProgramDetailPage(actionEmitter: ActionEmitter<ParentTherapyProgress>): Promise<void> {
        if (actionEmitter.actionType === ActionType.OPEN_NEW_PAGE) {
            const therapyId = actionEmitter.item.therapyId;
            await this.openDetailPage(`${therapyId}`);
        }
    }
}
