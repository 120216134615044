import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AppConfiguration, BasePageConfig, PageConfig } from '../../../config/entities';
import { UserRoles } from '../../../auth/entities/user';
import { ConfigService } from '../../../config/services';
import { CurafidaAuthService } from '../../../auth/services';
import { PopoverMenuComponent } from '../popover-menu/popover-menu.component';
import { LanguageSelectionComponent } from '../language-selection/language-selection.component';
import { StyleService } from '../../services/style/style.service';
import { TutorialSlidesModalComponent } from '../../../measurement/components/tutorial-slides-modal/tutorial-slides-modal.component';
import { ToolbarService } from '../../services/toolbar/toolbar.service';
import { ToolbarType } from '../../entities/view/toolbar-type';
import { ConnectionStatus, NetworkService } from '../../../measurement/services/network/network.service';
import { RoutingSegment } from '../../entities/routing-segment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CurafidaSession } from '../../../auth/entities/user/curafida-session';
import { MenuService } from '../../services/menu.service';
import { CurafidaFrontendConfiguration } from '../../entities/curafida-frontend-configuration';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lib-curafida-toolbar',
    templateUrl: './toolbar.html',
    styleUrls: ['./toolbar.scss'],
})
export class ToolbarComponent implements OnInit {
    title: string;
    backButtonStringName = '';
    showBackButtonAndBurgerMenuIcon = true;
    modalDefinition;
    helpURL: string = null;
    logoName = 'logo.png';
    appConfig: AppConfiguration;
    roles: UserRoles[] | string[] = [];
    session: CurafidaSession = new CurafidaSession();
    showCurrentUser = false;
    showOfflineIcon: boolean;

    langSubscription: Subscription;
    langList: string[] = [];
    currentLang: string;
    isMobile = false;
    toolbarVisibility = true;
    toolbarType = ToolbarType.NORMAL;
    ToolbarType = ToolbarType;
    networkSubscription: Subscription;
    hideToolbar: boolean;
    isPatient: boolean;
    // Check if at least one of the categories is in the URL, for header highlighting
    logoUrl: string = this.environmentConfig.theme.logoUrl;
    protected readonly MenuService = MenuService;

    constructor(
        public router: Router,
        public configService: ConfigService,
        private popoverController: PopoverController,
        protected authService: CurafidaAuthService,
        private iab: InAppBrowser,
        private translate: TranslateService,
        public styleService: StyleService,
        private modalCtrl: ModalController,
        private toolbarService: ToolbarService,
        private navController: NavController,
        private networkService: NetworkService,
        @Inject('environmentConfig') private environmentConfig: CurafidaFrontendConfiguration,
    ) {
        this.toolbarService.subscribeTitle((t) => {
            this.title = t;
        });
        this.toolbarService.subscribeBackButtonStringName((t) => {
            this.backButtonStringName = t;
        });
        this.toolbarService.subscribeToolbarType((t) => {
            this.toolbarType = t;
        });
        this.toolbarService.subscribeBackButtonAndBurgerMenuIconState((t) => {
            this.showBackButtonAndBurgerMenuIcon = t;
        });
        this.toolbarService.subscribeUserRole((t) => {
            this.roles = t;
            this.isPatient = !!this.roles.find((rol) => rol === UserRoles.PATIENT);
        });
        this.toolbarService.subscribeHelpModalComponent((t) => {
            this.modalDefinition = t;
        });
        this.toolbarService.subscribeHideToolbar((t) => {
            this.hideToolbar = t;
        });
        this.networkSubscription = this.networkService.onNetworkChange().subscribe((status) => {
            this.showOfflineIcon = status === ConnectionStatus.OFFLINE;
        });
        this.initToolbar();
    }

    isLoginPage(): boolean {
        return this.router.url.includes('/login');
    }

    isRootPage(): boolean {
        let isRootPage = false;
        const currentUrl = this.router.url;
        if (!this.roles || this.roles.length === 0) {
            isRootPage = currentUrl === '/login';
        }
        isRootPage = isRootPage ? isRootPage : currentUrl === this.configService.config.defaultUrl;
        if (isRootPage) {
            return true;
        }
        for (const url of this.configService.config.rootUrls) {
            if (currentUrl.includes(url.url)) return true;
        }
        return false;
    }

    async findCurrentPage(): Promise<PageConfig> {
        let page = this.configService.config.pageConfigs.find((currentPage) => {
            if (currentPage.configuration.toolbarVisibility) {
                this.toolbarVisibility = currentPage.configuration.toolbarVisibility;
            }
            return this.router.url.includes(currentPage.url);
        });
        if (!page) {
            for (const subpage of this.configService.config.pageConfigs) {
                if (subpage.childPageConfigs) {
                    page = subpage.childPageConfigs.find((currentPage) => {
                        if (currentPage.configuration.toolbarVisibility) {
                            this.toolbarVisibility = currentPage.configuration.toolbarVisibility;
                        }
                        return this.router.url.includes(currentPage.url);
                    });
                }
            }
        }

        if (!page) {
            page = this.configService.config.generalPageConfigs.find((currentPage) => {
                if (currentPage.configuration.toolbarVisibility) {
                    this.toolbarVisibility = currentPage.configuration.toolbarVisibility;
                }
                return this.router.url.includes(currentPage.url);
            });
        }
        if (!page) {
            for (const subpage of this.configService.config.generalPageConfigs) {
                if (subpage.childPageConfigs) {
                    page = subpage.childPageConfigs.find((currentPage) => {
                        if (currentPage.configuration.toolbarVisibility) {
                            this.toolbarVisibility = currentPage.configuration.toolbarVisibility;
                        }
                        return this.router.url.includes(currentPage.url);
                    });
                }
            }
        }
        return page;
    }

    async openHelp(): Promise<void> {
        if (this.helpURL) this.iab.create(this.helpURL);
        if (this.modalDefinition) {
            const modal = await this.modalCtrl.create({
                component: TutorialSlidesModalComponent,
                componentProps: {
                    modalDefinition: this.modalDefinition,
                },
            });
            await modal.present();
            await modal.onDidDismiss();
        }
    }

    async presentLangPopover(ev: Event): Promise<void> {
        const popover = await this.popoverController.create({
            component: LanguageSelectionComponent,
            cssClass: '',
            event: ev,
            translucent: true,
        });
        return await popover.present();
    }

    ngOnInit(): void {
        this.title = this.toolbarService.getTitle();
    }

    goToPreviousPage(): void {
        this.navController.pop();
    }

    openPage(url: string): void {
        this.router.navigate([url]);
    }

    mainTitleActive(categories: RoutingSegment[]): boolean {
        for (const category of categories) {
            if (this.router.url.includes(category)) {
                return true;
            }
        }
        return false;
    }

    async login(): Promise<void> {
        await this.authService.isAuthenticated(true, true);
    }

    openProfile(): void {
        this.router.navigate([RoutingSegment.MEMBER, RoutingSegment.PROFILE]);
    }

    async presentPopover(ev: Event, subPagesConfig: BasePageConfig[]): Promise<void> {
        subPagesConfig = MenuService.fetchSubPageConfig(subPagesConfig, this.roles, this.configService);
        const popover = await this.popoverController.create({
            component: PopoverMenuComponent,
            translucent: true,
            event: ev,
            componentProps: {
                subPages: subPagesConfig,
            },
        });
        return await popover.present();
    }

    private async initToolbar() {
        this.roles = await this.authService.getRoles();
        this.langList = this.translate.getLangs();
        this.currentLang = this.translate.currentLang;
        this.langSubscription = this.translate.onLangChange.subscribe(() => {
            this.currentLang = this.translate.currentLang;
        });
        this.title = this.toolbarService.getTitle();
        this.appConfig = this.configService.config;
        const page = await this.findCurrentPage();
        if (page?.configuration?.helpUrl && page?.configuration?.hasHelpIcon) {
            this.helpURL = page?.configuration?.helpUrl;
        }
        if (page) this.showCurrentUser = page.configuration.showCurrentUser;

        if (!this.configService.config.theme.logoUrl) this.logoUrl = 'assets/imgs/logo.png';
    }
}
