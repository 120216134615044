import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Content } from '../../entities/content';
import { ApiService } from '../../../api';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HypermediaResource } from '../../../hateoas/hateoas.model';
import { AuthorizationPipe } from '../../../hateoas/authorization.pipe';
import { TaskResource } from '../../components/task/task.resource';
import { XapiLesson } from '../../entities/xapi/xapi-finished-lessons.dto';
import { InAppBrowserOptions } from '@ionic-native/in-app-browser';
import { ConfigService } from '../../../config/services';
import { Platform } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable({
    providedIn: 'root',
})
export class ArticulateContentsService {
    readonly lessons$ = new BehaviorSubject<XapiLesson[]>([]);
    readonly finishedLessons$ = new BehaviorSubject<XapiLesson[]>([]);
    readonly url$ = new BehaviorSubject<{ url: string }>({ url: undefined });

    constructor(
        public router: Router,
        protected http: HttpClient,
        private authorizationPipe: AuthorizationPipe,
        private configService: ConfigService,
        private platform: Platform,
        private iab: InAppBrowser,
    ) {}

    async uploadFile(file): Promise<Content> {
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        const options = { headers, withCredentials: true };
        return this.http.post<Content>(`articulate/file-upload`, file, options).toPromise();
    }

    createLearningContentFileSubscricable(formData: FormData): Observable<any> {
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        return this.http
            .post<Content>(`articulate/file-upload`, formData, {
                reportProgress: true,
                observe: 'events',
                headers,
                withCredentials: true,
            })
            .pipe(catchError(this.errorMgmt));
    }

    errorMgmt(error: HttpErrorResponse) {
        let errorMessage;
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }

    async importArticulateCourse(exerciseId: number, contentId: string): Promise<Content> {
        return this.http.post<Content>(`articulateContent/${exerciseId}/import/${contentId}`, null).toPromise();
    }

    init(taskResource: TaskResource, isPatient = false): void {
        this.fetchArticulateLessons(taskResource, 'articulateLearningCourseLessons').subscribe((it) => {
            this.lessons$.next(it);
            this.finishedLessons$.next(it.filter((it) => it.isFinished));
        });
        if (isPatient) {
            this.fetchArticulateUrlCourse(taskResource, 'articulateLearningCourseUrl').subscribe((it) => {
                this.url$.next(it);
            });
        }
    }

    updateLessons(taskResource: TaskResource): void {
        this.fetchArticulateLessons(taskResource, 'articulateLearningCourseLessons').subscribe((it) => {
            this.lessons$.next(it);
            this.finishedLessons$.next(it.filter((it) => it.isFinished));
        });
    }

    fetchArticulateLessons(resource: HypermediaResource, linkName: string): Observable<XapiLesson[]> {
        if (!this.authorizationPipe.transform(resource, linkName, 'read')) {
            return of(undefined);
        }
        return this.http.get<XapiLesson[]>(`${resource._links[linkName].href}`);
    }

    openArticulateCourseWithIab(articulateLaunchUrl: string) {
        const iabOptions: InAppBrowserOptions = {
            hardwareback: 'yes',
            hideurlbar: 'yes',
            hidenavigationbuttons: 'yes',
            toolbarcolor: this.configService.config.theme.colors.primary,
            closebuttoncolor: this.configService.config.theme.colors.primaryContrast,
            closebuttoncaption: 'X',
            location: 'no',
            zoom: 'yes',
            toolbarposition: 'top',
            lefttoright: 'yes',
        };
        if (this.platform.is('android')) {
            iabOptions.location = 'yes';
            iabOptions.lefttoright = 'no';
        }
        return this.iab.create(articulateLaunchUrl, '_blank', iabOptions);
    }

    private fetchArticulateUrlCourse(resource: HypermediaResource, linkName: string): Observable<{ url: string }> {
        if (!this.authorizationPipe.transform(resource, linkName, 'read')) {
            return of(undefined);
        }
        return this.http.get<{ url: string }>(`${resource._links[linkName].href}`);
    }
}
