import { Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import { ItemAdapterComponent, ItemType, MultiItemAdapterItem, TableItem } from '../../entities/table';
import { StyleService } from '../../../common/services/style/style.service';
import { ItemAdapterHostDirective } from '../column-definition/item-adapter-host.directive';

@Component({
    standalone: true,
    imports: [ItemAdapterHostDirective],
    template: `<ng-template itemAdapterHost></ng-template>`,
})
export class MultiItemAdapterComponent implements ItemAdapterComponent, OnInit {
    @Input() data: { disabled: boolean; isBold: boolean };
    @Input() itemSetting: TableItem;
    isMobile: boolean;
    @Input()
    mobileAdapter: Type<ItemAdapterComponent>;
    @Input()
    rowIndex: number;
    @ViewChild(ItemAdapterHostDirective, { static: true }) itemAdapterHost!: ItemAdapterHostDirective;
    ItemType = ItemType;
    @Input() isReorderDisabled: boolean;
    private adapters: MultiItemAdapterItem[];
    private chosenAdapter: MultiItemAdapterItem;

    ngOnInit() {
        this.adapters = this.itemSetting.multiItemSettings;
        for (const adapter of this.adapters) {
            if (adapter.condition(this.data)) {
                this.chosenAdapter = adapter;
                break;
            }
        }
        if (this.isMobile) {
            this.loadMobileComponent();
        } else if (this.itemSetting.type === ItemType.ADAPTER) this.loadComponent();
    }

    loadComponent() {
        if (this.itemAdapterHost && this.chosenAdapter) {
            const viewContainerRef = this.itemAdapterHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent<ItemAdapterComponent>(
                this.chosenAdapter.itemSetting.adapter,
            );
            componentRef.instance.data = this.data;
            componentRef.instance.itemSetting = this.chosenAdapter.itemSetting;
            componentRef.instance.rowIndex = this.rowIndex;
            componentRef.instance.isReorderDisable = this.isReorderDisabled;
        }
    }

    loadMobileComponent() {
        if (this.itemAdapterHost) {
            const viewContainerRef = this.itemAdapterHost.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent<ItemAdapterComponent>(this.mobileAdapter);
            componentRef.instance.data = this.data;
            this.itemSetting = new TableItem();
            this.itemSetting.type = ItemType.ADAPTER;
            componentRef.instance.itemSetting = this.itemSetting;
            componentRef.instance.rowIndex = this.rowIndex;
            componentRef.instance.isReorderDisable = this.isReorderDisabled;
        }
    }

    constructor(private styleService: StyleService) {
        this.isMobile = this.styleService.isMobile$;
    }
}
