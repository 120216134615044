import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    ExerciseSessionState,
    ExerciseSessionUserState,
    ExerciseSubType,
    ExerciseType,
} from '../../../entities/exerciseSession';
import { ActivatedRoute } from '@angular/router';
import { User, UserRoles } from '../../../../auth/entities/user';
import { ActionEmitter, DateFormat, ItemType, TableConfig, TableUpdateValue } from '../../../../table/entities/table';
import { PaginatedResponse, SortOrder } from '../../../../common/entities/paginated-response';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { ModalController } from '@ionic/angular';
import { StyleService } from '../../../../common/services/style/style.service';
import { CurafidaAuthService } from '../../../../auth/services';
import { CurafidaSegmentItem } from '../../../../common/entities/curafida-segment.item';
import { SegmentType } from '../../../../common/entities/segment.type';
import { ModalConfig } from '../../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../../common/entities/modal/modal-typ';
import { ButtonConfig } from '../../../../common/entities/modal/modal-button';
import { ModalAlertService } from '../../../../common/services/modal';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import {
    differenceInDays,
    differenceInHours,
    endOfDay,
    isBefore,
    parseISO,
    startOfDay,
    subHours,
    subSeconds,
} from 'date-fns';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { IconType } from '../../../../common/entities/icon_type';
import { CaregiverTaskPreviewComponent } from '../caregiver-task-preview/caregiver-task-preview.component';
import { PatientTaskPreviewComponent } from '../patient-task-preview/patient-task-preview.component';
import { UsersPreferencesService } from '../../../../user/services/user-preferences';
import { SortTaskPropertyMap, TaskPreviewResource, TaskResource } from '../task.resource';
import { ResponsibleNamePipe } from '../task-overview/responsible-name.pipe';
import { IconAdapterComponent } from '../../../../table/components/table-adapter/icon-adapter.component';
import { TaskService } from '../../../services/task/task.service';
import { PercentItemAdapterComponent } from '../../../../table/components/table-adapter/percent-item-adapter.component';
import { MultiItemAdapterComponent } from '../../../../table/components/table-adapter/multi-item-adapter.component';
import { TaskFilterModalComponent } from '../../modal/task-filter-modal/task-filter-modal.component';
import { TaskOverviewFilterSetting } from '../task-overview/task-overview.component';
import { map, take } from 'rxjs/operators';
import { TaskFormComponent } from '../task-form/task-form.component';
import { CurafidaPaginationOptions } from '../../../../table/entities/curafidaPaginationOptions';

interface TaskListItem extends TaskPreviewResource {
    dueDate: string;
    iconName?: string;
    iconColor?: string;
    dashedCardBorder?: boolean;
    finished_at: Date;
    state: string;
    checkBoxActive: boolean;
    checkBoxDisabled: boolean;
    iconType: IconType;
    type: string;
    exerciseSubType: ExerciseSubType;
    responsible: string;
    title: string;
}

@Component({
    selector: 'task-list',
    templateUrl: './task-list.component.html',
    styleUrls: ['./task-list.component.scss'],
})
export class TaskListComponent implements OnInit {
    readonly filterCount$ = this.usersPreferencesService
        .settingByName<TaskOverviewFilterSetting>('TaskListComponent')
        .pipe(map((it) => (it?.tags?.length ?? 0) + (it?.icons?.length ?? 0)));
    isLoading = true;
    isLoadingSuccess = false;
    notificationCooldown = 8; // 8 hours
    notificationTimestamp = subHours(new Date(), this.notificationCooldown + 1);
    ExerciseType = ExerciseType;
    @Input()
    patient: User;
    searchTerm: string;
    patientTaskTableConfig: TableConfig<TaskListItem[]> = new TableConfig();
    loggedInUser: User;
    userRoles: UserRoles[] = [];
    UserRoles = UserRoles;
    segmentListTask: CurafidaSegmentItem<SegmentType>[] = [];
    segmentType = SegmentType;
    offset = 0;
    limit = 10;
    displayedTaskType = SegmentType.ACTIVE;
    activeTaskStates = [ExerciseSessionState.NOT_PLANNED, ExerciseSessionState.ACTIVE];
    plannedTaskUserStates = [ExerciseSessionUserState.PLANNED];
    @Output()
    navigateToTasks = new EventEmitter<SegmentType>();
    protected readonly log: Logger;
    private paginationOptions: CurafidaPaginationOptions;
    private sortOrder: SortOrder;
    private sortBy: SortTaskPropertyMap[];

    constructor(
        private activatedRoute: ActivatedRoute,
        private toastService: ToastService,
        private taskService: TaskService,
        readonly styleService: StyleService,
        private modalCtrl: ModalController,
        private authService: CurafidaAuthService,
        private modalAlertService: ModalAlertService,
        private loggingService: LoggingService,
        private usersPreferencesService: UsersPreferencesService,
        private readonly responsibleNamePipe: ResponsibleNamePipe,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.initTableConfig();
    }

    static mapState(state: ExerciseSessionUserState): string {
        switch (state) {
            case ExerciseSessionUserState.NOT_PLANNED:
            case ExerciseSessionUserState.PLANNED:
            case ExerciseSessionUserState.ACTIVE:
                return 'OPEN';
            case ExerciseSessionUserState.PATIENT_CANCELLED:
            case ExerciseSessionUserState.CANCELLED:
                return 'DECLINED';
            case ExerciseSessionUserState.NO_SHOW:
                return 'EXPIRED';
            default:
                return state;
        }
    }

    async ngOnInit() {
        this.userRoles = (await this.authService.getRoles()) as UserRoles[];
        this.loggedInUser = this.authService.getSession()?.user;
        this.initTabs();
        this.fetchFilteredTaskList();
        if (this.patient.username === this.loggedInUser.username) {
            await this.checkForDueTodayTasks();
        }

        const fromMyMedax = this.activatedRoute.snapshot.queryParamMap.get('fromMyMedax');
        const taskId = this.activatedRoute.snapshot.queryParamMap.get('taskId');
        if (fromMyMedax && taskId) {
            const task = await this.taskService.fetchTask(Number(taskId));
            const actionEmitter = new ActionEmitter<TaskResource>();
            actionEmitter.item = task;
            await this.openPreviewModal(actionEmitter);
        }
    }

    fetchFilteredTaskList() {
        this.usersPreferencesService
            .settingByName<TaskOverviewFilterSetting>('TaskListComponent')
            .pipe(take(1))
            .subscribe((it) =>
                this.getPatientTaskList({
                    value: {
                        offset: this.offset,
                        limit: this.limit,
                    },
                    taskFilterSetting: it,
                }),
            );
    }

    initTableConfig() {
        this.patientTaskTableConfig.emptyListLabel = 'TASK.ANY';
        this.patientTaskTableConfig.itemSettings = [
            {
                id: 'dueDate',
                prop: 'dueDate',
                header: 'DUE_DATE',
                type: ItemType.ADAPTER,
                format: DateFormat.DATE_AND_HOUR_2_LINES,
                adapter: StringItemAdapterComponent,
                width: '12%',
                columnPosition: 1,
                sortBy: SortTaskPropertyMap.START_TIME,
            },
            {
                id: 'dueDate',
                prop: 'calendarEventStartDate',
                header: 'APPOINTMENT',
                type: ItemType.ADAPTER,
                format: DateFormat.DATE_AND_HOUR_2_LINES,
                adapter: StringItemAdapterComponent,
                width: '12%',
                columnPosition: 2,
                sortBy: SortTaskPropertyMap.CALENDAR_EVENT_START_TIME,
            },
            {
                id: 'responsible',
                prop: 'responsible',
                header: 'RESPONSIBLE_FOR',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '16%',
                columnPosition: 3,
                sortBy: SortTaskPropertyMap.RESPONSIBLE,
            },
            {
                id: 'type',
                prop: 'exerciseSubType',
                header: 'TYPE',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '20%',
                columnPosition: 4,
                sortBy: SortTaskPropertyMap.EXERCISE_SUB_TYPE,
                translationPrefix: 'TASK.SUB_TYPE.',
            },
            {
                id: 'title',
                prop: 'title',
                header: 'TASK.SINGULAR',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '22%',
                columnPosition: 5,
                sortBy: SortTaskPropertyMap.TITLE,
            },
            {
                id: 'multiItem',
                prop: '',
                header: '',
                type: ItemType.ADAPTER,
                adapter: MultiItemAdapterComponent,
                width: '6%',
                columnPosition: 6,
                multiItemSettings: [
                    {
                        condition: (data) => data.exerciseSubType === ExerciseSubType.LEARNING,
                        itemSetting: {
                            id: 'learningProgress',
                            prop: 'learningProgress',
                            header: '',
                            type: ItemType.ADAPTER,
                            adapter: PercentItemAdapterComponent,
                        },
                    },
                    {
                        condition: () => true,
                        itemSetting: {
                            id: 'icon',
                            prop: 'icons',
                            header: '',
                            type: ItemType.ADAPTER,
                            adapter: IconAdapterComponent,
                        },
                    },
                ],
            },
        ];
        this.patientTaskTableConfig.isOpenDetailEnable = true;
    }

    initTabs() {
        this.segmentListTask.push(new CurafidaSegmentItem({ name: 'PLANNED_ADJECTIVE', value: SegmentType.PLANNED }));
        this.segmentListTask.push(
            new CurafidaSegmentItem({ name: 'SEGMENT.CALENDAR_EVENT', value: SegmentType.CALENDAR_EVENT }),
        );
        this.segmentListTask.push(new CurafidaSegmentItem({ name: 'SEGMENT.ACTIVE', value: SegmentType.ACTIVE }));
        this.segmentListTask.push(new CurafidaSegmentItem({ name: 'CLOSED', value: SegmentType.INACTIVE }));
        this.segmentListTask.push(new CurafidaSegmentItem({ name: 'CANCELLED', value: SegmentType.CANCELLED }));
        this.segmentListTask.push(new CurafidaSegmentItem({ name: 'EXPIRED', value: SegmentType.EXPIRED }));
        if (this.userRoles.includes(UserRoles.read_task_plan)) {
            this.segmentListTask.push(new CurafidaSegmentItem({ name: 'TASK.PLAN.PLURAL', value: SegmentType.PLAN }));
        }
        this.paginationOptions = new CurafidaPaginationOptions(this.patientTaskTableConfig.itemSettings);
        for (const segment of this.segmentListTask) {
            const segmentSettings = this.taskService.setStateConfiguration(segment.value);
            this.paginationOptions.set(
                { segment: segment.value },
                {
                    sortBy: segmentSettings.sortBy,
                    sortOrder: segmentSettings.sortOrder,
                    offset: 0,
                    tableColumnId: segmentSettings.tableColumnId,
                },
            );
        }
        const segmentSettings = this.taskService.setStateConfiguration(this.displayedTaskType);
        this.sortBy = segmentSettings.sortBy;
        this.sortOrder = segmentSettings.sortOrder;
        this.displayedTaskType = this.segmentListTask[0].value;
    }

    async openPreviewModal(actionEmitter: ActionEmitter<TaskResource | TaskPreviewResource>) {
        if (!actionEmitter.item) {
            this.log.debug('Item of action is undefined, doing nothing.');
            return;
        }
        let modal: HTMLIonModalElement;
        if (this.loggedInUser.roles.includes(UserRoles.PATIENT)) {
            modal = await this.modalCtrl.create({
                component: PatientTaskPreviewComponent,
                id: 'PatientTaskPreviewComponent',
                cssClass: 'full-width-modal',
                componentProps: {
                    exerciseSession_id: actionEmitter.item.id,
                    patient: this.patient,
                },
            });
            await modal.present();
            const { data } = await modal.onDidDismiss();
            if (data) {
                this.fetchFilteredTaskList();
            }
        } else {
            const task = await this.taskService.fetchTask(actionEmitter.item.id);
            modal = await this.modalCtrl.create({
                component: CaregiverTaskPreviewComponent,
                id: 'CaregiverTaskPreviewComponent',
                cssClass: 'task-preview-modal',
                componentProps: {
                    taskResource: task,
                    patient: this.patient,
                },
            });
            await modal.present();
            const { data } = await modal.onDidDismiss();
            if (data) {
                this.fetchFilteredTaskList();
            }
        }
    }

    async openDetailModal(actionEmitter: ActionEmitter<TaskResource>) {
        const task = actionEmitter ? actionEmitter.item : null;
        if (actionEmitter) task.id = Number(actionEmitter.item.id);
        const modal = await this.modalCtrl.create({
            component: TaskFormComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                task: task,
                isNew: !actionEmitter?.item,
                isMobile: this.styleService.isMobile$,
                patient: this.patient,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            this.fetchFilteredTaskList();
        }
    }

    async openPatientTaskDetailModal(actionEmitter: ActionEmitter<TaskPreviewResource>) {
        const task = actionEmitter ? actionEmitter.item : null;
        if (actionEmitter) task.id = Number(actionEmitter.item.id);
        const modal = await this.modalCtrl.create({
            component: PatientTaskPreviewComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                exerciseSession_id: task.id,
                isMobile: this.styleService.isMobile$,
                patient: this.patient,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            this.fetchFilteredTaskList();
        }
    }

    async getPatientTaskList(args: { value: TableUpdateValue; taskFilterSetting?: TaskOverviewFilterSetting }) {
        this.isLoading = true;
        this.isLoadingSuccess = false;
        const stateConfiguration = this.taskService.setStateConfiguration(this.displayedTaskType);
        const taskStates = stateConfiguration.taskStates;
        const taskUserStates = stateConfiguration.taskUserStates;
        this.offset = args.value.offset;
        if (args.value.tableColumnId) {
            this.sortBy =
                args.value.tableColumnId === stateConfiguration.tableColumnId
                    ? stateConfiguration.sortBy
                    : [args.value?.sortBy as SortTaskPropertyMap];
        }
        this.sortOrder = args.value?.sortOrder ? args.value.sortOrder : this.sortOrder;
        try {
            this.patientTaskTableConfig.list = (await this.taskService.fetchTasks(this.patient.username, {
                exerciseSessionStates: taskStates,
                exerciseSessionUserStates: taskUserStates,
                sortBy: this.sortBy,
                sortOrder: this.sortOrder,
                limit: this.limit,
                offset: args.value.offset,
                textSearchTerm: this.searchTerm,
                onlyWithCalendarEvent: this.displayedTaskType === SegmentType.CALENDAR_EVENT,
                includeStateChanges: true,
                tagUuids: args?.taskFilterSetting?.tags?.map((i) => i.uuid),
                iconFilter: args?.taskFilterSetting?.icons,
            })) as PaginatedResponse<TaskListItem[]>;
            for (const userTask of this.patientTaskTableConfig.list?.items) {
                userTask.state = TaskListComponent.mapState(userTask.exerciseSessionUserState);
                userTask.stateChanges.sort(
                    (a, b) => new Date(a.created_at).getUTCDate() - new Date(b.created_at).getUTCDate(),
                );
                const tableConfigDueDate = this.patientTaskTableConfig.itemSettings.find((tc) => tc.id === 'dueDate');
                userTask.dueDate = userTask.appointment?.delayedTime
                    ? endOfDay(subSeconds(parseISO(userTask.appointment?.delayedTime), 1)).toISOString()
                    : null;
                tableConfigDueDate.header = 'HEADER.DUE_AT';
                if (this.displayedTaskType === SegmentType.PLANNED && userTask.stateChanges) {
                    userTask.dueDate = userTask.appointment?.startTime
                        ? startOfDay(parseISO(userTask.appointment?.startTime)).toISOString()
                        : null;
                    tableConfigDueDate.header = 'HEADER.PLANNED_AT';
                }
                if (this.displayedTaskType === SegmentType.INACTIVE && userTask.stateChanges) {
                    const finishedStateChange = userTask.stateChanges.find(
                        (sc) => sc.newState === ExerciseSessionState.FINISHED,
                    );
                    userTask.dueDate = finishedStateChange?.created_at
                        ? parseISO(finishedStateChange.created_at.toString()).toISOString()
                        : null;
                    userTask.finished_at = finishedStateChange?.created_at
                        ? new Date(finishedStateChange?.created_at)
                        : null;
                    tableConfigDueDate.header = 'HEADER.FINISHED_AT';
                }
                if (this.displayedTaskType === SegmentType.CANCELLED && userTask.stateChanges) {
                    const cancelledStateChange = userTask.stateChanges.find((sc) =>
                        [ExerciseSessionState.CANCELLED, ExerciseSessionState.PATIENT_CANCELLED].includes(sc.newState),
                    );
                    userTask.dueDate = cancelledStateChange?.created_at
                        ? parseISO(cancelledStateChange.created_at.toString()).toISOString()
                        : null;
                    tableConfigDueDate.header = 'HEADER.CANCELLED_AT';
                }
                if (this.displayedTaskType === SegmentType.EXPIRED && userTask.stateChanges) {
                    const expiredStateChange = userTask.stateChanges.find(
                        (sc) => sc.newState === ExerciseSessionState.FINISHED,
                    );
                    userTask.dueDate = expiredStateChange?.created_at
                        ? parseISO(expiredStateChange.created_at.toString()).toISOString()
                        : null;
                    tableConfigDueDate.header = 'HEADER.EXPIRED_AT';
                }
                userTask.checkBoxActive = userTask.state !== 'OPEN';
                userTask.checkBoxDisabled = true;
                userTask.responsible = this.responsibleNamePipe.transform(userTask.responsibility);
                userTask.calendarEventStartDate = userTask.calendarEvent?.startTime;
                if (userTask.exerciseSubType === ExerciseSubType.LEARNING) {
                    userTask.learningProgress =
                        userTask.lessons.filter((lesson) => lesson.isFinished).length / userTask.lessons.length;
                }
            }
            this.isLoadingSuccess = true;
            this.isLoading = false;
        } catch (e) {
            this.isLoadingSuccess = false;
            this.log.error('Error in getPatientTaskList', e);
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            this.patientTaskTableConfig.list = new PaginatedResponse<TaskListItem[]>();
            this.isLoading = false;
        }
    }

    async setTaskSegmentType(event: SegmentType) {
        this.paginationOptions.set(
            { segment: this.displayedTaskType },
            {
                offset: this.offset,
                sortBy: this.sortBy,
                sortOrder: this.sortOrder,
            },
        );
        this.displayedTaskType = event;

        const recievedPaginationOptions = this.paginationOptions.get({
            segment: this.displayedTaskType,
        });
        this.sortBy = recievedPaginationOptions.sortBy as SortTaskPropertyMap[];
        this.sortOrder = recievedPaginationOptions.sortOrder;
        this.offset = recievedPaginationOptions.offset;
        this.limit = 10;
        if (event !== SegmentType.PLAN) {
            this.limit = 10;
            this.fetchFilteredTaskList();
        }
    }

    async checkForDueTodayTasks() {
        if (differenceInHours(this.notificationTimestamp, new Date()) >= this.notificationCooldown) {
            const userExerciseSessions = await this.taskService.fetchTasks(this.patient.username, {
                exerciseSessionStates: this.activeTaskStates,
                exerciseSessionUserStates: this.plannedTaskUserStates,
                sortBy: [SortTaskPropertyMap.DELAYED_TIME],
                sortOrder: SortOrder.ASC,
            });
            const dueTodayTasks = [];
            for (const userTask of userExerciseSessions.items) {
                const listItem = userTask as TaskListItem;
                if (
                    listItem.appointment?.delayedTime &&
                    isBefore(parseISO(listItem.appointment?.startTime), new Date()) &&
                    differenceInDays(parseISO(listItem.appointment?.delayedTime), new Date()) === 0
                ) {
                    dueTodayTasks.push(listItem);
                }
            }
            if (dueTodayTasks.length > 0) {
                await this.showDueTodayNotifications(dueTodayTasks);
            }
            this.notificationTimestamp = new Date();
        }
    }

    async showDueTodayNotifications(taskList: TaskListItem[]) {
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.STANDARD;
        modalConfig.descriptionIsHTML = true;
        modalConfig.titleIcon = 'warning-outline';

        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonColor = 'primary';
        if (taskList.length === 1) {
            modalConfig.title = 'Aufgabe endet heute';
            modalConfig.description =
                'Die Bearbeitungszeit für die Aufgabe <strong>' +
                taskList[0].title +
                '</strong> endet heute.\n\n' +
                'Bitte klicken Sie auf "Aufgabe öffnen" und bearbeiten Sie diese zeitnah.\n\n' +
                'Nach Ablauf der Bearbeitungszeit kann die Aufgabe nicht mehr bearbeitet werden.';
            modalConfig.buttonRight.buttonText = 'Aufgabe öffnen';
            const action = await this.modalAlertService.showModal(modalConfig);
            if (action && action.action === 'right') {
                const actionEmitter = new ActionEmitter<TaskPreviewResource>();
                actionEmitter.item = taskList[0];
                await this.openPatientTaskDetailModal(actionEmitter);
            }
        } else {
            modalConfig.title = 'Fälligkeit von Aufgaben endet heute!';
            modalConfig.description =
                'Mehreren Aufgaben sind heute fällig.\n\n' +
                'Bitte klicken Sie auf "Aufgabenliste öffnen" und bearbeiten Sie diese zeitnah.\n\n';
            modalConfig.buttonRight.buttonText = 'Aufgabenliste öffnen';
            const action = await this.modalAlertService.showModal(modalConfig);
            if (action && action.action === 'right') {
                this.navigateToTasks.emit(SegmentType.TASK);
            }
        }
    }

    async openDetailPageForMobile(patientLearningListItem: TaskListItem) {
        if (this.styleService.isMobile$) {
            const item = new ActionEmitter<TaskResource>();
            item.item = patientLearningListItem as unknown as TaskResource;
            await this.openPreviewModal(item);
        }
    }

    async openFilterModal() {
        const modal = await this.modalCtrl.create({
            component: TaskFilterModalComponent,
            cssClass: 'modal-tag-list-css',
            componentProps: {
                filterSettingName: 'TaskListComponent',
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            await this.getPatientTaskList({ value: { offset: 0, limit: this.limit }, taskFilterSetting: data });
        }
    }

    hasRightToCreateNewTask(): boolean {
        return this.userRoles.includes(UserRoles.CAREGIVER) || this.userRoles.includes(UserRoles.SUPERVISOR);
    }
}
