// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kiosk-action-toolbar {
  padding-left: 1em;
}

.kiosk-acton-button-title {
  color: black;
}

.kiosk-action-line {
  width: 100%;
  border-top: 1px solid black;
}

.kiosk-action-text {
  padding-top: 20px;
}

.kiosk-action-text-top {
  padding-bottom: 10px;
}

.kiosk-action-text-bottom {
  padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./../../libs/kiosk/components/kiosk-action-element-modal/kiosk-action-element-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,WAAA;EACA,2BAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,oBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ","sourcesContent":[".kiosk-action-toolbar {\n    padding-left: 1em;\n}\n\n.kiosk-acton-button-title {\n    color: black;\n}\n\n.kiosk-action-line {\n    width: 100%;\n    border-top: 1px solid black;\n}\n\n.kiosk-action-text {\n    padding-top: 20px;\n}\n\n.kiosk-action-text-top {\n    padding-bottom: 10px;\n}\n\n.kiosk-action-text-bottom {\n    padding-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
