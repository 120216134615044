export enum SegmentType {
    DOSSIER = 'DOSSIER',
    ACTIVE = 'ACTIVE',
    ALL = 'ALL',
    ALTERNATIVE_APPOINTMENT = 'ALTERNATIVE_APPOINTMENT',
    ANNOUNCEMENT = 'ANNOUNCEMENT',
    APPOINTMENTS = 'APPOINTMENTS',
    CASESTUDY = 'CASESTUDY',
    CALENDAR_EVENT = 'CALENDAR_EVENT',
    CANCELLED = 'CANCELLED',
    CHART = 'CHART',
    CHAT = 'CHAT',
    CLOSED = 'CLOSED',
    COMMENT = 'COMMENT',
    CONCEPT = 'CONCEPT',
    CONSULTATION = 'CONSULTATION',
    CONSULTATION_CANCELED = 'CONSULTATION_CANCELED',
    CONSULTATION_DECLINED = 'CONSULTATION_DECLINED',
    CONSULTATION_DELETED = 'CONSULTATION_DELETED',
    CONSULTATION_FINISHED = 'CONSULTATION_FINISHED',
    CONSULTATION_IN_CLOSING = 'CONSULTATION_IN_CLOSING',
    CONSULTATION_REVOKED = 'CONSULTATION_REVOKED',
    COURSE = 'COURSE',
    CREATE = 'CREATE',
    DISABLED = 'DISABLED',
    DISCONTINUED = 'DISCONTINUED',
    EXAMINATION_REQUESTED = 'EXAMINATION_REQUESTED', // Befund angeordnet
    EXERCISE = 'EXERCISE',
    EXERCISE_SERIES_TEMPLATES = 'EXERCISE_SERIES_TEMPLATES',
    EXPIRED = 'EXPIRED',
    FINDING_APPROVED = 'FINDING_APPROVED', // Befund freigegeben
    FINDING_IN_APPROVAL = 'FINDING_IN_APPROVAL', // Befund in Freigabe
    FINDING_IN_CREATION = 'FINDING_IN_CREATION', // Befund in Bearbeitung
    FINISHED = 'FINISHED',
    GROUP = 'GROUP',
    HAS_THERAPY = 'HAS_THERAPY',
    INACTIVE = 'INACTIVE',
    LEARNING = 'LEARNING',
    LIST = 'LIST',
    LOG = 'LOG',
    MAIN_INFORMATION = 'MAIN_INFORMATION',
    MANUAL = 'MANUAL',
    MEASUREMENT = 'MEASUREMENT',
    MEASUREMENT_MARKER = 'MEASUREMENT_MARKER',
    MEDICATION = 'MEDICATION',
    MOBILITY = 'MOBILITY',
    MONTH = 'MONTH',
    MY = 'MY',
    NO_SHOW_APPOINTMENT = 'NO_SHOW_APPOINTMENT',
    NOTE = 'NOTE',
    ORDER = 'ORDER',
    ORDER_CANCELED = 'ORDER_CANCELED', // Auftrag abgebrochen
    ORDER_DECLINED = 'ORDER_DECLINED', // Auftrag abgelehnt
    ORDER_DELETED = 'ORDER_DELETED', // Auftrag gelöscht
    ORDER_FINISHED = 'ORDER_FINISHED', // Auftrag abgeschlossen
    ORDER_REVOKED = 'ORDER_REVOKED', // Auftrag widerrufen
    ORGANIZATION = 'ORGANIZATION',
    OTHER_APPOINTMENT = 'OTHER_APPOINTMENT',
    PASSIVE = 'PASSIVE',
    PAUSED = 'PAUSED',
    PLAN = 'PLAN',
    PLANNED = 'PLANNED',
    PRODUCT = 'PRODUCT',
    PROGRAM = 'PROGRAM',
    PROGRESSION = 'PROGRESSION',
    READ = 'READ',
    REGISTERED = 'REGISTERED',
    REQUEST_IN_CREATION = 'REQUEST_IN_CREATION', // Anfrage in Bearbeitung
    REQUEST_UNDER_REVIEW = 'REQUEST_UNDER_REVIEW', // Anfrage in Prüfung
    SCHEDULE_APPOINTMENT = 'SCHEDULE_APPOINTMENT',
    SCREENING = 'SCREENING',
    SUPERVISED_PATIENTS = 'SUPERVISED_PATIENTS',
    TAGS = 'TAGS',
    TASK = 'TASK',
    TEMPLATE = 'TEMPLATE',
    TASK_TEMPLATE = 'TASK_TEMPLATE',
    TENANT = 'TENANT',
    TRACKED = 'TRACK',
    TRAINING = 'TRAINING',
    UNKNOWN = 'UNKNOWN',
    UNREAD = 'UNREAD',
    WAITING_FINISHED = 'WAITING_FINISHED',
    WEEK = 'WEEK',
    YEAR = 'YEAR',
}
