import { Component, Input, OnInit } from '@angular/core';
import { MyMedaxQuestionnaireSubmission } from '../../entities/my-medax-questionnaire-submission';
import {
    ActionEmitter,
    ActionType,
    ButtonItemAdapterComponent,
    DateFormat,
    ItemType,
    TableConfig,
} from '../../../table/entities/table';
import { UsersService } from '../../../user/services/user';
import { MyMedaxService } from '../../services/my-medax.service';
import { ConsultationContent } from '../../../consultation/entities/consultation-content';
import { StringItemAdapterComponent } from '../../../table/components/table-adapter/string-item-adapter.component';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { isAfter, isBefore } from 'date-fns';
import { StyleService } from '../../../common/services/style/style.service';
import { OrderContent } from '../../../order/entities/order-content';
import { ModalController } from '@ionic/angular';
import { MyMedaxQuestionnaireSubmissionComponent } from '../my-medax-questionnaire-submission/my-medax-questionnaire-submission.component';
import { CheckBoxItemAdapterComponent } from '../../../table/components/table-adapter/checkbox-item-adapter.component';
import { TranslateService } from '@ngx-translate/core';

export class QuestionnaireSubmissionListItem {
    myMedaxQuestionnaireSubmission: MyMedaxQuestionnaireSubmission;
    title: string;
    groupName: string;
    answererFullName: string;
    updatedDateTime: string;
    selected: boolean;

    constructor(
        myMedaxQuestionnaireSubmission: MyMedaxQuestionnaireSubmission,
        groupName?: string,
        answererFullName?: string,
    ) {
        this.myMedaxQuestionnaireSubmission = myMedaxQuestionnaireSubmission;
        this.title = this.myMedaxQuestionnaireSubmission.title;
        const splitGroupName = groupName ? groupName.split(', ') : null;
        this.groupName = groupName ? `${splitGroupName[0]},\n${splitGroupName[1]}` : '';
        const answererUser = this.myMedaxQuestionnaireSubmission.answererUser;
        const answererNameExtracted = answererUser
            ? `${answererUser.lastname},\n${answererUser.firstname}`
            : this.myMedaxQuestionnaireSubmission.answererUsername;
        this.answererFullName = answererFullName ? answererFullName : answererNameExtracted;
        this.updatedDateTime = this.myMedaxQuestionnaireSubmission.created_at;
        this.selected = false;
    }
}

@Component({
    selector: 'lib-questionnaire-list-modal',
    templateUrl: './questionnaire-list-modal.component.html',
    styleUrls: ['./questionnaire-list-modal.component.scss'],
})
export class QuestionnaireListModalComponent implements OnInit {
    readonly isMobile: boolean;
    @Input()
    myMedaxQuestionnaireSubmissions: MyMedaxQuestionnaireSubmission[];
    @Input()
    hasCheckbox: boolean;
    @Input()
    onlyLatestVersions: boolean;
    selectedItems: MyMedaxQuestionnaireSubmission[] = [];
    @Input()
    consultationContents?: ConsultationContent[];
    @Input()
    orderContents?: OrderContent[];
    @Input()
    concernedUserFullName: string;
    @Input()
    groupName: string;
    @Input()
    modalTitle: string;
    questionnaireSubmissionTableConfig: TableConfig<QuestionnaireSubmissionListItem[]> = new TableConfig();
    isLoading: boolean;
    isLoadingSuccess: boolean;
    protected readonly log: Logger;

    constructor(
        private userService: UsersService,
        private myMedaxService: MyMedaxService,
        private loggingService: LoggingService,
        private styleService: StyleService,
        private modalController: ModalController,
        private translate: TranslateService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.isMobile = this.styleService.isMobile$;
    }

    async ngOnInit(): Promise<void> {
        this.modalTitle = this.onlyLatestVersions
            ? this.modalTitle
            : this.translate.instant('PREVIOUS_VERSION') + ' ' + (this.modalTitle ?? '');
        this.initTableConfig();
        await this.initQuestionnaireSubmissions();
    }

    initTableConfig(): void {
        this.questionnaireSubmissionTableConfig.emptyListLabel = 'TASK.QUESTIONNAIRE.ANY_ITEMS';
        this.questionnaireSubmissionTableConfig.itemSettings = [];
        if (this.hasCheckbox) {
            this.questionnaireSubmissionTableConfig.isOpenDetailEnable = true;
            this.questionnaireSubmissionTableConfig.itemSettings.push({
                id: 'selected',
                prop: 'selected',
                header: '',
                adapter: CheckBoxItemAdapterComponent,
                type: ItemType.ADAPTER,
                width: '8%',
                columnPosition: 0,
                sortOrderMobile: 0,
            });
        }
        if (this.onlyLatestVersions) {
            this.questionnaireSubmissionTableConfig.itemSettings.push({
                id: 'title',
                prop: 'title',
                header: 'QUESTIONNAIRE',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: this.hasCheckbox ? '32%' : '40%',
                columnPosition: 2,
                sortOrderMobile: 2,
            });
        } else {
            this.questionnaireSubmissionTableConfig.itemSettings.push({
                id: 'groupName',
                prop: 'groupName',
                header: 'GROUP_SESSION',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: this.hasCheckbox ? (this.isMobile ? '10%' : '32%') : this.isMobile ? '20%' : '40%',
                columnPosition: 4,
                sortOrderMobile: 4,
            });
        }
        this.questionnaireSubmissionTableConfig.itemSettings.push(
            {
                id: 'updatedDateTime',
                prop: 'updatedDateTime',
                header: 'CREATED_ON',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                format: DateFormat.DATE_AND_HOUR_2_LINES,
                width: this.isMobile ? '28%' : '19%',
                columnPosition: 1,
                sortOrderMobile: 1,
            },
            {
                id: 'answererFullName',
                prop: 'answererFullName',
                header: 'USER.USER',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '30%',
                columnPosition: 3,
                sortOrderMobile: 3,
            },
            {
                id: 'action_view',
                prop: 'uuid',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                icon: 'open-outline',
                actionType: ActionType.PREVIEW,
                width: this.isMobile ? '22%' : '11%',
                columnPosition: 5,
                sortOrderMobile: 5,
            },
        );
        this.questionnaireSubmissionTableConfig.list.items = [];
    }

    async initQuestionnaireSubmissions(): Promise<void> {
        this.isLoading = true;
        this.isLoadingSuccess = false;
        if (!this.myMedaxQuestionnaireSubmissions) this.myMedaxQuestionnaireSubmissions = [];
        this.myMedaxQuestionnaireSubmissions.sort((a, b) => {
            if (isBefore(new Date(a.created_at), new Date(b.created_at))) {
                return 1;
            } else if (isAfter(new Date(a.created_at), new Date(b.created_at))) {
                return -1;
            }
            return 0;
        });
        const submissions = this.onlyLatestVersions
            ? this.myMedaxService.filterOutOldVersions(this.myMedaxQuestionnaireSubmissions)
            : this.myMedaxQuestionnaireSubmissions;
        for (const submission of submissions) {
            let answererFullName: string;
            if (!submission.answererUser) {
                try {
                    answererFullName = await this.userService.getUserFullName(submission.answererUsername);
                } catch (e) {
                    answererFullName = submission.answererUsername;
                }
            } else {
                answererFullName = `${submission.answererUser.lastname}, ${submission.answererUser.firstname}`;
            }
            this.questionnaireSubmissionTableConfig.list.items.push(
                new QuestionnaireSubmissionListItem(submission, this.groupName, answererFullName),
            );
        }
        this.questionnaireSubmissionTableConfig.list.total =
            this.questionnaireSubmissionTableConfig.list.count =
            this.questionnaireSubmissionTableConfig.list.limit =
                this.questionnaireSubmissionTableConfig.list.items.length;
        this.questionnaireSubmissionTableConfig.list.offset = 0;
        this.isLoading = false;
        this.isLoadingSuccess = true;
    }

    async openQuestionnaireSubmission(actionEmitter: ActionEmitter<QuestionnaireSubmissionListItem>): Promise<void> {
        if (actionEmitter.actionType === ActionType.PREVIEW) {
            const listItem = actionEmitter.item;
            let orderContent;
            let consultationContent;
            if (this.orderContents && this.orderContents?.length > 0) {
                orderContent = this.orderContents.find(
                    (x) => x.myMedaxQuestionnaireSubmissionUuid === listItem.myMedaxQuestionnaireSubmission.uuid,
                );
            }
            if (this.consultationContents && this.consultationContents?.length > 0) {
                consultationContent = this.consultationContents.find(
                    (x) => x.myMedaxQuestionnaireSubmissionUuid === listItem.myMedaxQuestionnaireSubmission.uuid,
                );
            }
            const modal = await this.modalController.create({
                component: MyMedaxQuestionnaireSubmissionComponent,
                backdropDismiss: true,
                cssClass: 'full-width-modal',
                componentProps: {
                    myMedaxQuestionnaireSubmission: listItem.myMedaxQuestionnaireSubmission,
                    allowFillNewVersion: false,
                    concernedUserFullName: this.concernedUserFullName,
                    answererFullName: listItem.answererFullName,
                    orderContent: orderContent,
                    consultationContent: consultationContent,
                },
            });
            await modal.present();
        }
    }

    async onActionOnItem(item: QuestionnaireSubmissionListItem): Promise<void> {
        if (this.hasCheckbox) {
            item.selected = !item.selected;
            if (item.selected) {
                this.selectedItems.push(item.myMedaxQuestionnaireSubmission);
            } else {
                this.selectedItems = this.selectedItems.filter((x) => x !== item.myMedaxQuestionnaireSubmission);
            }
        } else {
            return;
        }
    }

    async dismissModal(): Promise<void> {
        await this.modalController.dismiss();
    }

    async returnSelectedItems(): Promise<void> {
        await this.modalController.dismiss(this.selectedItems);
    }
}
