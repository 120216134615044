import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ItemType, TableConfig } from '../../../table/entities/table';
import { CheckBoxItemAdapterComponent } from '../../../table/components/table-adapter/checkbox-item-adapter.component';
import { StringItemAdapterComponent } from '../../../table/components/table-adapter/string-item-adapter.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IonicColor } from '../../entities/toast/ionic-color';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { ToastService } from '../../services/toast-service/toast-service.service';

export interface SelectableItem {
    name: string;
    description?: string;
    selected: boolean;
}

@Component({
    selector: 'lib-accept-request-modal',
    templateUrl: './accept-request-modal.component.html',
    styleUrls: ['./accept-request-modal.component.scss'],
})
export class AcceptRequestModalComponent implements OnInit {
    @Input()
    title: string;
    @Input()
    titleIcon: string;
    @Input()
    cancelButtonText: string;
    @Input()
    confirmButtonText: string;
    @Input()
    selectableServices: SelectableItem[];
    @Input()
    emptyListText: string;
    @Input()
    justifyingIndicationEnabled: boolean;
    @Input()
    hasNote: boolean;

    serviceListConfig: TableConfig<SelectableItem[]> = new TableConfig<SelectableItem[]>();
    inputForm: FormGroup;
    justifyingIndication = [
        { value: true, label: 'Ja' },
        { value: false, label: 'Nein' },
    ];
    protected readonly log: Logger;

    constructor(
        private params: NavParams,
        private modalCtrl: ModalController,
        protected formBuilder: FormBuilder,
        private toastService: ToastService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit() {
        this.inputForm = this.formBuilder.group({
            justifyingIndicationGiven: new FormControl({ value: null, disabled: false }, Validators.required),
            justifyingIndicationNotes: new FormControl({ value: '', disabled: false }),
        });
        this.title = await this.params.get('title');
        this.titleIcon = await this.params.get('titleIcon');
        this.cancelButtonText = await this.params.get('cancelButtonText');
        this.confirmButtonText = await this.params.get('confirmButtonText');
        this.selectableServices = await this.params.get('selectableServices');
        this.emptyListText = await this.params.get('emptyListText');
        this.justifyingIndicationEnabled = await this.params.get('justifyingIndicationEnabled');
        this.hasNote = await this.params.get('hasNote');
        if (this.selectableServices && this.selectableServices.length > 0) {
            this.initListConfig();
            this.serviceListConfig.list.items = this.selectableServices;
            this.serviceListConfig.list.count = this.serviceListConfig.list.items.length;
            this.serviceListConfig.list.limit = this.serviceListConfig.list.items.length;
            this.serviceListConfig.list.total = this.serviceListConfig.list.items.length;
            this.serviceListConfig.list.offset = 0;
        }
    }

    initListConfig() {
        this.serviceListConfig.isOpenDetailEnable = true;
        this.serviceListConfig.itemSettings = [
            {
                id: 'selected',
                prop: 'selected',
                header: '',
                adapter: CheckBoxItemAdapterComponent,
                type: ItemType.ADAPTER,
                width: '8%',
                columnPosition: 0,
            },
            {
                id: 'name',
                prop: 'name',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '46%',
                columnPosition: 1,
            },
            {
                id: 'description',
                prop: 'description',
                header: 'Beschreibung',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '46%',
                columnPosition: 2,
            },
        ];
    }

    async dismissModal() {
        await this.modalCtrl.dismiss();
    }

    onActionOnItem(clickedItem: SelectableItem) {
        clickedItem.selected = !clickedItem.selected;
    }

    async returnSelectedData() {
        if (this.justifyingIndicationEnabled && this.inputForm.controls.justifyingIndicationGiven.value === null) {
            this.log.error('Justifying indication required but not selected');
            await this.toastService.showToast(
                'Rechtfertigende Indikation muss explizit angegeben werden.',
                IonicColor.danger,
            );
            return;
        }
        const selectedProviderServices =
            this.serviceListConfig.list.items && this.serviceListConfig.list.items.length > 0
                ? this.serviceListConfig.list.items?.filter((x) => x.selected === true)
                : [];
        const justifyingIndicationGiven = this.justifyingIndicationEnabled
            ? this.inputForm.controls.justifyingIndicationGiven.value
            : null;
        const justifyingIndicationNotes = this.hasNote ? this.inputForm.controls.justifyingIndicationNotes.value : null;
        const response = {
            selectedProviderServices: selectedProviderServices.length > 0 ? selectedProviderServices : null,
            justifyingIndicationGiven: justifyingIndicationGiven ? justifyingIndicationGiven : null,
            justifyingIndicationNotes: justifyingIndicationNotes ? justifyingIndicationNotes : null,
        };
        await this.modalCtrl.dismiss({ value: response, action: 'right' });
    }

    changeJustifyingIndication(value) {
        this.inputForm.patchValue({ justifyingIndicationGiven: value });
        this.inputForm.markAsDirty();
    }

    cancelOrder() {
        this.modalCtrl.dismiss({ action: 'cancel' });
    }
}
