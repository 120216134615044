import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import * as duration from 'duration-fns';
import { TherapyTemplate } from '../../entities/therapy/therapy-template';
import { User } from '../../../auth/entities/user';
import { TherapiesService } from '../../services/therapies';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { TherapyTemplateListModalComponent } from '../modal/therapy-template-list-modal/therapy-template-list-modal.component';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { ExerciseType } from '../../entities/exerciseSession';
import { Therapy, TherapyDto } from '../../entities/therapy';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { TherapyTemplatesService } from '../../services/therapy-templates';
import { TrainingService } from '../../services/training/training.service';
import { TaskPlanService } from '../task/task-plan.service';
import { LoadingService } from '../../../common/services/loading/loading.service';
import { mergeMap } from 'rxjs/operators';
import { StyleService } from '../../../common/services/style/style.service';
import { CommonComponentsModule } from '../../../common/components/common-components.module';
import { ToolbarModalComponent } from '../../../common/components/toolbar-modal/toolbar-modal.component';

@Component({
    selector: 'lib-copy-template-modal',
    templateUrl: './copy-template-modal.component.html',
    styleUrls: ['./copy-template-modal.component.scss'],
    standalone: true,
    imports: [CommonComponentsModule, ToolbarModalComponent],
})
export class CopyTemplateModalComponent implements OnInit {
    newTherapy: Therapy;
    @Input()
    user: User;
    @Input()
    exerciseType: ExerciseType;

    requiredValidators: Validators = Validators.compose([
        Validators.minLength(1),
        Validators.required,
        Validators.maxLength(255),
    ]);

    therapyForm: FormGroup;
    isEditEnabled: boolean;
    template: TherapyTemplate;
    isMobile: boolean;
    title: string;
    templateName: string;
    ExerciseType = ExerciseType;
    protected readonly log: Logger;

    constructor(
        private formBuilder: FormBuilder,
        private toastService: ToastService,
        private therapiesService: TherapiesService,
        private modalCtrl: ModalController,
        private modalController: ModalController,
        private translate: TranslateService,
        private loggingService: LoggingService,
        private therapyTemplatesService: TherapyTemplatesService,
        private trainingService: TrainingService,
        private readonly taskPlanService: TaskPlanService,
        private readonly loadingService: LoadingService,
        private styleService: StyleService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.isMobile = this.styleService.isMobile$;
    }

    ngOnInit() {
        this.initForm();
    }

    async selectTemplate() {
        if (this.therapyForm.controls.templateName.disabled) return;
        let selectPlan: string;
        let anyPlan: string;
        if (this.exerciseType === ExerciseType.TASK) {
            selectPlan = 'TASK.TEMPLATE.SELECT';
            anyPlan = 'TASK.TEMPLATE.NONE';
        } else if (this.exerciseType === ExerciseType.LEARNING) {
            selectPlan = 'LEARNING.TEMPLATE.SELECT';
            anyPlan = 'LEARNING.TEMPLATE.NONE';
        } else if (this.exerciseType === ExerciseType.MIXED) {
            selectPlan = 'PROGRAM.TEMPLATE.SELECT';
            anyPlan = 'PROGRAM.TEMPLATE.NONE';
        } else if (this.exerciseType === ExerciseType.TRAINING) {
            selectPlan = 'TRAINING.PLAN.SELECT';
        }

        this.therapyForm.controls.duration.patchValue(null);
        const modal = await this.modalCtrl.create({
            component: TherapyTemplateListModalComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                title: selectPlan,
                selectedTherapyTemplates: this.template ? [this.template] : [],
                isMultipleChoice: false,
                anyItem: anyPlan,
                exerciseType: this.exerciseType,
                showOldSelectedExercise: true,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data && data[0]) {
            this.template = data[0];
            this.template = await this.therapyTemplatesService.getTherapyTemplateId(this.template.id);
            this.therapyForm.controls.templateName.patchValue(this.template.title);
            this.therapyForm.controls.title.patchValue(this.template.title);
            if (this.exerciseType === ExerciseType.TRAINING && this.template.therapyGoal) {
                this.therapyForm.controls.therapyGoal.patchValue(this.translate.instant(this.template.therapyGoal));
                this.therapyForm.controls.duration.patchValue(duration.toDays(this.template.duration));
                this.therapyForm.controls.startDate.patchValue(new Date().toISOString());
                if (!this.isEditEnabled) this.toggleFormEdit();
                return;
            }
            if (this.template.duration) this.template.duration = '0';
            if (this.template.duration === '0') {
                this.template.duration = duration.toDays(Number(this.template.duration)).toString();
                this.therapyForm.controls.duration.patchValue(Number(this.template.duration));
                this.therapyForm.controls.startDate.patchValue(new Date().toISOString());
            }
            const exerciseTemplateMaxDuration =
                Math.max(
                    ...this.template.therapyTemplateExercises.map(
                        (i) => duration.toDays(i.startOffset) + duration.toDays(i.endOffset),
                    ),
                ) + 1;
            if (exerciseTemplateMaxDuration >= duration.toDays(Number(this.template.duration))) {
                this.template.duration = duration.toString({ days: exerciseTemplateMaxDuration });
                this.therapyForm.controls.duration.patchValue(duration.toDays(this.template.duration));
                this.therapyForm.controls.startDate.patchValue(new Date().toISOString());
            }
            if (this.exerciseType === ExerciseType.LEARNING || this.exerciseType === ExerciseType.TASK) {
                this.setEndDate(new Date().toISOString());
            }
            if (this.exerciseType === ExerciseType.MIXED) {
                this.therapyForm.controls.duration.patchValue(0);
                const durations = this.template.childTherapyTemplates
                    .map((i) => duration.toDays(i.duration) + (duration.toDays(i.startOffset) - 1))
                    .filter((i) => i !== null);
                if (durations.length > 1) {
                    durations.sort((a, b) => {
                        if (a > b) {
                            return -1;
                        } else if (a < b) {
                            return 1;
                        }
                        // a must be equal to b
                        return 0;
                    });
                    this.therapyForm.controls.duration.patchValue(durations[0]);
                    this.therapyForm.controls.startDate.patchValue(new Date().toISOString());
                } else if (durations.length === 1) {
                    this.therapyForm.controls.duration.patchValue(durations[0]);
                    this.therapyForm.controls.startDate.patchValue(new Date().toISOString());
                }
                this.setEndDate(new Date().toISOString());
            }
        }
        if (!this.isEditEnabled) this.toggleFormEdit();
    }

    setEndDate(newStartDate: string): void {
        if (this.template?.duration || this.therapyForm.controls.duration.value) {
            /* I'd be interested to know why duration needs to be subtracted 1 here.
             * My guess is that it is needed to have the start date included in the duration as the "first day".
             */
            const duration = this.therapyForm.controls.duration.value - 1;
            this.therapyForm.controls.endDate.patchValue(addDays(new Date(newStartDate), duration).toISOString());
        } else {
            this.therapyForm.controls.endDate.patchValue(newStartDate);
        }
    }

    async saveTherapy() {
        this.toggleFormEdit();
        if (this.exerciseType === ExerciseType.MIXED) {
            try {
                const therapy = await this.therapiesService.copyTherapiesFromTemplate(
                    this.template.id,
                    [this.user.username],
                    null,
                    startOfDay(new Date(this.therapyForm.get('startDate').value)),
                    endOfDay(new Date(this.therapyForm.get('endDate').value)),
                );

                therapy[0] = await this.therapiesService.updateTherapy(therapy[0].id, this.setTherapyDto());
                await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
                await this.modalController.dismiss(therapy[0]);
            } catch (e) {
                this.log.error(e);
                await this.toastService.showToast(ToastService.errorMessageSave, IonicColor.danger);
            }
        } else if (this.exerciseType === ExerciseType.TASK) {
            this.loadingService.startLoadingModal();
            this.taskPlanService
                .create({
                    therapyTemplateId: this.template.id,
                    username: this.user.username,
                    startDate: startOfDay(new Date(this.therapyForm.get('startDate').value)).toISOString(),
                    endDate: endOfDay(new Date(this.therapyForm.get('endDate').value)).toISOString(),
                })
                .pipe(mergeMap((it) => this.taskPlanService.update(it.id, this.setTherapyDto())))
                .subscribe({
                    next: (therapy) => {
                        this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
                        this.modalController.dismiss(therapy);
                    },
                    error: (message) => {
                        this.log.error(message);
                        this.toastService.showToast(ToastService.errorMessageSave, IonicColor.danger);
                    },
                    complete: () => this.loadingService.stopLoadingModal(),
                });
        } else {
            try {
                let therapy = await this.therapiesService.createTherapyFromTemplateForPatient(
                    this.template.id,
                    this.user.username,
                    false,
                    startOfDay(new Date(this.therapyForm.get('startDate').value)).toISOString(),
                    endOfDay(new Date(this.therapyForm.get('endDate').value)).toISOString(),
                );
                therapy = await this.therapiesService.updateTherapy(therapy.id, this.setTherapyDto());
                await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
                await this.modalController.dismiss(therapy);
            } catch (e) {
                this.log.error(e);
                await this.toastService.showToast(ToastService.errorMessageSave, IonicColor.danger);
            }
        }
        this.trainingService.therapySaved();
    }

    async dismissModal() {
        await this.modalCtrl.dismiss();
    }

    private toggleFormEdit(): void {
        this.isEditEnabled = !this.isEditEnabled;
        const titleInput = this.therapyForm.get('title');
        const startDate = this.therapyForm.get('startDate');
        this.isEditEnabled ? titleInput.enable() : titleInput.disable();
        this.isEditEnabled ? startDate.enable() : startDate.disable();
    }

    private initForm() {
        const templateName =
            this.newTherapy?.therapyTemplate?.title == null ? '' : this.newTherapy.therapyTemplate.title;
        const startDate = this.newTherapy?.startDate ? new Date(this.newTherapy.startDate).toISOString() : '';
        const endDate = this.newTherapy?.endDate ? new Date(this.newTherapy.endDate).toISOString() : '';
        const title = this.newTherapy?.title == null ? '' : this.newTherapy.title;
        const therapyGoal = this.newTherapy?.therapyGoal == null ? '' : this.newTherapy.therapyGoal;
        this.therapyForm = this.formBuilder.group({
            title: new FormControl({ value: title, disabled: !this.isEditEnabled }, this.requiredValidators),
            startDate: new FormControl<string>({
                value: startDate,
                disabled: !this.isEditEnabled,
            }),
            endDate: new FormControl<string>({
                value: endDate,
                disabled: true,
            }),
            duration: new FormControl<number>({
                value: null,
                disabled: true,
            }),
            therapyGoal: new FormControl({
                value: therapyGoal,
                disabled: true,
            }),
            templateName: new FormControl(
                {
                    value: templateName,
                    disabled: false,
                },
                this.requiredValidators,
            ),
        });
    }

    private setTherapyDto() {
        const therapyDto = new TherapyDto();
        therapyDto.title = this.therapyForm.get('title').value;
        therapyDto.templateId = this.template.id;
        therapyDto.description = this.template.description;
        therapyDto.startDate = startOfDay(new Date(this.therapyForm.get('startDate').value));
        therapyDto.endDate = endOfDay(new Date(this.therapyForm.get('endDate').value));
        therapyDto.exerciseType = this.exerciseType;
        return therapyDto;
    }
}
