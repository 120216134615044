import { ElementRef, Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Storage } from '@ionic/storage-angular';
import { Platform } from '@ionic/angular';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { CurafidaAuthService } from '../../../auth/services';
import { UserRoles } from '../../../auth/entities/user';

@Injectable({
    providedIn: 'root',
})
export class StyleService {
    isMobile$ = this.isMobile();
    renderer: Renderer2;
    protected readonly log: Logger;
    private readonly maxWidthContainerTablet = 1340;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private storage: Storage,
        private rendererFactory: RendererFactory2,
        private readonly platform: Platform,
        private loggingService: LoggingService,
        private readonly authService: CurafidaAuthService,
    ) {
        storage.get('theme').then((cssText) => {
            // <--- GET SAVED THEME
            this.setGlobalCSS(cssText);
        });
        this.renderer = this.rendererFactory.createRenderer(null, null);
        this.platform.ready().then(() => {
            this.setStyle();
        });
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.platform.resize.subscribe(() => {
            this.setStyle();
            this.isMobile$ = this.isMobile();
        });
    }

    private _width: number;

    get width(): number {
        return this._width;
    }

    set width(value: number) {
        this._width = value;
    }

    setStyle() {
        this.width = this.platform.width();
        this.setStyleOfPlatform();
        this.isValidScreenSize();
    }

    getHeight() {
        return this.platform.height();
    }

    /**
     * This is used to recognize if the viewport has a tablet screen dimensions in landscape-modus
     */
    isTabletLandscape(): boolean {
        return (
            (this.platform.width() >= 750 && this.platform.width() <= this.maxWidthContainerTablet) ||
            (this.platform.is('tablet') && this.platform.isLandscape())
        );
    }

    /**
     * TODO: Portal meaning must be defined acording to MockUps. For now, there is not a concrete/finished standard definition
     */
    isPortal(): boolean {
        // return (this.platform.is('mobileweb') && (this.platform.width() > 900))  || (this.platform.width() > this.maxWidthContainerTablet);
        return this.width >= 900 && !this.platform.is('cordova');
    }

    setWrapperContainer(elementRef: ElementRef) {
        /*
         * For tablets: A max-width will be fixed. The wrapper will be used contain the content for screen wider than the max
         */
        this.log.info('this.platform.is(tablet):', this.platform.is('tablet'));
        this.log.info('this.platform.isLandscape():', this.platform.isLandscape());
        if (this.isTabletLandscape()) {
            elementRef.nativeElement.style.setProperty('--curafida-wrapper-width', this.maxWidthContainerTablet + 'px');
        }
    }

    getUsableWidth(): number {
        const r = document.querySelector(':root');
        const rComputedStyle = getComputedStyle(r);
        const wrapper = rComputedStyle.getPropertyValue('--curafida-wrapper-width');
        this.log.debug('wrapper', wrapper);
        if (this.width > this.maxWidthContainerTablet) {
            return this.maxWidthContainerTablet;
        }
        return this.width;
    }

    private isMobile(): boolean {
        if (
            window.location.hostname + ':' + window.location.port === 'localhost:8100' ||
            window.location.hostname + ':' + window.location.port === 'localhost:8101'
        ) {
            return this.platform.is('mobile');
        }
        return (this.platform.is('mobile') && this.platform.width() <= 500) || this.isPatientMobileViewAllow();
    }

    private isPatientMobileViewAllow(): boolean {
        return (
            (this.authService.getSession()?.user.roles.includes(UserRoles.PATIENT) ||
                !this.authService.getSession()?.user) &&
            this.platform.is('mobile') &&
            this.platform.width() <= 500
        );
    }

    private setGlobalCSS(css: string) {
        this.document.documentElement.style.cssText = css;
    }

    private setStyleOfPlatform() {
        if (this.isMobile$) {
            this.renderer.addClass(this.document.body, 'mobile-style');
        }
    }

    private isValidScreenSize() {
        const blockOverlay = document.getElementById('block-overlay');
        if (!blockOverlay) {
            this.log.warn('Element to block the screen not found');
            return;
        }
        if (this.width < 900) {
            blockOverlay.classList.add('show');
        } else {
            blockOverlay.classList.remove('show');
        }
    }
}

export const SCREEN_STANDARD_SIZE = {
    // Standard in pixel
    min_smartphone_portrait: 100,
    max_smartphone_portrait: 700,
};
