import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    CreateProgramArticleVariantDto,
    ProgramArticle,
    ProgramArticleVariant,
    UpdateProgramArticleDto,
    UpdateProgramArticleVariantDto,
    UpdateProgramTemplateArticleVariantDto,
} from '../../entities/webshop/program-article.entity';
import { Observable } from 'rxjs';
import { CreateProgramTemplateArticleVariantDto } from '../../entities/webshop/create-program-template-article-variant.dto';
import { CreateProgramArticleDto } from '../../entities/webshop/create-program-article.dto';

@Injectable({
    providedIn: 'root',
})
export class TherapyWebshopService {
    constructor(private readonly http: HttpClient) {}

    async getArticleOfTherapyTemplate(therapyTemplateId: number): Promise<ProgramArticle> {
        return this.http.get<ProgramArticle>(`webshop/therapy/${therapyTemplateId}/article`).toPromise();
    }

    async getArticleVariantOfTherapy(therapyId: number): Promise<ProgramArticleVariant> {
        return this.http.get<ProgramArticleVariant>(`webshop/therapy/${therapyId}/articleVariant`).toPromise();
    }

    createArticle(createCourseArticleDto: CreateProgramArticleDto): Observable<ProgramArticle> {
        return this.http.post<ProgramArticle>(`webshop/therapy/articles`, createCourseArticleDto);
    }

    async createArticleVariant(
        createCourseArticleVariantDto: CreateProgramArticleVariantDto | CreateProgramTemplateArticleVariantDto,
        articleUuid: string,
    ): Promise<ProgramArticleVariant> {
        return this.http
            .post<ProgramArticleVariant>(
                `webshop/therapy/articles/${articleUuid}/variants`,
                createCourseArticleVariantDto,
            )
            .toPromise();
    }

    async updateArticleVariant(
        createCourseArticleVariantDto: UpdateProgramTemplateArticleVariantDto | UpdateProgramArticleVariantDto,
        articleUuid: string,
    ): Promise<ProgramArticleVariant> {
        return this.http
            .put<ProgramArticleVariant>(
                `webshop/therapy/articles/${articleUuid}/variants`,
                createCourseArticleVariantDto,
            )
            .toPromise();
    }

    async updateArticle(saveCourseArticleDto: UpdateProgramArticleDto): Promise<ProgramArticle> {
        return this.http.put<ProgramArticle>(`webshop/therapy/articles`, saveCourseArticleDto).toPromise();
    }
}
