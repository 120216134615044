import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders } from '@angular/common/http';
import { TherapyTemplateDto } from '../../entities/therapy';
import { TherapyTemplate } from '../../entities/therapy/therapy-template';
import { PaginatedResponse, SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { ApiService } from '../../../api';
import {
    TherapyTemplateExerciseCreationDto,
    TherapyTemplateExerciseResource,
    TherapyTemplateExerciseUpdateDto,
} from '../../entities/therapy/therapy-template-exercise';
import { TherapyGoal } from '../../entities/therapy-goal/therapy-goal';
import { ExerciseType } from '../../entities/exerciseSession';
import { Observable, throwError } from 'rxjs';
import { Content } from '../../entities/content';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { catchError } from 'rxjs/operators';
import { UpdateTherapyTemplateContentDto } from '../../entities/therapy/therapy-template-content.model';
import { ContentFormatType } from '../../../common/entities/content-format-type';
import { SafeUrl } from '@angular/platform-browser';
import { UpdateWebshopContentDto, WebshopContentMetadata } from '../../../shop/entities/webshop-content.model';

@Injectable({
    providedIn: 'root',
})
export class TherapyTemplatesService {
    protected readonly log: Logger;

    constructor(
        protected http: HttpClient,
        private readonly loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(TherapyTemplatesService.name);
    }

    private static logErrors(error: HttpErrorResponse) {
        let errorMessage;
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }

    async getTherapyTemplates(args: {
        offset?: number;
        limit?: number;
        filter?: string;
        sortOrder?: SortOrder;
        sortBy?: SortBy | string;
        therapyGoal?: TherapyGoal;
        exerciseType?: ExerciseType;
        disabled?: boolean;
    }): Promise<PaginatedResponse<TherapyTemplate[]>> {
        const url = new URL(`${ApiService.url}therapyTemplates`);

        if (args?.offset) url.searchParams.set('offset', args.offset.toString());
        if (args?.limit) url.searchParams.set('limit', args.limit.toString());
        if (args?.sortBy) url.searchParams.set('sortBy', args.sortBy);
        if (args?.sortOrder) url.searchParams.set('sortOrder', args.sortOrder);
        if (args?.filter) url.searchParams.set('filter', args.filter);
        if (args?.therapyGoal) url.searchParams.set('therapyGoal', args.therapyGoal);
        if (args?.exerciseType) url.searchParams.set('exerciseType', args.exerciseType);
        if (args?.disabled?.toString()) url.searchParams.set('disabled', args.disabled.toString());

        return this.http.get<PaginatedResponse<TherapyTemplate[]>>(url.toString(), ApiService.options).toPromise();
    }

    async postTherapyTemplate(newTherapyTemplate: TherapyTemplateDto): Promise<TherapyTemplate> {
        const url = `${ApiService.url}therapyTemplates`;
        return this.http.post<TherapyTemplate>(url, newTherapyTemplate, ApiService.options).toPromise();
    }

    async getTherapyTemplateId(therapyTemplateId: number): Promise<TherapyTemplate> {
        const url = `${ApiService.url}therapyTemplates/${therapyTemplateId}`;
        return this.http.get<TherapyTemplate>(url, ApiService.options).toPromise();
    }

    async updateTherapyTemplate(
        therapyTemplateId: number,
        therapyTemplateDto: TherapyTemplateDto,
    ): Promise<TherapyTemplate> {
        const url = `${ApiService.url}therapyTemplates/${therapyTemplateId}`;
        return this.http.put<TherapyTemplate>(url, therapyTemplateDto, ApiService.options).toPromise();
    }

    async assignChildToParentTemplate(parentId: number, childId: number): Promise<TherapyTemplate> {
        const url = `${ApiService.url}therapyTemplates/assign/parent/${parentId}/child/${childId}`;
        return this.http.put<TherapyTemplate>(url, null, ApiService.options).toPromise();
    }

    async unassignChildFromParentTemplate(parentId: number, childId: number): Promise<TherapyTemplate> {
        const url = `${ApiService.url}therapyTemplates/unassign/parent/${parentId}/child/${childId}`;
        return this.http.put<TherapyTemplate>(url, null, ApiService.options).toPromise();
    }

    deleteTherapyTemplate(therapyTemplateId: number) {
        const url = `${ApiService.url}therapyTemplates/${therapyTemplateId}`;
        return this.http.delete(url, ApiService.options).toPromise();
    }

    deleteExerciseFromTherapyTemplate(therapyTemplateExerciseId: number): Promise<any> {
        const url = `${ApiService.url}therapyTemplates/-/therapyTemplateExercises/${therapyTemplateExerciseId}`;
        return this.http.delete(url, ApiService.options).toPromise();
    }

    putTherapyTemplateExercises(therapyTemplateExercises: TherapyTemplateExerciseUpdateDto[]) {
        const url = `${ApiService.url}therapyTemplates/-/therapyTemplateExercises`;
        return this.http
            .put<TherapyTemplateExerciseResource[]>(url, therapyTemplateExercises, ApiService.options)
            .toPromise();
    }

    postTherapyTemplateExercises(therapyTemplateExercise: TherapyTemplateExerciseCreationDto) {
        const url = `${ApiService.url}therapyTemplates/-/therapyTemplateExercises`;
        return this.http
            .post<TherapyTemplateExerciseResource>(url, therapyTemplateExercise, ApiService.options)
            .toPromise();
    }

    uploadMediaFileForTherapyTemplate(
        formData: FormData,
        templateId: number,
        articleUuid?: string,
    ): Observable<HttpEvent<Content>> {
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        const url = new URL(`${ApiService.url}therapyTemplates/${templateId}/file-upload`);
        if (articleUuid) {
            url.searchParams.set('articleUuid', articleUuid);
        }
        return this.http
            .post<Content>(url.toString(), formData, {
                reportProgress: true,
                observe: 'events',
                headers,
                withCredentials: true,
            })
            .pipe(catchError(TherapyTemplatesService.logErrors));
    }

    async updateContent(
        contentUuid: string,
        updateTherapyTemplateContentDto: UpdateTherapyTemplateContentDto,
        articleUuid?: string,
    ): Promise<Content> {
        this.log.debug(`Requesting to PATCH update Content ${contentUuid}`);
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        const url = new URL(`${ApiService.url}therapyTemplates/content/${contentUuid}`);
        if (articleUuid) {
            url.searchParams.set('articleUuid', articleUuid);
        }
        return this.http
            .patch<Content>(url.toString(), updateTherapyTemplateContentDto, {
                headers,
            })
            .toPromise();
    }

    async createTempDownloadUrl(contentUuid: string, isInline?: boolean): Promise<string> {
        this.log.debug(`Requesting temporary download URL for Content ${contentUuid}`);
        const inlineQueryParam = isInline ? '?isInline=true' : '?isInline=false';
        const url = `${ApiService.url}therapyTemplates/content/${contentUuid}/download/temp`;
        const tempDownload = await this.http.post<{ downloadUrl: string }>(url, null, ApiService.options).toPromise();
        return ApiService.url + tempDownload.downloadUrl + inlineQueryParam;
    }

    async getTherapyTemplateContent(
        therapyTemplateId: number,
        contentUuid: string,
        preferredImageFormat?: ContentFormatType,
    ): Promise<SafeUrl> {
        this.log.debug(`Requesting to GET CourseArticleVariant ${therapyTemplateId}`);
        const url = new URL(`${ApiService.url}therapyTemplates/${therapyTemplateId}/contentDownload/${contentUuid}`);
        if (preferredImageFormat) url.searchParams.set('preferredImageFormat', preferredImageFormat);
        return url.toString();
    }

    async deleteContent(contentUuid: string, articleUuid?: string): Promise<void> {
        this.log.debug(`Requesting to DELETE Content ${contentUuid}`);
        const url = new URL(`${ApiService.url}therapyTemplates/content/${contentUuid}`);
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        if (articleUuid) {
            url.searchParams.set('articleUuid', articleUuid);
        }
        return this.http.delete<void>(url.toString(), { headers }).toPromise();
    }

    async updateContentPosition(content: Content, position: number, articleUuid: string): Promise<Content> {
        const updateDto = new UpdateWebshopContentDto();
        updateDto.jsonData = new WebshopContentMetadata(position);
        return this.updateContent(content.uuid, updateDto, articleUuid);
    }
}
