import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Exercise, ExerciseDto } from '../../entities/exercise';
import { Content } from '../../entities/content';
import { TherapyModuleSortBy } from '../../../table/entities/table';
import { FeedBack } from '../../entities/feedback';
import { PaginatedResponse, SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { ApiService } from '../../../api';
import { ExerciseType } from '../../entities/exerciseSession';
import { ModalConfig } from '../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../common/entities/modal/modal-typ';
import { ButtonConfig } from '../../../common/entities/modal/modal-button';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { RoutingSegment } from '../../../common/entities/routing-segment';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalAlertService } from '../../../common/services/modal';
import { Logger, LoggingService } from '../../../logging/logging.service';

@Injectable({
    providedIn: 'root',
})
export class ExercisesService {
    protected readonly log: Logger;

    constructor(
        protected http: HttpClient,
        private readonly translateService: TranslateService,
        private readonly modalAlertService: ModalAlertService,
        private readonly router: Router,
        private readonly toastService: ToastService,
        private readonly loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async getExercises(
        offset?: number,
        limit?: number,
        filter?: string,
        sortOrder?: SortOrder,
        sortBy?: SortBy | TherapyModuleSortBy,
        exerciseType?: ExerciseType,
        includeContents?: boolean,
        includeGoals?: boolean,
        includeTags?: boolean,
        tagUuids?: string[],
        excludedIds?: string[],
    ): Promise<PaginatedResponse<Exercise[]>> {
        let url = `${ApiService.url}exercises`;
        // build query param string
        let queryParams = '';
        if (offset > -1) queryParams = `${queryParams}offset=${offset}&`;
        if (limit) queryParams = `${queryParams}limit=${limit}&`;
        if (filter) queryParams = `${queryParams}filter=${filter}&`;
        if (sortOrder) queryParams = `${queryParams}sortOrder=${sortOrder}&`;
        if (sortBy) queryParams = `${queryParams}sortBy=${sortBy}&`;
        if (exerciseType) queryParams = `${queryParams}exerciseType=${exerciseType}&`;
        if (includeContents) queryParams = `${queryParams}includeContents=${includeContents}&`;
        if (includeGoals) queryParams = `${queryParams}includeGoals=${includeGoals}&`;
        if (includeTags) queryParams = `${queryParams}includeTags=${includeTags}&`;
        if (tagUuids) {
            for (const tagUuid of tagUuids) {
                queryParams = `${queryParams}tagUuids=${tagUuid}&`;
            }
        }
        if (excludedIds) {
            for (const excludedId of excludedIds) {
                queryParams = `${queryParams}excludedIds=${excludedId}&`;
            }
        }

        // check if query params are set, if so ...
        if (queryParams.length > 0) {
            // ... remove the last char '&' and append the query param string to the url
            queryParams = queryParams.substring(0, queryParams.length - 1);
            url = url + '?' + queryParams;
        }
        return this.http.get<PaginatedResponse<Exercise[]>>(url, ApiService.options).toPromise();
    }

    async createExercise(exerciseDto: ExerciseDto): Promise<Exercise> {
        const url = `${ApiService.url}exercises`;
        return this.http.post<Exercise>(url, exerciseDto, ApiService.options).toPromise();
    }

    async getExerciseById(exerciseId: number): Promise<Exercise> {
        const url = `${ApiService.url}exercises/${exerciseId}`;
        return this.http.get<Exercise>(url, ApiService.options).toPromise();
    }

    async updateExercise(exerciseId: number, exerciseDto: ExerciseDto): Promise<Exercise> {
        const url = `${ApiService.url}exercises/${exerciseId}`;
        return this.http.put<Exercise>(url, exerciseDto, ApiService.options).toPromise();
    }

    async deleteExercise(exerciseId: number) {
        const url = `${ApiService.url}exercises/${exerciseId}`;
        return this.http.delete(url, ApiService.options).toPromise();
    }

    async getContentsFromExercise(
        exerciseId: number,
        offset?: number,
        limit?: number,
        sortOrder?: SortOrder,
        sortBy?: SortBy,
    ): Promise<Content[]> {
        let url = `${ApiService.url}exercises/${exerciseId}/contents`;

        // build query param string
        let queryParams = '';
        if (offset) queryParams = `${queryParams}offset=${offset}&`;
        if (limit) queryParams = `${queryParams}limit=${limit}&`;
        if (sortBy) queryParams = `${queryParams}sortBy=${sortBy}&`;
        if (sortOrder) queryParams = `${queryParams}sortOrder=${sortOrder}&`;

        // check if query params are set, if so ...
        if (queryParams.length > 0) {
            // ... remove the last char '&' and append the query param string to the url
            queryParams = queryParams.substring(0, queryParams.length - 1);
            url = url + '?' + queryParams;
        }
        return this.http.get<Content[]>(url, ApiService.options).toPromise();
    }

    async assignContentToExercise(exerciseId: number, contentUuid: string): Promise<Content> {
        const url = `${ApiService.url}exercises/${exerciseId}/contents/${contentUuid}`;
        return this.http.put<Content>(url, '', ApiService.options).toPromise();
    }

    async deleteContentFromExercise(exerciseId: number, contentUuid: string) {
        const url = `${ApiService.url}exercises/${exerciseId}/contents/${contentUuid}`;
        return this.http.delete(url, ApiService.options).toPromise();
    }

    async getExerciseFeedback(exerciseId: number, username: string): Promise<FeedBack[]> {
        const url = `${ApiService.url}exercises/${exerciseId}/user/${username}/feedbacks`;
        return this.http.get<FeedBack[]>(url, ApiService.options).toPromise();
    }

    async removeExercise(exercise: Exercise) {
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.title = this.translateService.instant(exercise.exerciseType + '.EXERCISE.ITEM_DELETE_MODAL_TITLE', {
            title: exercise.title,
        });
        modalConfig.titleIcon = 'warning-outline';
        modalConfig.description = this.translateService.instant(
            exercise.exerciseType + '.EXERCISE.ITEM_DELETE_MODAL_DESCRIPTION',
            {
                title: exercise.title,
            },
        );

        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'DELETE';
        modalConfig.buttonRight.buttonColor = 'danger';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            try {
                await this.deleteExercise(exercise.id);
                await this.toastService.showToast(
                    exercise.exerciseType + '.EXERCISE.ITEM_WAS_DELETE',
                    IonicColor.success,
                );
                this.router.navigate([
                    RoutingSegment.MEMBER,
                    RoutingSegment.ADMINISTRATION,
                    exercise.exerciseType.toLowerCase(),
                    RoutingSegment.MAIN,
                ]);
            } catch (err) {
                this.log.error('Error in deleteCurrentExercise', err);
                await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            }
        }
    }
}
