import { NgModule } from '@angular/core';
import { CheckFeaturesPipe } from './check-features.pipe';
import { MenuItemsFilterPipe } from './menu-items-filter.pipe';

@NgModule({
    declarations: [CheckFeaturesPipe, MenuItemsFilterPipe],
    imports: [],
    exports: [CheckFeaturesPipe, MenuItemsFilterPipe],
})
export class CoreCurafidaPipesModule {}
