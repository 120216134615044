import { Component, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { Content, ContentDto } from '../../../therapy/entities/content';
import { UpdateWebshopContentDto } from '../../entities/webshop-content.model';
import { WebshopContentService } from '../../services/webshop-content.service';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { UPLOAD_MIME_TYPE_WHITELIST } from '../../../common/entities/white-list-mime-type';
import { ExerciseContentsService } from '../../../therapy/services/exercise-contents';
import { ContentUsage } from '../../../therapy/entities/content/content-usage';
import { NgIf } from '@angular/common';
import { CommonComponentsModule } from '../../../common/components/common-components.module';

@Component({
    selector: 'lib-content-detail-definition',
    templateUrl: './content-detail-modal.component.html',
    styleUrls: ['./content-detail-modal.component.scss'],
    standalone: true,
    imports: [IonicModule, ReactiveFormsModule, NgIf, CommonComponentsModule],
})
export class ContentDetailModalComponent implements OnInit {
    Content = Content;

    contentForm: FormGroup;
    content: Content;

    private isEditEnabled = true;
    protected readonly log: Logger;
    whiteListMimeType = UPLOAD_MIME_TYPE_WHITELIST.join();

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly loggingService: LoggingService,
        private readonly modalCtrl: ModalController,
        private readonly toastService: ToastService,
        private readonly webshopContentService: WebshopContentService,
        private readonly exerciseContentService: ExerciseContentsService,
    ) {
        this.log = this.loggingService.getLogger(ContentDetailModalComponent.name);
    }

    async ngOnInit(): Promise<void> {
        this.contentForm = this.formBuilder.group({
            description: new FormControl(
                { value: this.content.description ?? '', disabled: !this.isEditEnabled },
                Validators.required,
            ),
            content: new FormControl({ value: this.content.origFileName ?? '', disabled: true }, Validators.required),
        });
    }

    async dismissModal(): Promise<void> {
        await this.modalCtrl.dismiss(false);
    }

    async saveContent(): Promise<void> {
        let contentRes: Content;
        try {
            if (this.content.contentUsage === ContentUsage.WEBSHOP_CONTENT) {
                const updateDto = new UpdateWebshopContentDto();
                updateDto.description = this.contentForm.get('description').value;
                contentRes = await this.webshopContentService.updateContent(this.content.uuid, updateDto);
            } else if (this.content.contentUsage === ContentUsage.EXERCISE_CONTENT) {
                const contentDto = new ContentDto();
                contentDto.description = this.contentForm.get('description').value;
                contentDto.mimeType = this.content.mimeType;
                contentRes = await this.exerciseContentService.updateExerciseContent(this.content.uuid, contentDto);
            } else {
                this.log.error(`Content-Usage ${this.content.contentUsage} not yet implemented for this Componente`);
            }
            await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
            await this.modalCtrl.dismiss({ content: contentRes });
        } catch (e) {
            await this.toastService.showToast(ToastService.errorMessageSave, IonicColor.danger);
            await this.modalCtrl.dismiss(false);
        }
    }
}
