import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ItemType, TableConfig, TableUpdateValue } from '../../../table/entities/table';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { UsersService } from '../../services/user';
import { SupervisorService } from '../../services/supervisor';
import { StringItemAdapterComponent } from '../../../table/components/table-adapter/string-item-adapter.component';
import { CheckBoxItemAdapterComponent } from '../../../table/components/table-adapter/checkbox-item-adapter.component';
import { CurafidaAuthService } from '../../../auth/services';
import { PatientReportTemplate } from '../../../common/components/patient-report-modal/patient-report.model';
import { PaginatedResponse } from '../../..//common/entities/paginated-response';

@Component({
    selector: 'lib-report-type-modal',
    templateUrl: './report-type-modal.component.html',
    styleUrls: ['./report-type-modal.component.scss'],
})
export class ReportTypeModalComponent implements OnInit {
    isLoading = true;
    isLoadingSuccess = false;

    title = 'REPORT.TEMPLATE.SELECT';
    patientReportTemplate: TableConfig<PatientReportTemplateResource[]> = new TableConfig();
    selectedReport: PatientReportTemplate;
    saveButtonText: string;
    isMultipleChoice = false;
    anyItem: string;
    limit = 10;

    constructor(
        protected modalCtrl: ModalController,
        private userService: UsersService,
        protected toastService: ToastService,
        private params: NavParams,
        private supervisorService: SupervisorService,
        private authService: CurafidaAuthService,
    ) {
        this.patientReportTemplate.isOpenDetailEnable = true;
        this.patientReportTemplate.itemSettings = [
            {
                id: 'selected',
                prop: 'selected',
                header: '',
                adapter: CheckBoxItemAdapterComponent,
                type: ItemType.ADAPTER,
                width: '8%',
                columnPosition: 0,
            },
            {
                id: 'name',
                prop: 'letterSubject',
                header: 'NAME',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '30%',
                columnPosition: 1,
            },
        ];
    }

    private _searchTerm: string;

    get searchTerm(): string {
        return this._searchTerm;
    }

    set searchTerm(value: string) {
        this._searchTerm = value;
        this.initReportTypeList({ offset: 0, limit: this.limit });
    }

    async ngOnInit(): Promise<void> {
        await this.initReportTypeList({ offset: 0, limit: this.limit });
    }

    async initReportTypeList(value: TableUpdateValue): Promise<void> {
        this.isLoading = true;
        this.isLoadingSuccess = false;
        try {
            this.patientReportTemplate.list = (await this.userService.getPatientReportTemplates(
                this.authService.getSession().user.groups[0],
                value.limit,
                value.offset,
            )) as PaginatedResponse<PatientReportTemplate[]>;
            this.isLoading = false;
            this.isLoadingSuccess = true;
        } catch (err) {
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            this.isLoading = false;
            this.isLoadingSuccess = false;
        }
    }

    async dismissModal(): Promise<void> {
        await this.modalCtrl.dismiss();
    }

    onActionOnItem(clickedReport: PatientReportTemplateResource): void {
        clickedReport.selected = !clickedReport.selected;
        if (clickedReport?.selected) {
            this.patientReportTemplate.list.items.map((clickedReport) => {
                if (clickedReport.uuid === this.selectedReport?.uuid) {
                    clickedReport.selected = false;
                }
            });
            this.selectedReport = clickedReport;
        } else {
            this.selectedReport = null;
        }
    }

    async returnSelectedUsers(): Promise<void> {
        await this.modalCtrl.dismiss(this.selectedReport);
    }
}

export interface PatientReportTemplateResource extends PatientReportTemplate {
    selected?: boolean;
}
