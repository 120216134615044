import { SortBy, SortOrder } from './paginated-response';

export interface SearchParameters {
    textSearchTerm?: string;
    offset?: number;
    limit?: number;
    sortBy?: SortBy | string;
    sortOrder?: SortOrder;
}

export class DefaultSearchParameters implements SearchParameters {
    public limit: number;

    public offset: number;

    public sortBy?: SortBy | string;

    public sortOrder?: SortOrder;

    public textSearchTerm?: string;

    /**
     * Constructs with initial default values.
     */
    public static build(defaultInitValues: SearchParameters): DefaultSearchParameters {
        if (defaultInitValues?.limit < 1 || defaultInitValues?.offset < 0) {
            throw new Error('limit and offset must be set');
        }
        const params = new DefaultSearchParameters();
        return params.patch(defaultInitValues);
    }

    public reset(): DefaultSearchParameters {
        this.offset = 0;
        return this;
    }

    /**
     * Updates values from changeSet only if set and valid.
     */
    public patch(changeSet: SearchParameters): DefaultSearchParameters {
        if (changeSet?.sortBy) {
            this.sortBy = changeSet.sortBy;
        }
        if (changeSet?.sortOrder) {
            this.sortOrder = changeSet.sortOrder;
        }
        if (changeSet?.limit > 0) {
            this.limit = changeSet.limit;
        }
        if (changeSet?.offset >= 0) {
            this.offset = changeSet.offset;
        }
        if (changeSet.textSearchTerm !== undefined && changeSet.textSearchTerm !== null) {
            this.textSearchTerm = changeSet.textSearchTerm;
        }
        return this;
    }
}
