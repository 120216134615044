import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CurafidaAuthService } from '../../../../../auth/services';
import { RoutingSegment } from '../../../../../common/entities/routing-segment';
import { Logger, LoggingService } from '../../../../../logging/logging.service';
import { Tag } from '../../../../entities/tag/tag.entity';
import { ExerciseSessionState } from '../../../../entities/exerciseSession';
import { PhysicalExerciseGoalDto } from '../../../../entities/exerciseGoal/exerciseGoalDto';
import { DurationUnit } from '../../../../entities/duration';
import { ContentMetaDataType } from '../../../../entities/content';
import { ExerciseContentsService } from '../../../../services/exercise-contents';
import {
    TherapySession,
    TherapySessionChangeEvent,
    TherapySessionState,
} from '../../../../entities/therapy-session/therapy-session';
import { UsersTherapySessionsService } from '../../../../services/users-therapy-sessions/users-therapy-sessions.service';
import { ToolbarService } from '../../../../../common/services/toolbar/toolbar.service';
import { SafeUrl } from '@angular/platform-browser';
import { PaginatedResponse } from '../../../../../common/entities/paginated-response';
import { User, UserRoles } from '../../../../../auth/entities/user';
import { ContentFormatType } from '../../../../../common/entities/content-format-type';
import { CommonComponentsModule } from '../../../../../common/components/common-components.module';
import { ToastService } from '../../../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../../../common/entities/toast/ionic-color';
import { TrainingPlanResource } from '../../../../entities/training/training-plan.resource';
import { TranslateService } from '@ngx-translate/core';
import { LoadingComponent } from '../../../../../common/components/loading/loading.component';

@Component({
    selector: 'lib-training-info-process',
    templateUrl: './training-info-process.component.html',
    styleUrls: ['./training-info-process.component.scss'],
    standalone: true,
    imports: [CommonComponentsModule, LoadingComponent],
})
export class TrainingInfoProcessComponent implements OnInit {
    ExerciseSessionState = ExerciseSessionState;
    infoTraining: InfoTrainingDefinition;
    loadingExercisesCompleted = false;
    loggedInUser: User;
    UserRoles = UserRoles;
    RoutingSegment = RoutingSegment;
    @Input()
    trainingPlan: TrainingPlanResource;
    @Output()
    emitNextProcessStep: EventEmitter<string> = new EventEmitter<string>();
    protected readonly log: Logger;
    private therapySessionList: PaginatedResponse<TherapySession[]>;
    lastTrainingInfo = '';
    restDaysInfo = '';
    completedTherapySessionsChangeEvents: TherapySessionChangeEvent[] = [];

    constructor(
        public router: Router,
        private loggingService: LoggingService,
        private authService: CurafidaAuthService,
        private exerciseContentService: ExerciseContentsService,
        private usersTherapySessionService: UsersTherapySessionsService,
        private toast: ToastService,
        private translateService: TranslateService,
    ) {
        this.loggedInUser = this.authService.getSession().user;
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit(): Promise<void> {
        await this.getTherapySessions();
        await this.setInfoTrainingDefinition();
        const restDays = this.trainingPlan.trainingRestDays;
        if (restDays > 0) this.restDaysInfo = this.translateService.instant(restDaysTranslations[restDays]);

        if (this.trainingPlan.therapySessions.length > 0) {
            for (const therapySession of this.trainingPlan.therapySessions) {
                const completedEventsInSession = therapySession.changeEvents.filter(
                    (tsce) => tsce.newState === TherapySessionState.COMPLETED,
                );

                this.completedTherapySessionsChangeEvents.push(...completedEventsInSession);
            }

            this.completedTherapySessionsChangeEvents.sort((a, b) => {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            });

            if (this.completedTherapySessionsChangeEvents.length > 0) {
                const latestEvent = this.completedTherapySessionsChangeEvents[0];

                this.lastTrainingInfo = this.translateService.instant(
                    'TRAINING.PLAN.REST_INFORMATION_LATEST_TRAINING_DATE',
                    {
                        date: latestEvent.created_at?.toString().replace(/T.*/, '').split('-').reverse().join('.'),
                    },
                );
            }
        }
    }

    private async getTherapySessions() {
        try {
            this.therapySessionList = await this.usersTherapySessionService.getUserTherapySessions({
                username: this.authService.getSession().user.username,
                therapyId: this.trainingPlan.id,
                offset: 0,
                limit: 1,
                state: TherapySessionState.RUNNING,
            });
        } catch (error) {
            this.log.error(error);
            this.toast.showToast(ToastService.errorMessage, IonicColor.danger);
        }
    }

    private async setInfoTrainingDefinition() {
        this.infoTraining = new InfoTrainingDefinition();
        let therapySession: TherapySession;
        if (this.therapySessionList.total !== 0) {
            therapySession = this.therapySessionList.items[0];
        }
        this.infoTraining.title = this.trainingPlan.title;
        this.infoTraining.exercises = [];
        this.infoTraining.tags = [];
        if (this.trainingPlan.startDate) {
            const today = new Date();
            const startDate = new Date(this.trainingPlan.startDate);
            this.infoTraining.isActive = today.toDateString() === startDate.toDateString() || startDate < today;
        }
        for (const exercise of this.trainingPlan.exercises) {
            const infoTrainingExercise = new InfoTrainingExercise();
            infoTrainingExercise.title = exercise.title;
            infoTrainingExercise.id = exercise.id;
            if (
                therapySession &&
                therapySession.exerciseSessionsOfUser &&
                therapySession.exerciseSessionsOfUser.length > 0
            ) {
                infoTrainingExercise.state = therapySession.exerciseSessionsOfUser.find(
                    (ex) => ex.exerciseSession.exercise.id === exercise.id,
                )?.exerciseSession?.exerciseSessionState;
            }
            infoTrainingExercise.order = exercise.order;
            const contentUuid = exercise.contents.filter(
                (i) => i.jsonData?.contentMetaDataType === ContentMetaDataType.THUMBNAIL,
            )[0]?.uuid;
            if (contentUuid) {
                try {
                    infoTrainingExercise.contentUrl = await this.exerciseContentService.getObjectURLContentFromUrl(
                        contentUuid,
                        ContentFormatType.THUMBNAIL,
                    );
                } catch (error) {
                    this.log.error(error);
                    this.toast.showToast(ToastService.errorMessage, IonicColor.danger);
                }
            }
            const exerciseGoal = this.getActiveExerciseGoal(exercise.exerciseGoals as PhysicalExerciseGoalDto[]);
            infoTrainingExercise.series = exerciseGoal?.series;
            infoTrainingExercise.duration = exerciseGoal?.duration;
            infoTrainingExercise.durationUnit = exerciseGoal?.durationUnit;
            if (exercise.tags && exercise.tags.length > 0) {
                for (const tag of exercise.tags) {
                    if (
                        !this.infoTraining.tags.find((t) => t.uuid === tag.uuid) &&
                        tag?.tagCategory &&
                        tag?.tagCategory[0]?.label === 'Equipment'
                    ) {
                        this.infoTraining.tags.push(tag);
                    }
                }
            }
            this.infoTraining.exercises.push(infoTrainingExercise);
        }
        this.infoTraining.exercises = this.infoTraining.exercises.sort((esou1, esou2) => {
            return esou1?.order - esou2?.order;
        });
        this.loadingExercisesCompleted = true;
    }

    async startTraining(): Promise<void> {
        try {
            if (this.therapySessionList.count === 0) {
                const therapySession = await this.usersTherapySessionService.createUserTherapySession(
                    this.authService.getSession().user.username,
                    this.trainingPlan.id,
                );
                this.emitNextProcessStep.emit(therapySession.uuid);
            } else {
                const therapySession = await this.usersTherapySessionService.getUserTherapySessions({
                    username: this.authService.getSession().user.username,
                    therapyId: this.trainingPlan.id,
                    state: TherapySessionState.RUNNING,
                });
                this.emitNextProcessStep.emit(therapySession.items[0].uuid);
            }
        } catch (error) {
            this.log.error(error);
            this.toast.showToast(ToastService.errorMessageSave, IonicColor.danger);
        }
    }

    private getActiveExerciseGoal(exerciseGoals: PhysicalExerciseGoalDto[]): PhysicalExerciseGoalDto {
        if (!exerciseGoals || exerciseGoals.length < 1) {
            this.log.error('No exercise goals present');
            return undefined;
        }
        const progressedGoal = exerciseGoals.find((goal) => goal.active && goal.username);
        const baseGoal = exerciseGoals.find((goal) => goal.active && !goal.username);
        if (!progressedGoal && !baseGoal) {
            this.log.error('No active goal found');
            return undefined;
        }
        return progressedGoal ?? baseGoal;
    }
}

class InfoTrainingDefinition {
    title: string;
    tags: Tag[];
    exercises: InfoTrainingExercise[];
    isActive: boolean;
}

class InfoTrainingExercise {
    title: string;
    id: number;
    order: number;
    state: ExerciseSessionState;
    contentUrl: SafeUrl | URL | string;
    series: number;
    duration: number;
    durationUnit: DurationUnit;
}

export const restDaysTranslations = {
    1: 'TRAINING.PLAN.REST_INFORMATION_1_DAY',
    2: 'TRAINING.PLAN.REST_INFORMATION_2_DAY',
    3: 'TRAINING.PLAN.REST_INFORMATION_3_DAY',
    4: 'TRAINING.PLAN.REST_INFORMATION_4_DAY',
    5: 'TRAINING.PLAN.REST_INFORMATION_5_DAY',
    6: 'TRAINING.PLAN.REST_INFORMATION_6_DAY',
};
