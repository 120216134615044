import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { FeedbackComment, FeedbackCommentDto, FeedbackFlag } from '../../../entities/feedback/feedback-comment';
import { FeedbackCommentsService } from '../../../services/feedback-comments';
import { Logger, LoggingService } from '../../../../logging/logging.service';

@Component({
    selector: 'lib-exercise-comment',
    templateUrl: './exercise-comment-modal.component.html',
    styleUrls: ['./exercise-comment-modal.component.scss'],
})
export class ExerciseCommentModalComponent implements OnInit {
    protected readonly log: Logger;
    @Input()
    feedbackComment: FeedbackComment;
    @Input()
    title: string;
    saveButtonText = 'Speichern';
    feedbackCommentForm: FormGroup;
    feedbackFlags = [
        { value: FeedbackFlag.GREEN, label: FeedbackFlag.GREEN },
        { value: FeedbackFlag.YELLOW, label: FeedbackFlag.YELLOW },
        { value: FeedbackFlag.RED, label: FeedbackFlag.RED },
        { value: FeedbackFlag.NONE, label: FeedbackFlag.NONE },
    ];
    private isEditEnabled = true;

    constructor(
        protected modalCtrl: ModalController,
        private formBuilder: FormBuilder,
        protected toastService: ToastService,
        private params: NavParams,
        private feedBackCommentService: FeedbackCommentsService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit() {
        if (!this.feedbackComment) this.feedbackComment = new FeedbackComment();
        await this.initExerciseConmmentForm();
    }

    async initExerciseConmmentForm() {
        const text = this.feedbackComment.text == null ? '' : this.feedbackComment.text;
        const flag = this.feedbackComment.flag == null ? '' : this.feedbackComment.flag;
        this.feedbackCommentForm = this.formBuilder.group({
            text: new FormControl({ value: text, disabled: !this.isEditEnabled }, Validators.required),
            flag: new FormControl({ value: flag, disabled: !this.isEditEnabled }, Validators.required),
        });
    }

    async dismissModal() {
        await this.modalCtrl.dismiss();
    }

    async saveExerciseContent() {
        if (this.feedbackComment.uuid) {
            try {
                const feedbackComment = new FeedbackCommentDto();
                feedbackComment.flag = this.feedbackCommentForm.controls.flag.value;
                feedbackComment.text = this.feedbackCommentForm.controls.text.value;
                await this.feedBackCommentService.updateFeedbackComments(this.feedbackComment.uuid, feedbackComment);
                await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
            } catch (e) {
                this.log.error('Error in saveExerciseContent', e);
                await this.toastService.showToast(ToastService.errorMessageSave, IonicColor.danger);
            }
        } else {
            try {
                const feedbackComment = new FeedbackCommentDto();
                feedbackComment.flag = this.feedbackCommentForm.controls.flag.value;
                feedbackComment.text = this.feedbackCommentForm.controls.text.value;
                await this.feedBackCommentService.createFeedbackComments(feedbackComment);
                await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
            } catch (e) {
                this.log.error('Error in saveExerciseContent', e);
                await this.toastService.showToast(ToastService.errorMessageSave, IonicColor.danger);
            }
        }

        await this.modalCtrl.dismiss();
    }
}
