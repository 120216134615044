import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import {
    ExerciseSession,
    ExerciseSessionOfUser,
    ExerciseSessionState,
    ExerciseSessionUserState,
    ExerciseSubType,
} from '../../entities/exerciseSession';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { User, UserRoles } from '../../../auth/entities/user';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { UserExerciseSessionsService } from '../../services/user-exercise-sessions';
import { ModalController, NavParams } from '@ionic/angular';
import { StyleService } from '../../../common/services/style/style.service';
import { CurafidaAuthService } from '../../../auth/services';
import { QuestionnaireTemplate } from '../../../my-medax/entities/questionnaire-template';
import { TherapyMyMedaxService } from '../../services/therapy-my-medax';
import { MyMedaxFillLink } from '../../../my-medax/entities/my-medax-fill-link';
import { QuestionnaireType } from '../../../my-medax/entities/questionnaire-type';
import { MyMedaxQuestionnaireSubmissionComponent } from '../../../my-medax/components/my-medax-questionnaire-submission/my-medax-questionnaire-submission.component';
import { MyMedaxService } from '../../../my-medax/services/my-medax.service';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { Subscription } from 'rxjs';
import { isFuture, isPast } from 'date-fns';
import { SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ExerciseSessionsService } from '../../services/exercise-sessions';
import { BrowserNavigationService } from '../../../common/services/browser-navigation/browser-navigation.service';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lib-exercise-session-filer-modal',
    templateUrl: './exercise-session-filter-modal.component.html',
    styleUrls: ['./exercise-session-filter-modal.component.scss'],
})
export class ExerciseSessionFilterModalComponent implements OnInit, AfterViewInit {
    @ViewChild('viewSelect') viewSelect: HTMLIonSelectElement;
    @Input()
    exerciseSessionOfUser: ExerciseSessionOfUser;
    @Input()
    isMobile = false;
    @Input()
    patient: User;
    userRoleOfBetreuer = UserRoles.CAREGIVER;
    title = 'Aufgabe';
    subtitle = '';
    ExerciseSessionState = ExerciseSessionState;
    form: QuestionnaireTemplate;
    loggedInUser: User;
    fromMyMedax = false;
    userRoles: UserRoles[] = [];
    UserRoles = UserRoles;
    isEndTimeInPast = false;
    isButtonDisable = false;
    queryParamsSubscription: Subscription;
    hideResult: boolean;
    viewModes = 'reduce';
    itemList = [
        { value: 'all', label: 'Vollansicht' },
        { value: 'reduce', label: 'Kompaktansicht' },
    ];
    showCalendarEvent = false;
    showAppointment = false;
    ExerciseSubType = ExerciseSubType;
    protected readonly log: Logger;
    protected readonly ExerciseSessionUserState = ExerciseSessionUserState;
    private element: HTMLElement;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toastService: ToastService,
        private authService: CurafidaAuthService,
        private route: ActivatedRoute,
        private styleService: StyleService,
        private userExerciseSessionsService: UserExerciseSessionsService,
        private therapyMyMedaxService: TherapyMyMedaxService,
        private myMedaxService: MyMedaxService,
        private modalCtrl: ModalController,
        private loggingService: LoggingService,
        private params: NavParams,
        private exerciseSessionService: ExerciseSessionsService,
        private browser: BrowserNavigationService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    ngOnInit() {
        this.queryParamsSubscription = this.route.queryParams.subscribe((params) => {
            // if this page was called after a redirection from mymedax a timeout is called
            // to give the async update of the exerciseSessionState some time
            if (params && params.fromMyMedax && params.fromMyMedax === 'true') {
                setTimeout(async () => {
                    this.router.navigate([], { queryParams: { fromMyMedax: null }, queryParamsHandling: 'merge' });
                }, 1000);
            }
        });
        this.initComponent();
    }

    isStartInFuture(exerciseSession: ExerciseSession) {
        return exerciseSession.appointment?.startTime !== ''
            ? isFuture(new Date(exerciseSession.appointment?.startTime))
            : false;
    }

    async initComponent() {
        this.exerciseSessionOfUser = this.params.get('task');
        this.patient = this.params.get('patient');
        this.exerciseSessionOfUser = await this.userExerciseSessionsService.getExerciseSessionOfUser(
            this.patient.username,
            Number(this.exerciseSessionOfUser.exerciseSession_id),
        );
        this.title = this.exerciseSessionOfUser.exerciseSession.exercise.title;
        this.userRoles = this.authService.getSession()?.user.roles;
        if (this.viewSelect) this.viewSelect.value = 'reduce';
        if (this.exerciseSessionOfUser.exerciseSession.calendarEvent) this.showCalendarEvent = true;
        if (
            this.exerciseSessionOfUser.exerciseSession.appointment.startTime ||
            this.exerciseSessionOfUser.exerciseSession.appointment.endTime
        ) {
            this.showAppointment = true;
        }
    }

    async openMyMedax() {
        let returnUri = window.location.href;
        returnUri = returnUri.slice(-1) === '/' ? returnUri.slice(0, -1) : returnUri;
        if (returnUri.includes('?')) {
            returnUri = returnUri + `&fromMyMedax=true&taskId=${this.exerciseSessionOfUser.exerciseSession_id}`;
        } else {
            returnUri = returnUri + `?fromMyMedax=true&taskId=${this.exerciseSessionOfUser.exerciseSession_id}`;
        }
        if (this.styleService.isMobile$) {
            returnUri = null;
        }
        const fillLink: MyMedaxFillLink = await this.therapyMyMedaxService.getMyMedaxFillLink(
            this.patient.username,
            this.exerciseSessionOfUser.exerciseSession_id,
            returnUri,
            QuestionnaireType.TASK,
        );
        this.browser.openTargetSelf(fillLink.url);
    }

    async showQuestionnaireModal() {
        const submissions = await this.myMedaxService.getQuestionnaireSubmissionsByUser(
            this.patient.username,
            null,
            null,
            SortBy.UPDATED_AT,
            SortOrder.DESC,
            null,
            null,
            null,
            null,
            null,
            null,
        );
        if (submissions.items.length > 0) {
            const modal = await this.modalCtrl.create({
                component: MyMedaxQuestionnaireSubmissionComponent,
                backdropDismiss: true,
                componentProps: {
                    myMedaxQuestionnaireSubmission: submissions.items[0],
                },
            });
            await modal.present();
            const { data } = await modal.onDidDismiss();
            return data;
        }
    }

    async finishTask() {
        try {
            if (this.exerciseSessionOfUser.exerciseSessionUserState === ExerciseSessionUserState.PLANNED) {
                await this.userExerciseSessionsService.postUsersExerciseSessionActivate(
                    this.patient.username,
                    this.exerciseSessionOfUser.exerciseSession.id,
                );
            }
            await this.userExerciseSessionsService.postUsersExerciseSessionFinish(
                this.patient.username,
                this.exerciseSessionOfUser.exerciseSession.id,
            );
            this.exerciseSessionOfUser.exerciseSessionUserState = ExerciseSessionUserState.FINISHED;
            await this.toastService.showToast('Die Aufgabe wurde erfolgreich abgeschlossen.', IonicColor.success);
        } catch (e) {
            this.log.error('Error in finishTask', e);
            await this.toastService.showToast(
                'Beim Abschließen der Aufgabe ist ein Fehler aufgetreten.',
                IonicColor.danger,
            );
        } finally {
            await this.dismissModal();
        }
    }

    async cancelTask() {
        if (
            !(
                this.exerciseSessionOfUser.exerciseSessionUserState === ExerciseSessionUserState.PLANNED ||
                this.exerciseSessionOfUser.exerciseSessionUserState === ExerciseSessionUserState.ACTIVE
            )
        ) {
            await this.toastService.showToast(
                'Beim Ablehnen der Aufgabe ist ein Fehler aufgetreten.',
                IonicColor.danger,
            );
        }
        try {
            await this.exerciseSessionService.postExerciseSessionCancel(this.exerciseSessionOfUser.exerciseSession.id);
            this.exerciseSessionOfUser.exerciseSessionUserState = ExerciseSessionUserState.PATIENT_CANCELLED;
            await this.toastService.showToast('Die Aufgabe wurde abgelehnt.', IonicColor.success);
        } catch (e) {
            this.log.error('Error in cancelTask', e);
            await this.toastService.showToast(
                'Beim Ablehnen der Aufgabe ist ein Fehler aufgetreten.',
                IonicColor.danger,
            );
        } finally {
            await this.dismissModal();
        }
    }

    async dismissModal() {
        const queryParams: Params = { fromMyMedax: null, taskId: null };
        await this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams,
            queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
        await this.modalCtrl.dismiss('true');
    }

    checkedEndTimeInPast() {
        if (!this.exerciseSessionOfUser.exerciseSession.appointment?.endTime) return false;
        this.isEndTimeInPast =
            this.exerciseSessionOfUser.exerciseSession.appointment?.endTime !== ''
                ? isPast(new Date(this.exerciseSessionOfUser.exerciseSession.appointment?.endTime))
                : false;
    }

    setView(event: any) {
        this.viewModes = event.detail.value;
        this.element = document.getElementById('CaregiverTaskPreviewComponent');
        if (this.viewModes === 'all') {
            this.element.classList.add('task-preview-full-information-modal');
            this.element.classList.remove('task-preview-modal');
        } else if (this.viewModes === 'reduce') {
            this.element.classList.add('task-preview-modal');
            this.element.classList.remove('task-preview-full-information-modal');
        }
        event.stopPropagation;
    }

    ngAfterViewInit(): void {
        if (this.viewSelect) this.viewSelect.value = 'reduce';
    }
}
