import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../api';
import { UsersTherapyStats } from '../../entities/user-therapy-stats/users-therapy-stats';
import { ParentTherapyProgress } from '../../entities/user-therapy-stats/parent-therapy-progress';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { differenceInDays } from 'date-fns';
import { DaysProgressed, ProgramStats } from '../../../therapy/entities/therapy/therapy-stats';

@Injectable({
    providedIn: 'root',
})
export class TherapyStatsService {
    constructor(protected http: HttpClient) {}

    async getUserTherapyStats(username: string): Promise<UsersTherapyStats[]> {
        const url = `users/${username}/therapyStats`;
        return this.http.get<UsersTherapyStats[]>(url).toPromise();
    }

    async getParentTherapyProgress(
        username?: string,
        limit?: number,
        offset?: number,
    ): Promise<PaginatedResponse<ParentTherapyProgress[]>> {
        const url = new URL(`${ApiService.url}users/${username ?? '-'}/parentTherapyProgresses`);
        if (limit && offset) {
            url.searchParams.set('limit', limit.toString());
            url.searchParams.set('offset', offset.toString());
        }
        return this.http.get<PaginatedResponse<ParentTherapyProgress[]>>(url.toString()).toPromise();
    }

    private getDaysProgressed = ({ startDate, endDate }: ParentTherapyProgress): DaysProgressed => {
        if (!startDate || !endDate) return null;
        /*
         * differenceInDays() only counts the full days between two dates.
         * Since the start date is at the start of day (00:00) and the end date is at the end of day (23:59)
         * this will not return a full day, so we add one to make up for this.
         * See https://gitlab.ztm-badkissingen.de/curafida/development/ionic-common/-/issues/7902#note_186590
         */
        const total = differenceInDays(new Date(endDate), new Date(startDate)) + 1;
        const completed =
            differenceInDays(new Date(), new Date(startDate)) >= 0
                ? differenceInDays(new Date(), new Date(startDate)) + 1
                : 0;
        const daysProgressed: DaysProgressed = {
            days: Math.min(completed, total),
            total: total,
        };
        return daysProgressed;
    };

    private floatToPercent = (value: number): number => {
        return Math.round(value * 100);
    };

    getProgramStats(parentTherapyProgress: ParentTherapyProgress): ProgramStats {
        return {
            daysProgressed: this.getDaysProgressed(parentTherapyProgress),
            progressTotalPercent: this.floatToPercent(parentTherapyProgress.progressTotal),
            progressWhereAvailablePercent: this.floatToPercent(parentTherapyProgress.progressWhereAvailable),
        };
    }
}
