import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RangeType } from '../../entities/training/range.typ';
import { ActionType, IPhysicalExerciseGoal, ItemAdapterComponent, TableItem } from '../../../table/entities/table';

@Component({
    templateUrl: 'range-selector-item-adapter.component.html',
})
export class RangeSelectAdapterComponent implements ItemAdapterComponent {
    @Input() data: { disabled: boolean };
    @Input() itemSetting: TableItem;
    @Output()
    sendUpdateOfExerciseGoal = new EventEmitter<{ action: ActionType; exerciseGoal: IPhysicalExerciseGoal }>();
    RangeType = RangeType;

    changeRangeValue(value: number, exerciseGoal: IPhysicalExerciseGoal, rangeType: RangeType) {
        if (rangeType === RangeType.INTENSITY) exerciseGoal.intensity = value;
        if (rangeType === RangeType.DURATION) exerciseGoal.duration = value;
        if (rangeType === RangeType.SERIES) exerciseGoal.series = value;
        if (rangeType === RangeType.PULSE) exerciseGoal.pulse = value;
        if (rangeType === RangeType.PULSE_VARIANCE) exerciseGoal.pulseVariance = value;
        this.sendUpdateOfExerciseGoal.emit({ action: ActionType.UPDATE, exerciseGoal });
    }
}
