import { NgModule } from '@angular/core';
import { HandleEmptyValuePipe } from './handle-empty-value.pipe';
import { FilterMobilePipe } from './filter-mobile.pipe';
import { DurationPipe } from './duration.pipe';
import { DateTransformPipe } from './date.pipe';
import { FilterButtonMobilePipe } from './filter-button-mobile.pipe';
import { ContainsNotPipe, ContainsPipe, SortPipe } from './array.pipe';

@NgModule({
    declarations: [
        HandleEmptyValuePipe,
        FilterMobilePipe,
        DurationPipe,
        DateTransformPipe,
        FilterButtonMobilePipe,
        SortPipe,
        ContainsPipe,
        ContainsNotPipe,
    ],
    imports: [],
    exports: [
        HandleEmptyValuePipe,
        FilterMobilePipe,
        DurationPipe,
        DateTransformPipe,
        FilterButtonMobilePipe,
        SortPipe,
        ContainsPipe,
        ContainsNotPipe,
    ],
})
export class PipeTableLib {}
