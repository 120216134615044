import { Exercise } from '../exercise';
import { DurationUnit } from '../duration';

export class ExerciseGoal {
    uuid: string;
    username?: string;
    created_at?: Date;
    exercise?: Exercise;
    exerciseGoalType?: ExerciseGoalType = ExerciseGoalType.EXERCISE_GOAL;
    active: boolean;
    exerciseId?: number;
    therapyId?: number;
    additionalParams?: ExerciseGoalAdditionalParam[];
}

export class ExerciseGoalDto extends ExerciseGoal {
    exerciseGoalType = ExerciseGoalType.EXERCISE_GOAL;
    active = true;
}

export class ExerciseGoalDtoWrapper {
    exerciseGoalDto: ExerciseGoalDto | PhysicalExerciseGoalDto;

    constructor(exerciseGoalDto: ExerciseGoalDto | PhysicalExerciseGoalDto) {
        this.exerciseGoalDto = exerciseGoalDto;
    }
}

export enum IntensityUnit {
    NONE = 'NONE',
    STEP = 'STEP',
    WATT = 'WATT',
    SPEED = 'SPEED',
    PACE = 'PACE',
    KILOGRAMS = 'KILOGRAMS',
    LEVEL = 'LEVEL',
    KMH = 'KMH',
}

export enum ExerciseGoalType {
    EXERCISE_GOAL = 'ExerciseGoal',
    PHYSICAL_EXERCISE_GOAL = 'PhysicalExerciseGoal',
}

export enum StressUnit {
    NONE = 'NONE',
    PULSE = 'PULSE',
}

export enum EffortUnit {
    NONE = 'NONE',
    BORG_0_TO_10 = 'BORG_0_TO_10',
}

export enum PainUnit {
    NONE = 'NONE',
    PAIN_0_TO_10 = 'PAIN_0_TO_10',
}

export enum FeedbackCommentUnit {
    NONE = 'NONE',
    FEEDBACK_COMMENT = 'FEEDBACK_COMMENT',
}

export class PhysicalExerciseGoalDto extends ExerciseGoal {
    mainGoal: string;
    goalType: string;
    username: string;
    exerciseGoalType = ExerciseGoalType.PHYSICAL_EXERCISE_GOAL;
    exerciseId: number;
    therapyId: number;
    additionalParams: ExerciseGoalAdditionalParam[];
    series: number;
    duration: number;
    durationUnit: DurationUnit;
    intensity: number;
    intensityUnit: IntensityUnit;
    borg: number;
    borgVariance: number;
    pulse: number;
    pulseVariance: number;
    stressUnit: StressUnit;
    effortUnit: EffortUnit;
    painUnit: PainUnit;
    feedbackCommentUnit: FeedbackCommentUnit;

    static returnPhysicalExerciseGoalDtoToSend(exerciseGoalDto: PhysicalExerciseGoalDto): PhysicalExerciseGoalDto {
        const physicalExerciseGoalDto = new PhysicalExerciseGoalDto();
        physicalExerciseGoalDto.goalType = exerciseGoalDto?.goalType;
        physicalExerciseGoalDto.username = exerciseGoalDto?.username;
        physicalExerciseGoalDto.exerciseGoalType = ExerciseGoalType.PHYSICAL_EXERCISE_GOAL;
        physicalExerciseGoalDto.exerciseId = exerciseGoalDto?.exerciseId;
        physicalExerciseGoalDto.series = exerciseGoalDto?.series;
        physicalExerciseGoalDto.duration = exerciseGoalDto?.duration;
        physicalExerciseGoalDto.durationUnit = exerciseGoalDto?.durationUnit;
        physicalExerciseGoalDto.intensity = exerciseGoalDto?.intensity;
        physicalExerciseGoalDto.intensityUnit = exerciseGoalDto?.intensityUnit;
        physicalExerciseGoalDto.borg = exerciseGoalDto?.borg;
        physicalExerciseGoalDto.borgVariance = exerciseGoalDto?.borgVariance;
        physicalExerciseGoalDto.pulse = exerciseGoalDto?.pulse;
        physicalExerciseGoalDto.pulseVariance = exerciseGoalDto?.pulseVariance;
        physicalExerciseGoalDto.active = exerciseGoalDto?.active;
        physicalExerciseGoalDto.therapyId = exerciseGoalDto?.therapyId;
        physicalExerciseGoalDto.additionalParams = exerciseGoalDto?.additionalParams;
        physicalExerciseGoalDto.stressUnit = exerciseGoalDto?.stressUnit;
        physicalExerciseGoalDto.effortUnit = exerciseGoalDto?.effortUnit;
        physicalExerciseGoalDto.painUnit = exerciseGoalDto?.painUnit;
        physicalExerciseGoalDto.feedbackCommentUnit = exerciseGoalDto?.feedbackCommentUnit;
        delete physicalExerciseGoalDto.created_at;
        delete physicalExerciseGoalDto.uuid;
        delete physicalExerciseGoalDto.exercise;
        return physicalExerciseGoalDto;
    }
}

export class TemplateExerciseGoal extends ExerciseGoal {
    id?: number;
    mainGoal?: string;
    goalType?: string;
    therapyTemplateId?: number;
    uuid: string;
    created_at?: Date;
    therapyTemplateExerciseId?: number;
    exerciseSeriesTemplateExerciseUuid?: string;
    templateExerciseSeriesExerciseUuid?: string;
    exerciseGoalType?: ExerciseGoalType = ExerciseGoalType.PHYSICAL_EXERCISE_GOAL;
    active: boolean = true;
    exerciseId?: number;
    additionalParams?: ExerciseGoalAdditionalParam[];
    series?: number;
    duration?: number;
    durationUnit?: DurationUnit;
    intensity?: number;
    intensityUnit?: IntensityUnit;
    pulse?: number;
    pulseVariance?: number;
    stressUnit?: StressUnit;
    effortUnit?: EffortUnit;
    painUnit?: PainUnit;
    feedbackCommentUnit?: FeedbackCommentUnit;
    isProgressed?: boolean = false;
}

export class TherapyExerciseGoal extends ExerciseGoal {
    id?: number;
    mainGoal?: string;
    goalType?: string;
    exerciseGoalType?: ExerciseGoalType = ExerciseGoalType.PHYSICAL_EXERCISE_GOAL;
    active: boolean;
    exerciseId?: number;
    therapyId?: number;
    therapyExerciseId?: number;
    additionalParams?: ExerciseGoalAdditionalParam[];
    series?: number;
    duration?: number;
    durationUnit?: DurationUnit;
    intensity?: number;
    intensityUnit?: IntensityUnit;
    borg?: number;
    borgVariance?: number;
    pulse?: number;
    pulseVariance?: number;
    stressUnit?: StressUnit;
    effortUnit?: EffortUnit;
    painUnit?: PainUnit;
    feedbackCommentUnit?: FeedbackCommentUnit;
    exerciseSeriesExerciseUuid?: string;
}

export class ExerciseGoalAdditionalParam {
    name: string;
    description: string;
    value: string;
    unitString: string;
}
