import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../../../api';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { TaskTemplatesSearchParameters } from '../../entities/exercise/task-template.dto';
import { TaskTemplateResource } from '../../entities/exercise/task-template.resource';
import { TaskTemplateDto } from '../../pages/task/task-template-detail/task-template.model';

@Injectable({
    providedIn: 'root',
})
export class TaskTemplateService {
    constructor(
        public router: Router,
        protected http: HttpClient,
    ) {}

    getTaskTemplates(parameters: TaskTemplatesSearchParameters): Promise<PaginatedResponse<TaskTemplateResource[]>> {
        const url = new URL(`${ApiService.url}task-templates`);
        if (parameters.offset > -1) url.searchParams.set('offset', String(parameters.offset));
        if (parameters.limit) url.searchParams.set('limit', String(parameters.limit));
        if (parameters.textSearchTerm) url.searchParams.set('textSearchTerm', String(parameters.textSearchTerm));
        if (parameters.sortOrder) url.searchParams.set('sortOrder', String(parameters.sortOrder));
        if (parameters.sortBy) url.searchParams.set('sortBy', String(parameters.sortBy));
        if (parameters.exerciseSubType) url.searchParams.set('exerciseSubType', String(parameters.exerciseSubType));
        if (parameters.tagUuids) {
            for (const tagUuid of parameters.tagUuids) {
                url.searchParams.set('tagUuids', String(tagUuid));
            }
        }
        return this.http.get<PaginatedResponse<TaskTemplateResource[]>>(url.toString(), ApiService.options).toPromise();
    }

    createTaskTemplate(
        taskTemplateDto: TaskTemplateDto,
        scormZipFile?: File,
        files?: File[],
    ): Observable<HttpEvent<TaskTemplateResource>> {
        const url = `${ApiService.url}task-templates`;
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        headers.append('content-type', 'multipart/form-data');
        const formData = new FormData();
        formData.append('taskTemplateDto', JSON.stringify(taskTemplateDto));
        if (scormZipFile) {
            formData.append('scormZipFile', scormZipFile);
        }
        if (files) {
            files.forEach((file) => formData.append('files', file));
        }
        return this.http.post<TaskTemplateResource>(url, formData, {
            headers,
            withCredentials: true,
            reportProgress: true,
            observe: 'events',
        });
    }

    async updateTaskTemplate(
        taskTemplateId: number,
        taskDto: TaskTemplateDto,
        files?: File[],
    ): Promise<TaskTemplateResource> {
        const url = `${ApiService.url}task-templates/${taskTemplateId}`;
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        headers.append('content-type', 'multipart/form-data');
        const multipartOptions = { headers, withCredentials: true };
        const formData = new FormData();
        formData.append('taskTemplateDto', JSON.stringify(taskDto));
        if (files) {
            files.forEach((file) => formData.append('files', file));
        }
        return this.http.put<TaskTemplateResource>(url, formData, multipartOptions).toPromise();
    }

    async getTaskTemplateById(taskTemplateId: number): Promise<TaskTemplateResource> {
        const url = `${ApiService.url}task-templates/${taskTemplateId}`;
        return this.http.get<TaskTemplateResource>(url, ApiService.options).toPromise();
    }
}
