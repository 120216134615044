import { TagCategory } from './tag-category.entity';
import { BaseTag } from './base-tag.entity';
import { Exercise } from '../exercise';
import { ExerciseType } from '../exerciseSession';

export class TagCreationDto {
    label: string;
    exerciseType: ExerciseType;
    categoryUuid: string;

    constructor(label: string, exerciseType: ExerciseType, categoryUuid: string) {
        this.label = label;
        this.exerciseType = exerciseType;
        this.categoryUuid = categoryUuid;
    }
}

export class TagUpdateDto {
    uuid: string;
    label: string;
    exerciseType: ExerciseType;

    constructor(uuid: string, label: string, exerciseType: ExerciseType) {
        this.uuid = uuid;
        this.label = label;
        this.exerciseType = exerciseType;
    }
}

export class Tag extends BaseTag {
    tagCategory: TagCategory;
    exercises?: Exercise[];
}
