import { Component, Input, OnInit } from '@angular/core';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import {
    ActionType,
    ButtonItemAdapterComponent,
    ItemType,
    TableConfig,
    TableUpdateValue,
} from '../../../table/entities/table';
import { SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { TherapyTemplate } from '../../entities/therapy/therapy-template';
import { Router } from '@angular/router';
import { ModalAlertService } from '../../../common/services/modal';
import { TherapyTemplatesService } from '../../services/therapy-templates';
import { RoutingSegment } from '../../../common/entities/routing-segment';
import { ExerciseType } from '../../entities/exerciseSession';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { StringItemAdapterComponent } from '../../../table/components/table-adapter/string-item-adapter.component';
import { CommonComponentsModule } from '../../../common/components/common-components.module';
import { CurafidaTableComponent } from '../../../table/components/curafida-table/curafida-table.component';
import { DefaultSearchParameters } from '../../../common/entities/search-parameters';

@Component({
    selector: 'lib-therapy-template-list',
    templateUrl: './therapy-template-list.component.html',
    styleUrls: ['./therapy-template-list.component.scss'],
    standalone: true,
    imports: [CommonComponentsModule, CurafidaTableComponent],
})
export class TherapyTemplateListComponent implements OnInit {
    therapyTemplateListConfig: TableConfig<TherapyTemplate[]> = new TableConfig();
    @Input()
    anyItemLabel: string;
    @Input()
    exerciseType = ExerciseType.TASK;
    @Input()
    hideSearch = false;
    protected readonly log: Logger;
    isLoadingProcessing = true;
    isLoadingSuccess = false;

    protected currentSearchParams = DefaultSearchParameters.build({
        limit: 10,
        offset: 0,
        sortBy: SortBy.TITLE,
        sortOrder: SortOrder.ASC,
    });

    constructor(
        private therapyTemplatesService: TherapyTemplatesService,
        private router: Router,
        protected modalAlertService: ModalAlertService,
        protected toastService: ToastService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit() {
        this.initTable();
        await this.updateTherapyTemplateList(new TableUpdateValue(this.currentSearchParams));
    }

    private initTable() {
        this.therapyTemplateListConfig.emptyListLabel = this.anyItemLabel ? this.anyItemLabel : 'ANY_ITEM';
        this.therapyTemplateListConfig.isOpenDetailEnable = true;
        this.therapyTemplateListConfig.itemSettings = [
            {
                id: 'title',
                prop: 'title',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '44%',
                sortBy: SortBy.TITLE,
                columnPosition: 0,
            },
            {
                id: 'description',
                prop: 'description',
                header: 'Beschreibung',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '56%',
                sortBy: SortBy.DESCRIPTION,
                columnPosition: 1,
            },
        ];
    }

    async updateTherapyTemplateList(value: TableUpdateValue) {
        this.currentSearchParams.patch(value);
        this.isLoadingProcessing = true;
        this.isLoadingSuccess = false;
        try {
            this.therapyTemplateListConfig.list = await this.therapyTemplatesService.getTherapyTemplates({
                offset: this.currentSearchParams.offset,
                limit: this.currentSearchParams.limit,
                filter: this.currentSearchParams.textSearchTerm,
                sortOrder: this.currentSearchParams.sortOrder,
                sortBy: this.currentSearchParams.sortBy,
                exerciseType: this.exerciseType,
            });
            this.therapyTemplateListConfig.emptyListLabel = this.anyItemLabel;
            this.isLoadingProcessing = false;
            this.isLoadingSuccess = true;
        } catch (e) {
            this.log.error('Error in refreshTherapyTemplateList', e);
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
        }
    }

    async openExercisePage(therapyTemplate?: TherapyTemplate) {
        const id = therapyTemplate ? therapyTemplate.id : 'new';
        if (this.exerciseType === ExerciseType.TASK) {
            await this.router.navigate([
                RoutingSegment.MEMBER,
                RoutingSegment.ADMINISTRATION,
                RoutingSegment.TASK,
                RoutingSegment.TASK_PLAN_TEMPLATES,
                RoutingSegment.DETAIL,
                id,
            ]);
        }
        if (this.exerciseType === ExerciseType.LEARNING) {
            await this.router.navigate([
                RoutingSegment.MEMBER,
                RoutingSegment.ADMINISTRATION,
                RoutingSegment.LEARNING,
                RoutingSegment.LEARNING_PLAN_TEMPLATES,
                RoutingSegment.DETAIL,
                id,
            ]);
        }
        if (this.exerciseType === ExerciseType.MIXED) {
            await this.router.navigate([
                RoutingSegment.MEMBER,
                RoutingSegment.ADMINISTRATION,
                RoutingSegment.PROGRAM_MANAGEMENT,
                RoutingSegment.PROGRAM_TEMPLATES,
                RoutingSegment.DETAIL,
                id,
            ]);
        }
    }
}
