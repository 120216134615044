import { Therapy } from '../therapy';
import { ExerciseSessionOfUser } from '../exerciseSession';
import { ActionMenuItem } from '../../../table/entities/action-menu.item';

export class TherapySession {
    uuid: string;
    therapyId: number;
    therapy: Therapy;
    username: string;
    earliestStart: string;
    latestEnd: string;
    state: TherapySessionState;
    exerciseSessionsOfUser: ExerciseSessionOfUser[] = [];
    created_at: string;
    changeEvents?: TherapySessionChangeEvent[];
}

export class TherapySessionChangeEvent {
    uuid: string;
    therapySessionId: string;
    oldState: string;
    newState: string;
    action: string;
    created_at: string;
}

export class FrontendTherapySession extends TherapySession {
    therapyName: string;
    therapyTemplateName: string;
    exerciseSessionsLength: string;
    finishedExerciseSessionsLength = 0;
    completedTherapies = 0;
    therapyGoal: string;
    flags: string[];
    startDateOfTherapy: Date;
    endDateOfTherapy: Date;
    isLocked = false;
    isToggleHidden: boolean;
    lockedLabel: string;
    patientName: string;
    color: string;
    isBold: boolean;
    actions: ActionMenuItem[];
    interval_date: string;
    stateAndFlag: string;
}

export enum TherapySessionState {
    UNINITIALIZED = 'UNINITIALIZED',
    RUNNING = 'RUNNING',
    COMPLETED = 'COMPLETED',
    INCOMPLETE = 'INCOMPLETE',
    LOCKED = 'LOCKED',
}
