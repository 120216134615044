import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    Chat,
    GroupChat,
    PatientRecordChat,
    SetPatientCanSendMessagesDto,
    UpdateChatIsTrackedDto,
} from '../entities/chat';
import { ChatMessage } from '../entities/chat-message';
import { ChatMessageDto } from '../entities/chat-message-dto';
import { ChatNotification } from '../entities/chat-notification';
import { ApiService } from '../../api';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { FileContentService } from '../../common/services/content/file-content.service';
import { PaginatedResponse } from '../../common/entities/paginated-response';
import { Capacitor } from '@capacitor/core';

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    constructor(
        private http: HttpClient,
        private fileContentService: FileContentService,
    ) {}

    async getChat(chatId: string): Promise<Chat> {
        const url = `${ApiService.url}chats/${chatId}`;
        return await this.http.get<Chat>(url).toPromise();
    }

    getChatNotifications$(chatId: string, timeoutMs = 2500): Observable<ChatNotification[]> {
        const url = `${ApiService.url}chats/${chatId}/notifications`;
        return this.http.get<ChatNotification[]>(url).pipe(timeout(timeoutMs));
    }

    async postChatMessage(chatId: string, content: string): Promise<ChatMessage> {
        const chatMessageDto = new ChatMessageDto();
        chatMessageDto.content = content;
        const url = `${ApiService.url}chats/${chatId}/messages`;
        return await this.http.post<ChatMessage>(url, chatMessageDto).toPromise();
    }

    async postAttachment(chatId: string, attachment: FormData): Promise<ChatMessage> {
        const url = `${ApiService.url}chats/${chatId}/attachments`;
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        headers.append('content-type', 'multipart/form-data');
        const options = { headers, withCredentials: true };
        return await this.http.post<ChatMessage>(url, attachment, options).toPromise();
    }

    async putReadConfirmation(chatId: string, messageId: string): Promise<void> {
        const url = `${ApiService.url}chats/${chatId}/messages/${messageId}/readConfirmation`;
        return await this.http.put<void>(url, null).toPromise();
    }

    async getPatientRecordChat(patientUsername: string, participate = false): Promise<PatientRecordChat> {
        let url = `${ApiService.url}users/${patientUsername}/patientRecordChat`;
        if (participate) url = `${url}?participate=${participate}`;
        return await this.http.get<PatientRecordChat>(url).toPromise();
    }

    async getGroupChat(groupPath: string): Promise<Chat> {
        const url = `${ApiService.url}groups/${encodeURIComponent(groupPath)}/-/groupChat`;
        return await this.http.get<Chat>(url).toPromise();
    }

    async getPatientRecordChats(
        username: string,
        limit?: number,
        offset?: number,
        isUnreadByNonPatient?: boolean,
        isTracked?: boolean,
        isClosed?: boolean,
        includeMessages = true,
    ): Promise<PaginatedResponse<PatientRecordChat[]>> {
        const url = new URL(`${ApiService.url}users/${username}/patientRecordChats`);

        if (offset) url.searchParams.set('offset', offset.toString());
        if (limit) url.searchParams.set('limit', limit.toString());
        if (typeof isUnreadByNonPatient !== 'undefined') {
            url.searchParams.set('isUnreadByNonPatient', isUnreadByNonPatient.toString());
        }
        if (isTracked) url.searchParams.set('isTracked', isTracked.toString());
        if (isClosed) url.searchParams.set('isClosed', isClosed.toString());
        if (includeMessages) url.searchParams.set('includeMessages', includeMessages.toString());
        return await this.http.get<PaginatedResponse<PatientRecordChat[]>>(url.toString()).toPromise();
    }

    async openAttachmentObjectURLinNewWindow(chatUuid: string, chatMessageUuid: string) {
        const url = new URL(`${ApiService.url}chats/${chatUuid}/attachments/${chatMessageUuid}/download`);
        if (Capacitor.isNativePlatform()) await this.fileContentService.openObjectURLinMobileDevice(url.toString());
        if (!Capacitor.isNativePlatform()) await this.fileContentService.openObjectURLinNewWindow(url.toString());
    }

    async updateChatTrackedStatus(chatUuid: string, updateChatIsTrackedDto: UpdateChatIsTrackedDto): Promise<Chat> {
        const url = new URL(`${ApiService.url}chats/${chatUuid}/isTracked`);
        return await this.http.put<GroupChat>(url.toString(), updateChatIsTrackedDto).toPromise();
    }

    async setPatientCanSendMessages(
        chatUuid: string,
        setPatientCanSendMessagesDto: SetPatientCanSendMessagesDto,
    ): Promise<SetPatientCanSendMessagesDto> {
        const url = new URL(`${ApiService.url}chats/${chatUuid}/setPatientCanSendMessages`);
        return await this.http
            .put<SetPatientCanSendMessagesDto>(url.toString(), setPatientCanSendMessagesDto)
            .toPromise();
    }
}
