import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ExerciseSessionUserState, ExerciseType } from '../../../therapy/entities/exerciseSession';
import { User, UserRoles } from '../../../auth/entities/user';
import { endOfWeek, isAfter, isBefore, startOfWeek, subWeeks } from 'date-fns';
import { TherapyWithTherapySessions } from '../../../therapy/entities/therapy';
import { UsersTherapiesService } from '../../../therapy/services/users-therapies';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { TherapyGoal } from '../../../therapy/entities/therapy-goal/therapy-goal';
import { CurafidaAuthService } from '../../../auth/services';
import { TaskResource } from '../../../therapy/components/task/task.resource';
import { Subscription } from 'rxjs';
import { TaskService } from '../../../therapy/services/task/task.service';

class TaskStatistic {
    template_id: number;
    name: string;
    finishedTasks: number;
    hasContent: boolean;
    hasExerciseToolSettings: boolean;

    constructor(template_id: number) {
        this.template_id = template_id;
    }
}

@Component({
    selector: 'lib-kiosk-week-resume-modal',
    templateUrl: './kiosk-week-resume-modal.component.html',
    styleUrls: ['./kiosk-week-resume-modal.component.scss'],
})
export class KioskWeekResumeModalComponent implements OnInit, OnDestroy {
    taskStatistics: TaskStatistic[] = [];
    calendarEventStatistics: TaskStatistic[] = [];
    trainings: TherapyWithTherapySessions[];
    goalOfTheWeek = 0;
    resultOfTheWeek = 0;
    TherapyGoal = TherapyGoal;
    isLoadingTrainingFinished = false;
    isLoadingTaskFinished = false;
    protected readonly log: Logger;
    private firstDayOfPreviousWeek: string;
    private lastDayOfPreviousWeek: string;
    private loggedInUser: User;
    private taskSubscription: Subscription;
    private trainingSubscription: Subscription;

    constructor(
        private modalCtrl: ModalController,
        private readonly authService: CurafidaAuthService,
        private usersTherapiesService: UsersTherapiesService,
        private loggingService: LoggingService,
        private taskService: TaskService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit(): Promise<void> {
        this.loggedInUser = this.authService.getSession().user;
        this.firstDayOfPreviousWeek = subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1).toISOString();
        this.lastDayOfPreviousWeek = subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1).toISOString();
        await Promise.all([this.initTrainings(), this.initTask()]);
    }

    ngOnDestroy() {
        this.taskSubscription?.unsubscribe();
        this.trainingSubscription?.unsubscribe();
    }

    async dismissModal(): Promise<void> {
        await this.modalCtrl.dismiss();
    }

    private setExerciseSessionsStatistics(taskResources: TaskResource[]): TaskStatistic[] {
        const statistics = [];
        taskResources
            /* Create a new array only with the ids of the task templates of all taskResources */
            .map((it) => {
                return it.taskTemplate.id;
            })
            /* Filter out duplicates, leaving only unique ids in an array */
            .filter((value, index, self) => {
                return self.indexOf(value) === index;
            })
            /* For each one of this unique id, set the statistics */
            .forEach((it) => {
                const item = new TaskStatistic(it);
                /* A template could be assigned as a task more than once, read it from the taskResources parameter */
                item.finishedTasks = taskResources.filter(
                    (i) =>
                        i.taskTemplate.id === item.template_id &&
                        i.exerciseSessionUserState === ExerciseSessionUserState.FINISHED,
                ).length;
                item.name = taskResources.find((i) => i.taskTemplate.id === item.template_id).title;
                item.hasContent =
                    taskResources.find((i) => i.taskTemplate.id === item.template_id).contents?.length > 0;
                item.hasExerciseToolSettings =
                    taskResources.find((i) => i.taskTemplate.id === item.template_id).exerciseToolSettings?.enabledTools
                        ?.length > 0;
                statistics.push(item);
            });
        return statistics;
    }

    /*
     * When tasks have an `appointment` AND a `calendarEvent` there might be issues when assigning them to one week
     * because each of them could be in a different week, leading to duplication in the statistics/UI.
     * This method mirrors the logic of the `KioskHomePage` to ensure the week resume reflects the kiosk items.
     * The main difference is that for the `KioskHomePage` this is done for each day of the week
     * whereas here it takes into consideration the week as a whole. See `KioskHomePage.setTasksForEachDays()`.
     */
    private isTaskOnRelevantWeek(task: TaskResource): boolean {
        /* The appointment (= Bearbeitungszeitraum) is the main driver of what week the task belongs to */
        if (task.appointment) {
            return (
                isBefore(new Date(task.appointment?.startTime), new Date(this.lastDayOfPreviousWeek)) &&
                isAfter(new Date(task.appointment?.startTime), new Date(this.firstDayOfPreviousWeek))
            );
        }
        /* If there is no appointment, then the calendarEvent (= Termin) becomes relevant to match the task to the week */
        if (task.appointment === null && !!task.calendarEvent) {
            return (
                isBefore(new Date(task.calendarEvent?.startTime), new Date(this.lastDayOfPreviousWeek)) &&
                isAfter(new Date(task.calendarEvent?.startTime), new Date(this.firstDayOfPreviousWeek))
            );
        }
        /*
         * If neither one is present there is an edge case where it should not be filtered out
         * to ensure finished tasks which reach this point don't go missing from the statistics
         */
        return task.appointment === null && task.calendarEvent === null;
    }

    private async initTask() {
        this.taskSubscription = this.taskService.statisticOfPatientTasks$.subscribe((patientTasks) => {
            try {
                this.isLoadingTaskFinished = false;
                if (patientTasks?.length > 0) {
                    /* Filter for tasks that are actually on the current week */
                    patientTasks = patientTasks.filter((i) => this.isTaskOnRelevantWeek(i));
                    /* Tasks where the patient is responsible */
                    const tasksPatientResponsible = patientTasks.filter(
                        (i) => i.responsibility.role === UserRoles.PATIENT,
                    );
                    /* Tasks where the patient is NOT responsible, but invited to/participant of a calendarEvent */
                    const calendarEvents = patientTasks
                        .filter((i) => i.calendarEvent !== null)
                        .filter((i) => i.responsibility.role !== UserRoles.PATIENT && i.isPatientParticipant);
                    /* Set the statistics for tasks and calendarEvents separately */
                    this.taskStatistics = this.setExerciseSessionsStatistics(tasksPatientResponsible);
                    this.calendarEventStatistics = this.setExerciseSessionsStatistics(calendarEvents);
                    /* Add the finished/completed tasks to the result of the week, state === FINISHED */
                    this.taskStatistics.forEach((stat) => {
                        this.resultOfTheWeek += stat.finishedTasks;
                    });
                    this.calendarEventStatistics.forEach((stat) => {
                        this.resultOfTheWeek += stat.finishedTasks;
                    });
                    /* Add all tasks and calendarEvents to the overall goal of the week, regardless of completion/state */
                    this.goalOfTheWeek += tasksPatientResponsible.length;
                    this.goalOfTheWeek += calendarEvents.length;
                }
            } catch (e) {
                this.log.error(this.constructor.name, e);
            } finally {
                this.isLoadingTaskFinished = true;
            }
        });
        this.taskService.initPatientTasks(
            {
                username: this.loggedInUser.username,
                limit: 200,
                startOfInterval: this.firstDayOfPreviousWeek,
                endOfInterval: this.lastDayOfPreviousWeek,
            },
            true,
        );
    }

    private async initTrainings(): Promise<void> {
        this.trainingSubscription = this.usersTherapiesService.userTherapiesWithTherapySessions$.subscribe(
            (therapiesWithTherapySessions) => {
                try {
                    this.isLoadingTrainingFinished = false;
                    this.trainings = therapiesWithTherapySessions.items;
                    if (this.trainings?.length > 0) {
                        this.trainings.forEach((training) => {
                            this.goalOfTheWeek += training.therapySessionsPerWeek;
                            this.resultOfTheWeek += training.completeTherapySession.items?.length;
                        });
                    }
                } catch (e) {
                    this.log.error(this.constructor.name, e);
                } finally {
                    this.isLoadingTrainingFinished = true;
                }
            },
        );
        this.usersTherapiesService.initUserTherapiesWithTherapySessions({
            username: this.loggedInUser.username,
            offset: 0,
            limit: 10,
            exerciseType: ExerciseType.TRAINING,
            startDate: this.firstDayOfPreviousWeek,
            endDate: this.lastDayOfPreviousWeek,
        });
    }
}
