import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExerciseType } from '../../../entities/exerciseSession';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { TherapiesService } from '../../../services/therapies';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { ModalAlertService } from '../../../../common/services/modal';
import { Therapy } from '../../../entities/therapy';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { ActionType, DateFormat, ItemType, TableConfig } from '../../../../table/entities/table';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { PaginatedResponse, SortBy } from '../../../../common/entities/paginated-response';
import { ModalTyp } from '../../../../common/entities/modal/modal-typ';
import { ModalConfig } from '../../../../common/entities/modal/modal-config';
import { ButtonConfig } from '../../../../common/entities/modal/modal-button';
import { LoadingProcess } from '../../../../table/components/curafida-table/loading.process';

@Component({
    selector: 'lib-program-plan-list',
    templateUrl: './program-plan-list.component.html',
    styleUrls: ['./program-plan-list.component.scss'],
})
export class ProgramPlanListComponent implements OnInit {
    isLoading = LoadingProcess.initLoadingProcess();
    @Input()
    programPlans: PaginatedResponse<Therapy[]>;
    @Input()
    program: Therapy;
    @Input()
    planType: ExerciseType;
    @Input()
    isNew: boolean;
    @Output()
    updateProgram: EventEmitter<boolean> = new EventEmitter<boolean>();
    title: string;
    buttonTitle: string;
    tableConfig = new TableConfig<Therapy[]>();
    protected readonly log: Logger;

    constructor(
        private therapiesService: TherapiesService,
        private toastService: ToastService,
        private modalAlertService: ModalAlertService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.initProgramTableConfig();
    }

    async ngOnInit() {
        this.initComponent();
    }

    initProgramTableConfig() {
        this.tableConfig.emptyListLabel = 'test';
        this.tableConfig.itemSettings = [
            {
                id: 'title',
                prop: 'title',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '28%',
                sortBy: SortBy.TITLE,
                columnPosition: 0,
            },
            {
                id: 'description',
                prop: 'description',
                header: 'Beschreibung',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '34%',
                columnPosition: 1,
            },
            {
                id: 'startDate',
                prop: 'startDate',
                header: 'Startdatum',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                format: DateFormat.DATE,
                width: '15%',
                columnPosition: 1,
            },
            {
                id: 'endDate',
                prop: 'endDate',
                header: 'Enddatum',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                format: DateFormat.DATE,
                width: '15%',
                columnPosition: 1,
            },
        ];
    }

    async buttonClickAction(event: { actionType: ActionType; item: Therapy }) {
        if (event.actionType === ActionType.DELETE) {
            await this.showRemovePrompt(event.item);
        }
    }

    async showRemovePrompt(programm: Therapy) {
        let typLabel = '';
        switch (this.planType) {
            case ExerciseType.TRAINING:
                typLabel = 'Trainingsplan';
                break;
            case ExerciseType.TASK:
                typLabel = 'Aufgabenplan';
                break;
            case ExerciseType.LEARNING:
                typLabel = 'Lernplan';
                break;
        }

        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.title = `${typLabel} entfernen`;
        modalConfig.titleIcon = 'warning-outline';
        modalConfig.description = `Durch das Entfernen des ${typLabel}s werden alle zugehörigen noch anstehenden Elemente entfernt. Sind Sie sicher, dass Sie fortfahren möchten?`;

        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'Entfernen';
        modalConfig.buttonRight.buttonColor = 'danger';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            try {
                await this.therapiesService.deleteTherapy(programm.id);
                await this.toastService.showToast(`${typLabel} erfolgreich entfernt.`, IonicColor.success);
                this.updateProgram.emit(true);
            } catch (e) {
                this.log.error('Failed to remove plan', e);
                await this.toastService.showToast(`Fehler beim Löschen des ${typLabel}.`, IonicColor.danger);
            }
        }
    }

    private initComponent() {
        switch (this.planType) {
            case ExerciseType.TRAINING:
                this.title = 'Trainingspläne';
                this.buttonTitle = 'Trainingsplan hinzufügen';
                this.tableConfig.emptyListLabel = 'ANY_TRAINING';
                break;
            case ExerciseType.TASK:
                this.title = 'Aufgabenpläne';
                this.buttonTitle = 'Aufgabenplan hinzufügen';
                this.tableConfig.emptyListLabel = 'ANY_TASK';
                break;
            case ExerciseType.LEARNING:
                this.title = 'Lernpläne';
                this.buttonTitle = 'Lernplan hinzufügen';
                this.tableConfig.emptyListLabel = 'ANY_LEARNING';
                break;
        }

        this.tableConfig.list = this.programPlans;
        this.isLoading = LoadingProcess.finishedSuccessfullyLoadingProcess();
    }
}
