import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { Exercise } from '../../../entities/exercise';
import { Content, ContentDto, ContentMetaDataType, ExerciseContentMetaData } from '../../../entities/content';
import { ExerciseContentTrainingDefinitionComponent } from '../exercise-content-training-definition/exercise-content-training-definition.component';
import { ActivatedRoute } from '@angular/router';
import {
    ActionEmitter,
    ActionType,
    ButtonItemAdapterComponent,
    ItemType,
    TableConfig,
} from '../../../../table/entities/table';
import { ModalController } from '@ionic/angular';
import { ExerciseContentsService } from '../../../services/exercise-contents';
import { ModalConfig } from '../../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../../common/entities/modal/modal-typ';
import { ButtonConfig } from '../../../../common/entities/modal/modal-button';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { PaginatedResponse } from '../../../../common/entities/paginated-response';
import { ModalAlertService } from '../../../../common/services/modal';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ExercisesService } from '../../../services/exercises';
import { PhysicalExerciseGoalDto } from '../../../entities/exerciseGoal/exerciseGoalDto';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { MediaContentAdapterComponent } from '../../../../table/components/table-adapter/media-content-adapter.component';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { UPLOAD_MIME_TYPE_WHITELIST } from '../../../../common/entities/white-list-mime-type';
import { UserRoles } from '../../../../auth/entities/user';
import { StyleService } from '../../../../common/services/style/style.service';
import { ContentFormatType } from '../../../../common/entities/content-format-type';
import { ICardActionButton } from '../../../../common/entities/card-action-button.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'content-training',
    templateUrl: './content-training.component.html',
    styleUrls: ['./content-training.component.scss'],
})
export class ContentTrainingComponent implements OnInit {
    @Input()
    exercise: Exercise;
    @Input()
    hasRightToModified = false;
    UserRoles = UserRoles;
    uploadFile: EventEmitter<FileItem> = new EventEmitter<FileItem>();
    public uploader: FileUploader = new FileUploader({
        url: '',
        allowedMimeType: UPLOAD_MIME_TYPE_WHITELIST,
        // queueLimit: 1,
        // maxFileSize: 20 * 1024 * 1024
    });
    @ViewChild('fileUploader', { static: true }) fileUploader: ElementRef;

    exerciseContentsListConfig: TableConfig<Content[]>;
    isEditContentEnabled = false;
    whiteListMimeType = UPLOAD_MIME_TYPE_WHITELIST.join();
    isMobile = false;
    actionButtons: ICardActionButton[] = [];
    protected readonly log: Logger;
    private physicalExerciseGoal: PhysicalExerciseGoalDto;

    constructor(
        public route: ActivatedRoute,
        private toastService: ToastService,
        private exercisesService: ExercisesService,
        private modalAlertService: ModalAlertService,
        private exerciseContentService: ExerciseContentsService,
        private modalController: ModalController,
        private loggingService: LoggingService,
        private styleService: StyleService,
        private translateService: TranslateService,
    ) {
        this.isMobile = this.styleService.isMobile$;
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.actionButtons.push({
            icon: 'add',
            id: 'add-content',
            isDisabled: false,
            isHidden: false,
            isIconButton: this.isMobile,
            title: 'ADD_CONTENT',
        });
    }

    async initExercise() {
        this.exercise.contents = this.exercise.contents.filter(
            (i) => i?.jsonData !== null && i?.jsonData?.contentMetaDataType !== ContentMetaDataType.THUMBNAIL,
        );

        for (const content of this.exercise.contents as ContentCatalogue[]) {
            content.order = content.jsonData.order;
            content.contentMetaDataType = content.jsonData.contentMetaDataType;
            content.name = content.jsonData.name;
        }
    }

    async getExercise() {
        if (this.route.snapshot.paramMap.get('exerciseId') === 'new') {
            this.exercise = new Exercise();
            this.physicalExerciseGoal = new PhysicalExerciseGoalDto();
            this.exercise.contents = [];
        } else {
            this.exercise = await this.exercisesService.getExerciseById(
                Number(this.route.snapshot.paramMap.get('exerciseId')),
            );
        }
    }

    async ngOnInit() {
        await this.initExerciseContentTable();
        this.exerciseContentsListConfig.list = new PaginatedResponse<Content[]>();
        await this.getExercise();
        await this.initExercise();
        await this.setContentTable();
    }

    async setContentTable() {
        for (const content of this.exercise.contents as ContentCatalogue[]) {
            if (Content.isAudioMimeType(content.mimeType) || Content.isVideoMimeType(content.mimeType)) {
                content.url = await this.exerciseContentService.getTempDownloadUrl(content.uuid);
            } else {
                content.url = await this.exerciseContentService.getObjectURLContentFromUrl(
                    content.uuid,
                    ContentFormatType.THUMBNAIL,
                );
            }
            content.isDisabled = this.isEditContentEnabled;
        }

        this.organizeContentCatalogue(this.exercise.contents as ContentCatalogue[]);

        this.exerciseContentsListConfig.list.items = this.exercise.contents.filter((i) => !!i.jsonData);
        if (this.exerciseContentsListConfig.list.total !== this.exercise.contents.length) {
            this.exerciseContentsListConfig.list.total = this.exercise.contents.length;
        }
        if (this.exerciseContentsListConfig.list.count !== this.exercise.contents.length) {
            this.exerciseContentsListConfig.list.count = this.exercise.contents.length;
        }
        if (this.exerciseContentsListConfig.list.limit !== this.exercise.contents.length) {
            this.exerciseContentsListConfig.list.limit = this.exercise.contents.length;
        }
        this.exerciseContentsListConfig.list.offset = 0;
    }

    organizeContentCatalogue(table: ContentCatalogue[]) {
        table.sort((a, b) => {
            if (a.order < b.order) {
                return -1;
            } else if (a.order > b.order) {
                return 1;
            }
            return 0;
        });
        return table;
    }

    async initExerciseContentTable() {
        this.exerciseContentsListConfig = new TableConfig<Content[]>();
        this.exerciseContentsListConfig.isReorderDisable = true;
        this.exerciseContentsListConfig.isOpenDetailEnable = true;
        this.exerciseContentsListConfig.emptyListLabel = 'TRAINING.CONTENT.ANY_ITEM';
        this.exerciseContentsListConfig.itemSettings = [
            { id: '', prop: '', header: '', type: ItemType.NUMBERING, width: '10%', columnPosition: 0 },
            {
                id: 'picture',
                prop: '',
                header: 'Medium',
                type: ItemType.ADAPTER,
                adapter: MediaContentAdapterComponent,
                actionType: ActionType.OPEN_NEW_PAGE,
                width: '20%',
                disabled: true,
                columnPosition: 1,
            },
            {
                id: 'position',
                prop: 'name',
                header: 'Merkmal',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '20%',
                actionType: ActionType.OPEN_NEW_PAGE,
                columnPosition: 2,
                sortOrderMobile: 1,
                isMobileBold: true,
            },
            {
                id: 'description',
                prop: 'description',
                header: 'Beschreibung',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '34%',
                actionType: ActionType.OPEN_NEW_PAGE,
                columnPosition: 3,
                sortOrderMobile: 2,
            },
        ];

        if (this.hasRightToModified) {
            this.exerciseContentsListConfig.itemSettings.push({
                id: '',
                prop: 'id',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                width: '8%',
                columnPosition: 4,
                icon: 'trash',
                color: 'danger',
                actionType: ActionType.REMOVE,
            });
        } else {
            this.exerciseContentsListConfig.itemSettings.find((i) => i.id === 'description').width = '42%';
        }
        this.exerciseContentsListConfig.itemSettings.push({
            id: '',
            prop: 'id',
            header: '',
            type: ItemType.ADAPTER,
            adapter: ButtonItemAdapterComponent,
            width: '8%',
            columnPosition: 4,
            icon: 'open',
            color: 'primary',
            actionType: ActionType.PREVIEW,
        });
    }

    toggleTable() {
        if (this.isEditContentEnabled) {
            this.exerciseContentsListConfig.itemSettings[0].type = ItemType.NUMBERING;
        } else {
            this.exerciseContentsListConfig.itemSettings[0].type = ItemType.REORDER;
        }
        this.isEditContentEnabled = !this.isEditContentEnabled;
        this.exerciseContentsListConfig.isReorderDisable = !this.exerciseContentsListConfig.isReorderDisable;
    }

    async addNewContent() {
        this.isEditContentEnabled = false;
        const modal = await this.modalController.create({
            component: ExerciseContentTrainingDefinitionComponent,
            cssClass: 'modal-create-exercise-css',
            componentProps: {
                exercise: this.exercise,
                highestOrder: this.getHighestOrderOfContents(),
                isNewContent: true,
            },
        });
        await modal.present();
        await modal.onDidDismiss();
        await this.initExerciseContentTable();
        await this.getExercise();
        await this.initExercise();
        await this.setContentTable();
    }

    async setActionOnItem(actionEmitter: ActionEmitter<Content>) {
        if (actionEmitter.actionType === ActionType.PREVIEW) {
            await this.openContentDetail(actionEmitter.item as ContentCatalogue);
        } else if (actionEmitter.actionType === ActionType.REMOVE) {
            const modalConfig = new ModalConfig();
            modalConfig.modalTyp = ModalTyp.INFORMATION;
            modalConfig.title = this.translateService.instant('DELETE_EXERCISE_DESCRIPTION', {
                filename: actionEmitter.item.origFileName,
            });
            modalConfig.titleIcon = 'warning-outline';
            modalConfig.description = this.translateService.instant('DELETE_EXERCISE_DESCRIPTION_PROMPT', {
                filename: actionEmitter.item.origFileName,
            });
            modalConfig.buttonRight = new ButtonConfig();
            modalConfig.buttonRight.buttonText = this.translateService.instant('DELETE');
            modalConfig.buttonRight.buttonColor = 'danger';
            const action = await this.modalAlertService.showModal(modalConfig);
            if (action && action.action === 'right') {
                try {
                    await this.exerciseContentService.deleteExerciseContentUuid(actionEmitter.item.uuid);
                    await this.getExercise();
                    await this.initExercise();
                    await this.setContentTable();
                    await this.toastService.showToast('Die Unterlagen wurden entfernt', IonicColor.success);
                } catch (err) {
                    this.log.error(err);
                    await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
                }
            }
        }
        if (actionEmitter.actionType === ActionType.UPDATE && this.isEditContentEnabled) {
            this.uploader.clearQueue();
            this.fileUploader.nativeElement.click();
            this.uploader.onAfterAddingAll = async (files) => {
                for (const file of files) {
                    try {
                        const assetInput = new FormData();
                        assetInput.append('file', file.file.rawFile);
                        let newContent = await this.exerciseContentService.postContentFileUpload(assetInput);
                        const contentDto = new ContentDto();
                        contentDto.description = actionEmitter.item.description;
                        contentDto.mimeType = newContent.mimeType;
                        contentDto.byteSize = newContent.byteSize;
                        contentDto.jsonData = actionEmitter.item.jsonData;
                        newContent = await this.exerciseContentService.updateExerciseContent(
                            newContent.uuid,
                            contentDto,
                        );
                        await this.exercisesService.assignContentToExercise(this.exercise.id, newContent.uuid);
                        const indexTable = this.exerciseContentsListConfig.list.items.findIndex(
                            (content) => content.uuid === actionEmitter.item.uuid,
                        );
                        const indexList = this.exercise.contents.findIndex(
                            (content) => content.uuid === actionEmitter.item.uuid,
                        );
                        await this.exerciseContentService.deleteExerciseContentUuid(actionEmitter.item.uuid);
                        this.exerciseContentsListConfig.list.items[indexTable].url =
                            await this.exerciseContentService.getObjectURLContentFromUrl(newContent.uuid);
                        this.exercise.contents[indexList].uuid = newContent.uuid;
                    } catch (e) {
                        this.log.error('Error in setActionOnItem', e);
                        await this.toastService.showToast(
                            `Beim Hochladen der Datei ${file.file.name} ist ein Fehler aufgetreten.`,
                            IonicColor.danger,
                        );
                    }
                }
                this.fileUploader.nativeElement.value = '';
                this.uploader.clearQueue();
            };
            this.uploader.onWhenAddingFileFailed = async (item, filter) => {
                switch (filter.name) {
                    // The size filter first has to be enabled in the FileUploader options
                    case 'fileSize':
                        await this.toastService.showToast(
                            `Die Datei ${item.name} ist zu groß. Maximale Dateigröße 20 MB.`,
                            IonicColor.danger,
                        );
                        break;
                    case 'mimeType':
                        await this.toastService.showToast(
                            `Das Format der Datei ${item.name} wird nicht unterstützt.`,
                            IonicColor.danger,
                        );
                        break;
                    // The queue limit first has to be enabled in the FileUploader options
                    case 'queueLimit':
                        await this.toastService.showToast(
                            `Datei ${item.name} wurde nicht hochgeladen, es kann nur eine Datei gleichzeitig hochgeladen werden`,
                            IonicColor.danger,
                        );
                        break;
                    default:
                        this.log.error('Error in setActionOnItem', `Unknown error (filter is ${filter.name})`);
                        await this.toastService.showToast(
                            `Beim Hochladen der Datei ${item.name} ist ein Fehler aufgetreten.`,
                            IonicColor.danger,
                        );
                        break;
                }
            };
        }
    }

    updateTableItem(event: PaginatedResponse<Content[]>) {
        this.exerciseContentsListConfig.list = event;
    }

    async cancelContentList() {
        this.toggleTable();
        await this.getExercise();
        await this.initExercise();
        await this.setContentTable();
    }

    async saveContent(content: ContentCatalogue) {
        const exerciseContentMetaData = new ExerciseContentMetaData(
            content.contentMetaDataType,
            content.order,
            content.name,
        );
        const contentDto = new ContentDto();
        contentDto.description = content.description;
        contentDto.mimeType = content.mimeType;
        contentDto.byteSize = content.byteSize;
        contentDto.jsonData = exerciseContentMetaData;
        await this.exerciseContentService.updateExerciseContent(content.uuid, contentDto);
    }

    async saveContentTable() {
        for (const content of this.exercise.contents as ContentCatalogue[]) {
            const toastText = [];
            if (content.contentMetaDataType === null) toastText.push('Element');
            if (content.name === '') toastText.push('Merkmal');
            if (content.description === '') toastText.push('Beschreibung');

            if (toastText.length !== 0) {
                let message = '';
                if (toastText.length === 1) message = toastText[0];
                if (toastText.length === 2) message = `${toastText[0]} und ${toastText[1]}`;
                if (toastText.length === 3) message = `${toastText[0]}, ${toastText[1]} und ${toastText[2]}`;
                await this.toastService.showToast(`${message} fehlt.`, IonicColor.danger);
                return;
            }
            await this.saveContent(content as ContentCatalogue);
        }
        if (this.isEditContentEnabled) this.toggleTable();
    }

    async openContentDetail(content: ContentCatalogue) {
        if (!content.isDisabled) {
            let css: string;
            if (this.isMobile) {
                css = 'my-custom-modal-css';
            } else {
                css = 'modal-media-exercise-css';
            }
            if (Content.isAudioMimeType(content.mimeType) || Content.isVideoMimeType(content.mimeType)) {
                content.url = await this.exerciseContentService.getTempDownloadUrl(content.uuid);
            } else {
                content.url = await this.exerciseContentService.getObjectURLContentFromUrl(
                    content.uuid,
                    ContentFormatType.MEDIUM_SIZE,
                );
            }
            const modal = await this.modalController.create({
                component: ExerciseContentTrainingDefinitionComponent,
                cssClass: css,
                componentProps: {
                    exercise: this.exercise,
                    content,
                    highestOrder: this.getHighestOrderOfContents(),
                    isNewContent: false,
                },
            });
            await modal.present();
            const result = await modal.onDidDismiss();
            if (result.data) {
                await this.getExercise();
                await this.initExercise();
                await this.setContentTable();
                if (this.isEditContentEnabled) this.toggleTable();
            }
        }
    }

    getHighestOrderOfContents() {
        let highestOrder = 0;
        for (const content of this.exercise.contents) {
            highestOrder = content.jsonData.order >= highestOrder ? content.jsonData.order : highestOrder;
        }
        return highestOrder;
    }
}

export class ContentCatalogue extends Content {
    order: number;
    contentMetaDataType: ContentMetaDataType | string;
    name?: string;
    isDisabled: boolean;
}
