import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { KioskActionElementModalComponent } from '../components/kiosk-action-element-modal/kiosk-action-element-modal.component';
import { ButtonsModalDefinition, KioskActionButton } from '../entities/buttons-modal-definition';

@Injectable({
    providedIn: 'root',
})
export class KioskModalService {
    constructor(private modalCtrl: ModalController) {}

    async definePauseTrainingModal(): Promise<HTMLIonModalElement> {
        const buttonsModalDefinition = this.getPauseTrainingModalDefinition();
        return await this.modalCtrl.create({
            component: KioskActionElementModalComponent,
            cssClass: 'kiosk-action-text-modal',
            backdropDismiss: false,
            componentProps: { buttonsModalDefinition: buttonsModalDefinition, pauseTraining: true },
        });
    }

    private getPauseTrainingModalDefinition(): ButtonsModalDefinition {
        const buttonsModalDefinition = new ButtonsModalDefinition();
        buttonsModalDefinition.title = 'KIOSK.TRAINING.PAUSE_TRAINING_ACTION_TITLE';
        buttonsModalDefinition.actionElements = [
            {
                title: 'KIOSK.TRAINING.CONTINUE_TRAINING',
                description: 'KIOSK.TRAINING.CONTINUE_TRAINING_DESCRIPTION',
                icon: 'checkmark-circle',
                iconColor: 'success',
                action: 'back',
            } as KioskActionButton,
            {
                title: 'KIOSK.TRAINING.PAUSE_TRAINING',
                description: 'KIOSK.TRAINING.LEAVE_TRAINING_DESCRIPTION',
                icon: 'close-circle',
                iconColor: 'danger',
                action: 'pauseTraining',
            } as KioskActionButton,
        ];
        return buttonsModalDefinition;
    }
}
