import { NgModule } from '@angular/core';
import { NoPreloading, PreloadingStrategy, RouterModule, Routes } from '@angular/router';
import { RoutingSegment } from '../../../../libs/common/entities/routing-segment';
import { SplashGuardService } from '../../../../libs/guard/splash-guard.service';
import { routeTemplates } from '../../../../libs/config/entities/routing-template';

const routes: Routes = routeTemplates;
routes.push(
    {
        path: RoutingSegment.DEFAULT,
        canActivate: [SplashGuardService],
        loadChildren: () => import('./pages/default/default.module').then((m) => m.DefaultPageModule),
    },
    {
        path: '**',
        canActivate: [SplashGuardService],
        loadChildren: () => import('./pages/default/default.module').then((m) => m.DefaultPageModule),
    },
);

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading, useHash: false, anchorScrolling: 'enabled' }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
