import { TherapyTemplateDto } from './therapy-template-dto';
import { Exercise } from '../exercise';
import { TherapyTemplateExerciseResource } from './therapy-template-exercise';
import { Content } from '../content';
import { ActionMenuItem } from '../../../table/entities/action-menu.item';

export class TherapyTemplate extends TherapyTemplateDto {
    id: number;
    created_at: number;
    updated_at: number;
    isCollapsed = true;
    exercises: Exercise[] = [];
    fullTherapy: boolean;
    existingForTheCurrentPatient = false;
    selected: boolean;
    category: string;
    therapySessionsPerWeek: number;
    therapyTemplateExercises: TherapyTemplateExerciseResource[] = [];
    childTherapyTemplates?: TherapyTemplate[];
    contents: Content[];
    disabled: boolean;

    //frontend variable
    actions?: ActionMenuItem[];
}
