import { ExerciseType } from '../exerciseSession';

export class BaseTag {
    uuid?: string;
    label: string;
    exerciseType?: ExerciseType;
    isReadonly: boolean;
    isHidden: boolean;
    isSelected: boolean;
}
