import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CurafidaSegmentItem } from '../../../../common/entities/curafida-segment.item';
import { SegmentType } from '../../../../common/entities/segment.type';
import { ActionButton, ActionItemType } from '../../../../table/entities/action-menu.item';
import {
    ActionEmitter,
    ActionType,
    ButtonItemAdapterComponent,
    DateFormat,
    ItemType,
    TableConfig,
    TableUpdateValue,
} from '../../../../table/entities/table';
import { PaginatedResponse, SortOrder } from '../../../../common/entities/paginated-response';
import { ExerciseSessionState, ExerciseSessionUserState, ExerciseSubType } from '../../../entities/exerciseSession';
import { RoutingSegment } from '../../../../common/entities/routing-segment';
import { Router } from '@angular/router';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { User, UserRoles } from '../../../../auth/entities/user';
import { CurafidaAuthService } from '../../../../auth/services';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { RoutingService } from '../../../../config/services/routing.service';
import { CurafidaSegmentComponent } from '../../../../common/components/curafida-segment/curafida-segment.component';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { StyleService } from '../../../../common/services/style/style.service';
import { ModalController } from '@ionic/angular';
import { endOfDay, parseISO, startOfDay, subSeconds } from 'date-fns';
import { Tag } from '../../../entities/tag/tag.entity';
import { CaregiverTaskPreviewComponent } from '../caregiver-task-preview/caregiver-task-preview.component';
import { UserExerciseSessionsService } from '../../../services/user-exercise-sessions';
import { UsersService } from '../../../../user/services/user';
import { UsersPreferencesService } from '../../../../user/services/user-preferences';
import { FilterSettingItem } from '../../../../user/entities/user-preferences/user-preferences';
import { TagsService } from '../../../services/tags';
import { SortTaskPropertyMap, TaskPreviewResource } from '../task.resource';
import { ResponsibleNamePipe } from './responsible-name.pipe';
import { IconAdapterComponent } from '../../../../table/components/table-adapter/icon-adapter.component';
import { TaskFilterModalComponent } from '../../modal/task-filter-modal/task-filter-modal.component';
import { map, take } from 'rxjs/operators';
import { TaskService } from '../../../services/task/task.service';
import { MultiItemAdapterComponent } from '../../../../table/components/table-adapter/multi-item-adapter.component';
import { PercentItemAdapterComponent } from '../../../../table/components/table-adapter/percent-item-adapter.component';
import { CurafidaPaginationOptions } from '../../../../table/entities/curafidaPaginationOptions';

export interface IconFilterItem {
    name: string;
    color: string;
}

export class TaskOverviewFilterSetting extends FilterSettingItem {
    tags: Tag[];
    icons: IconFilterItem[];

    constructor(name: string) {
        super(name);
        this.tags = [];
        this.icons = [];
    }
}

@Component({
    selector: 'lib-task-overview',
    templateUrl: './task-overview.component.html',
    styleUrls: ['./task-overview.component.scss'],
})
export class TaskOverviewComponent implements OnInit {
    readonly filterCount$ = this.usersPreferencesService
        .settingByName<TaskOverviewFilterSetting>('TaskOverviewComponent')
        .pipe(map((it) => (it?.tags?.length ?? 0) + (it?.icons?.length ?? 0)));
    @ViewChild('taskSegment') taskSegment: CurafidaSegmentComponent<SegmentType>;
    @ViewChild('taskResponsibleSegment') taskResponsibleSegment: CurafidaSegmentComponent<SegmentType>;

    isLoading = true;
    isLoadingSuccess = false;
    @Output()
    activeTaskCount$ = new EventEmitter<number>();
    offset = 0;
    limit = 10;
    searchTermTask: string;
    taskTableConfig: TableConfig<TaskPreviewResource[]> = new TableConfig<TaskPreviewResource[]>();
    segmentListTask: CurafidaSegmentItem<SegmentType>[] = [];
    segmentListResponsible: CurafidaSegmentItem<SegmentType>[] = [];
    displayedTaskType = SegmentType.ACTIVE;
    displayedResponsibleType = SegmentType.MY;
    loggedInUser: User;
    loggedInUserFullName: string;
    isMobile: boolean;
    protected readonly log: Logger;
    private taskStates: ExerciseSessionState[];
    private taskUserStates: ExerciseSessionUserState[];
    private sortOrder: SortOrder;
    private sortBy: SortTaskPropertyMap[];
    private paginationOptions: CurafidaPaginationOptions;

    constructor(
        private router: Router,
        private toastService: ToastService,
        private authService: CurafidaAuthService,
        private loggingService: LoggingService,
        private modalCtrl: ModalController,
        private styleService: StyleService,
        private routingService: RoutingService,
        private userExerciseSessionsService: UserExerciseSessionsService,
        private taskService: TaskService,
        private usersService: UsersService,
        private usersPreferencesService: UsersPreferencesService,
        private tagsService: TagsService,
        private readonly responsibleNamePipe: ResponsibleNamePipe,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.isMobile = this.styleService.isMobile$;
    }

    static mapState(state: ExerciseSessionUserState): string {
        switch (state) {
            case ExerciseSessionUserState.NOT_PLANNED:
            case ExerciseSessionUserState.PLANNED:
            case ExerciseSessionUserState.ACTIVE:
                return 'OPEN';
            case ExerciseSessionUserState.PATIENT_CANCELLED:
            case ExerciseSessionUserState.CANCELLED:
                return 'DECLINED';
            case ExerciseSessionUserState.NO_SHOW:
                return 'EXPIRED';
            default:
                return state;
        }
    }

    async ngOnInit() {
        this.loggedInUser = this.authService.getSession().user;
        this.loggedInUserFullName = `${this.loggedInUser.lastname}, ${this.loggedInUser.firstname}`;
        this.initTaskTable();
        this.initTabs();
        await this.getPatientTaskList({ fetchAllTasks: true }); // It is used to get the total number of tasks
        this.fetchFilteredTaskList({});
    }

    fetchFilteredTaskList(value: TableUpdateValue) {
        this.usersPreferencesService
            .settingByName<TaskOverviewFilterSetting>('TaskOverviewComponent')
            .pipe(take(1))
            .subscribe((it) => {
                if (isNaN(value.limit)) {
                    value.limit = this.limit;
                }
                if (isNaN(value.offset)) {
                    value.offset = this.offset;
                }
                this.getPatientTaskList({
                    limit: value.limit,
                    offset: value.offset,
                    sortBy: (value?.sortBy as SortTaskPropertyMap) || this.sortBy?.[0],
                    sortOrder: value?.sortOrder || this.sortOrder,
                    tableColumnId: value?.tableColumnId,
                    taskOverviewFilterSetting: it,
                });
            });
    }

    initTaskTable() {
        this.taskTableConfig.emptyListLabel = 'TASK.ANY';
        this.taskTableConfig.itemSettings = [
            {
                id: 'dueDate',
                prop: 'dueDate',
                header: 'DUE_DATE',
                type: ItemType.ADAPTER,
                format: DateFormat.DATE_AND_HOUR_2_LINES,
                adapter: StringItemAdapterComponent,
                width: '12%',
                columnPosition: 1,
                sortBy: SortTaskPropertyMap.DELAYED_TIME,
            },
            {
                id: 'appointment',
                prop: 'calendarEventStartDate',
                header: 'APPOINTMENT',
                type: ItemType.ADAPTER,
                format: DateFormat.DATE_AND_HOUR_2_LINES,
                adapter: StringItemAdapterComponent,
                width: '12%',
                columnPosition: 2,
                sortBy: SortTaskPropertyMap.CALENDAR_EVENT_START_TIME,
            },
            {
                id: 'patientName',
                prop: 'patientName',
                header: 'PATIENT.SINGULAR',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '18%',
                columnPosition: 3,
            },
            {
                id: 'responsibleName',
                prop: 'responsibleName',
                header: 'RESPONSIBLE_FOR',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '10%',
                columnPosition: 4,
                sortBy: SortTaskPropertyMap.RESPONSIBLE,
            },
            {
                id: 'type',
                prop: 'exerciseSubType',
                header: 'TYPE',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '11%',
                columnPosition: 5,
                sortBy: SortTaskPropertyMap.EXERCISE_SUB_TYPE,
            },
            {
                id: 'title',
                prop: 'title',
                header: 'TASK.SINGULAR',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '26%',
                columnPosition: 6,
                sortBy: SortTaskPropertyMap.TITLE,
            },
            {
                id: 'multiItem',
                prop: '',
                header: '',
                type: ItemType.ADAPTER,
                adapter: MultiItemAdapterComponent,
                width: '6%',
                columnPosition: 7,
                multiItemSettings: [
                    {
                        condition: (data) => data.exerciseSubType === ExerciseSubType.LEARNING,
                        itemSetting: {
                            id: 'learningProgress',
                            prop: 'learningProgress',
                            header: '',
                            type: ItemType.ADAPTER,
                            adapter: PercentItemAdapterComponent,
                        },
                    },
                    {
                        condition: () => true,
                        itemSetting: {
                            id: 'icon',
                            prop: 'icons',
                            header: '',
                            type: ItemType.ADAPTER,
                            adapter: IconAdapterComponent,
                        },
                    },
                ],
            },
            {
                prop: '',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                actionType: ActionType.POPOVER,
                fill: 'clear',
                icon: 'ellipsis-vertical',
                id: 'action_open',
                width: '8%',
                columnPosition: 8,
            },
        ];
        this.taskTableConfig.isOpenDetailEnable = true;
    }

    initTabs() {
        this.segmentListTask = [
            new CurafidaSegmentItem({ name: 'PLANNED_ADJECTIVE', value: SegmentType.PLANNED }),
            new CurafidaSegmentItem({ name: 'SEGMENT.CALENDAR_EVENT', value: SegmentType.CALENDAR_EVENT }),
            new CurafidaSegmentItem({ name: 'SEGMENT.ACTIVE', value: SegmentType.ACTIVE }),
            new CurafidaSegmentItem({ name: 'CLOSED', value: SegmentType.INACTIVE }),
            new CurafidaSegmentItem({ name: 'CANCELLED', value: SegmentType.CANCELLED }),
            new CurafidaSegmentItem({ name: 'EXPIRED', value: SegmentType.EXPIRED }),
        ];
        this.segmentListResponsible = [];
        if (!this.loggedInUser.roles.includes(UserRoles.ANALYST)) {
            this.segmentListResponsible.push(new CurafidaSegmentItem({ name: 'MY', value: SegmentType.MY }));
            this.segmentListResponsible.push(new CurafidaSegmentItem({ name: 'ALL', value: SegmentType.ALL }));
        }
        this.paginationOptions = new CurafidaPaginationOptions(this.taskTableConfig.itemSettings);
        for (const segmentResponsible of this.segmentListResponsible) {
            for (const segment of this.segmentListTask) {
                const segmentSettings = this.taskService.setStateConfiguration(segment.value);
                this.paginationOptions.set(
                    { responsible: segmentResponsible.value, segment: segment.value },
                    {
                        sortBy: segmentSettings.sortBy,
                        sortOrder: segmentSettings.sortOrder,
                        offset: 0,
                        tableColumnId: segmentSettings.tableColumnId,
                    },
                );
            }
        }
        const segmentSettings = this.taskService.setStateConfiguration(this.displayedTaskType);
        this.sortBy = segmentSettings.sortBy;
        this.sortOrder = segmentSettings.sortOrder;
    }

    async setTaskSegmentType(event: SegmentType) {
        this.paginationOptions.set(
            { responsible: this.displayedResponsibleType, segment: this.displayedTaskType },
            {
                offset: this.offset,
                sortBy: this.sortBy,
                sortOrder: this.sortOrder,
            },
        );
        this.displayedTaskType = event;
        await this.routingService.changingQueryParams(
            event.toLowerCase(),
            'task',
            this.displayedTaskType,
            this.taskSegment,
        );
        const recievedPaginationOptions = this.paginationOptions.get({
            responsible: this.displayedResponsibleType,
            segment: event,
        });
        this.sortBy = recievedPaginationOptions.sortBy as SortTaskPropertyMap[];
        this.sortOrder = recievedPaginationOptions.sortOrder;
        this.offset = recievedPaginationOptions.offset;
        this.limit = 10;
        this.fetchFilteredTaskList({});
    }

    async setTaskResponsibleSegmentType(event: SegmentType) {
        this.paginationOptions.set(
            { responsible: this.displayedResponsibleType, segment: this.displayedTaskType },
            {
                offset: this.offset,
                sortBy: this.sortBy,
                sortOrder: this.sortOrder,
            },
        );
        this.displayedResponsibleType = event;
        await this.routingService.changingQueryParams(
            event.toLowerCase(),
            'taskResponsible',
            this.displayedResponsibleType,
            this.taskResponsibleSegment,
        );
        const recievedPaginationOptions = this.paginationOptions.get({
            responsible: event,
            segment: this.displayedTaskType,
        });
        this.sortBy = recievedPaginationOptions.sortBy as SortTaskPropertyMap[];
        this.sortOrder = recievedPaginationOptions.sortOrder;
        this.offset = recievedPaginationOptions.offset;
        this.limit = 10;
        this.fetchFilteredTaskList({});
    }

    private async getPatientTaskList(args: {
        limit?: number;
        offset?: number;
        sortBy?: SortTaskPropertyMap;
        sortOrder?: SortOrder;
        taskOverviewFilterSetting?: TaskOverviewFilterSetting;
        tableColumnId?: string;
        fetchAllTasks?: boolean;
    }) {
        this.isLoading = true;
        this.isLoadingSuccess = false;
        if (args.limit) {
            this.limit = args.limit;
        }
        if (args.offset || args.offset === 0) {
            this.offset = args.offset;
        }
        const stateConfiguration = this.taskService.setStateConfiguration(this.displayedTaskType);
        if (args.tableColumnId) {
            this.sortBy =
                args.tableColumnId === stateConfiguration.tableColumnId ? stateConfiguration.sortBy : [args.sortBy];
        }
        this.sortOrder = args.sortOrder ? args.sortOrder : this.sortOrder;
        this.taskStates = stateConfiguration.taskStates;
        this.taskUserStates = stateConfiguration.taskUserStates;
        const responsible =
            this.displayedResponsibleType === SegmentType.ALL || args.fetchAllTasks ? null : this.loggedInUser.username;
        try {
            this.taskTableConfig.list = await this.taskService.fetchTasks(undefined, {
                offset: this.offset,
                limit: this.limit,
                sortBy: this.sortBy,
                sortOrder: this.sortOrder,
                exerciseSessionStates: this.taskStates,
                exerciseSessionUserStates: this.taskUserStates,
                responsible: responsible,
                textSearchTerm: this.searchTermTask,
                onlyWithCalendarEvent: this.displayedTaskType === SegmentType.CALENDAR_EVENT,
                tagUuids: args.taskOverviewFilterSetting?.tags?.map((i) => i.uuid),
                iconFilter: args.taskOverviewFilterSetting?.icons,
                includeStateChanges: true,
            });
            // Falls der offset in der zwischenzeit ungültig geworden ist erneut abrufen mit
            // gültigem offset
            if (
                this.taskTableConfig.list.offset > this.limit &&
                this.taskTableConfig.list.offset >= this.taskTableConfig.list.total
            ) {
                this.offset = Math.floor(this.taskTableConfig.list.total / this.limit);
                this.fetchFilteredTaskList({});
                return;
            }
            if (this.displayedTaskType === SegmentType.ACTIVE && !responsible) {
                this.activeTaskCount$.emit(this.taskTableConfig.list.total);
            }
            for (const task of this.taskTableConfig.list?.items) {
                task.state = TaskOverviewComponent.mapState(task.exerciseSessionUserState);
                task.stateChanges.sort(
                    (a, b) => new Date(a.created_at).getUTCDate() - new Date(b.created_at).getUTCDate(),
                );
                const tableConfigDueDate = this.taskTableConfig.itemSettings.find((tc) => tc.id === 'dueDate');
                task.dueDate = task.appointment?.delayedTime
                    ? endOfDay(subSeconds(parseISO(task.appointment?.delayedTime), 1)).toISOString()
                    : null;
                tableConfigDueDate.header = 'HEADER.DUE_AT';
                if (this.displayedTaskType === SegmentType.PLANNED && task.stateChanges) {
                    task.dueDate = task.appointment?.startTime
                        ? startOfDay(parseISO(task.appointment?.startTime)).toISOString()
                        : null;
                    tableConfigDueDate.header = 'HEADER.PLANNED_AT';
                }
                if (this.displayedTaskType === SegmentType.INACTIVE && task.stateChanges) {
                    const finishedStateChange = task.stateChanges
                        .sort()
                        .reverse()
                        .find((sc) => sc.newState === ExerciseSessionState.FINISHED);
                    task.dueDate = finishedStateChange?.created_at
                        ? parseISO(finishedStateChange.created_at.toString()).toISOString()
                        : null;
                    task.finished_at = finishedStateChange?.created_at ? finishedStateChange?.created_at : null;
                    tableConfigDueDate.header = 'HEADER.FINISHED_AT';
                }
                if (this.displayedTaskType === SegmentType.CANCELLED && task.stateChanges) {
                    const cancelledStateChange = task.stateChanges
                        .sort()
                        .reverse()
                        .find((sc) =>
                            [ExerciseSessionState.CANCELLED, ExerciseSessionState.PATIENT_CANCELLED].includes(
                                sc.newState,
                            ),
                        );
                    task.dueDate = cancelledStateChange?.created_at
                        ? parseISO(cancelledStateChange.created_at.toString()).toISOString()
                        : null;
                    tableConfigDueDate.header = 'HEADER.CANCELLED_AT';
                }
                if (this.displayedTaskType === SegmentType.EXPIRED && task.stateChanges) {
                    const expiredStateChange = task.stateChanges
                        .sort()
                        .reverse()
                        .find((sc) => sc.newState === ExerciseSessionState.FINISHED);
                    task.dueDate = expiredStateChange?.created_at
                        ? parseISO(expiredStateChange.created_at.toString()).toISOString()
                        : null;
                    tableConfigDueDate.header = 'HEADER.EXPIRED_AT';
                }
                if (task.exerciseSubType === ExerciseSubType.LEARNING) {
                    task.learningProgress =
                        task.lessons.filter((lesson) => lesson.isFinished).length / task.lessons.length;
                }

                task.patientName = `${task.concernedPerson.lastname}, ${task.concernedPerson.firstname}`;
                task.responsibleName = this.responsibleNamePipe.transform(task.responsibility);
                task.calendarEventStartDate = task.calendarEvent?.startTime;
            }
            for (const task of this.taskTableConfig.list.items) {
                task.actions = [];
                task.actions.push(new ActionButton(ActionItemType.BUTTON, 'Patientenakte öffnen', ActionType.UPDATE));
            }
            this.paginationOptions.set(
                { responsible: this.displayedResponsibleType, segment: this.displayedTaskType },
                { offset: this.offset, sortBy: this.sortBy, sortOrder: this.sortOrder },
            );
            this.isLoadingSuccess = true;
            this.isLoading = false;
        } catch (e) {
            this.log.error('Error in getPatientTaskList', e);
            this.isLoadingSuccess = false;
            this.isLoading = false;
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            this.taskTableConfig.list = new PaginatedResponse<TaskPreviewResource[]>();
        }
    }

    async openTaskDetailPage(actionEmitter: ActionEmitter<TaskPreviewResource>) {
        const task = await this.taskService.fetchTask(actionEmitter.item.id);
        if (actionEmitter.actionType === ActionType.PREVIEW) {
            const patient = await this.usersService.getUser(actionEmitter.item.concernedPerson.username);
            const modal = await this.modalCtrl.create({
                component: CaregiverTaskPreviewComponent,
                id: 'CaregiverTaskPreviewComponent',
                cssClass: 'task-preview-modal',
                componentProps: {
                    taskResource: task,
                    patient: patient,
                },
            });
            await modal.present();
            const { data } = await modal.onDidDismiss();
            if (data) {
                this.fetchFilteredTaskList({});
            }
        }
        if (actionEmitter.actionType === ActionType.UPDATE) {
            if (actionEmitter.item.concernedPerson.username) {
                await this.router.navigate(
                    [
                        RoutingSegment.MEMBER,
                        RoutingSegment.PATIENT_MANAGEMENT,
                        RoutingSegment.DETAIL,
                        actionEmitter.item.concernedPerson.username,
                    ],
                    { queryParams: { segment: SegmentType.TASK } },
                );
            }
        }
    }

    async openDetailPageForMobile(exerciseSession: TaskPreviewResource) {
        const item = new ActionEmitter<TaskPreviewResource>();
        item.item = exerciseSession;
        item.actionType = ActionType.PREVIEW;
        await this.openTaskDetailPage(item);
    }

    async openFilterModal() {
        const modal = await this.modalCtrl.create({
            component: TaskFilterModalComponent,
            cssClass: 'modal-tag-list-css',
            componentProps: {
                filterSettingName: 'TaskOverviewComponent',
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            this.fetchFilteredTaskList({});
        }
    }
}
