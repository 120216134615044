import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    ExerciseSessionState,
    ExerciseSessionUserState,
    ExerciseSubType,
    ExerciseType,
} from '../../../entities/exerciseSession';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { User, UserRoles } from '../../../../auth/entities/user';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { UserExerciseSessionsService } from '../../../services/user-exercise-sessions';
import { ModalController, PopoverController } from '@ionic/angular';
import { StyleService } from '../../../../common/services/style/style.service';
import { CurafidaAuthService } from '../../../../auth/services';
import { QuestionnaireTemplate } from '../../../../my-medax/entities/questionnaire-template';
import { TherapyMyMedaxService } from '../../../services/therapy-my-medax';
import { QuestionnaireType } from '../../../../my-medax/entities/questionnaire-type';
import { MyMedaxQuestionnaireSubmissionComponent } from '../../../../my-medax/components/my-medax-questionnaire-submission/my-medax-questionnaire-submission.component';
import { MyMedaxService } from '../../../../my-medax/services/my-medax.service';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { endOfDay, isAfter, isBefore, isFuture, isPast, startOfDay, subSeconds } from 'date-fns';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActionButton, ActionItemType, ActionMenuItem } from '../../../../table/entities/action-menu.item';
import { CurafidaPopoverSelectionComponent } from '../../../../table/components/curafida-popover-selection/curafida-popover-selection.component';
import { ActionType, DateFormat } from '../../../../table/entities/table';
import { MyMedaxQuestionnaireSubmission } from '../../../../my-medax/entities/my-medax-questionnaire-submission';
import { UserTaskPreview } from '../user-task-preview';
import { ModalAlertService } from '../../../../common/services/modal';
import { TranslateService } from '@ngx-translate/core';
import { QuestionnaireListModalComponent } from '../../../../my-medax/components/questionnaire-list-modal/questionnaire-list-modal.component';
import { PanelStyle } from '../../../../common/entities/panel.style';
import { Content } from '../../../entities/content';
import { TaskActionLinkName, TaskResource } from '../task.resource';
import { AuthorizationPipe } from '../../../../hateoas/authorization.pipe';
import { BrowserNavigationService } from '../../../../common/services/browser-navigation/browser-navigation.service';
import { TaskService } from '../../../services/task/task.service';
import { ArticulateContentsService } from '../../../services/articulate/articulate-contents.service';
import { SortBy, SortOrder } from '../../../../common/entities/paginated-response';
import { LoadingService } from '../../../../common/services/loading/loading.service';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lib-patient-task-preview',
    templateUrl: './patient-task-preview.component.html',
    styleUrls: ['./patient-task-preview.component.scss'],
})
export class PatientTaskPreviewComponent extends UserTaskPreview implements OnInit, OnDestroy {
    readonly DateFormat = DateFormat;
    taskResource: TaskResource;
    isMobile = false;
    patient: User;
    title = 'Aufgabe';
    ExerciseSessionState = ExerciseSessionState;
    ExerciseSessionUserState = ExerciseSessionUserState;
    form: QuestionnaireTemplate;
    loggedInUser: User;
    fromMyMedax = false;
    myMedaxQuestionnaireSubmissions: MyMedaxQuestionnaireSubmission[];
    userRoles: UserRoles[] = [];
    UserRoles = UserRoles;
    isStartTimeInFuture = false;
    isEndTimeInPast = false;
    showCalendarEvent = false;
    showAppointment = false;
    ExerciseSubType = ExerciseSubType;
    id: string;
    PanelStyle = PanelStyle;
    areContentVisible: boolean;
    hasContent = false;
    protected readonly log: Logger;
    protected readonly ExerciseType = ExerciseType;
    private updateInterval: any;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        protected toastService: ToastService,
        private authService: CurafidaAuthService,
        private styleService: StyleService,
        protected taskService: TaskService,
        private therapyMyMedaxService: TherapyMyMedaxService,
        private myMedaxService: MyMedaxService,
        private modalCtrl: ModalController,
        protected loggingService: LoggingService,
        private popoverController: PopoverController,
        protected modalAlertService: ModalAlertService,
        protected translateService: TranslateService,
        protected authorizationPipe: AuthorizationPipe,
        private browser: BrowserNavigationService,
        protected userExerciseSessionsService: UserExerciseSessionsService,
        readonly articulateContentsService: ArticulateContentsService,
        private readonly loadingService: LoadingService,
    ) {
        super(
            taskService,
            userExerciseSessionsService,
            toastService,
            modalAlertService,
            loggingService,
            translateService,
            authorizationPipe,
        );
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.isMobile = this.styleService.isMobile$;
    }

    async openMyMedax(): Promise<void> {
        let returnUri = window.location.href;
        returnUri = returnUri.slice(-1) === '/' ? returnUri.slice(0, -1) : returnUri;
        if (returnUri.includes('?')) {
            returnUri = returnUri + `&fromMyMedax=true&taskId=${this.taskResource.id}`;
        } else {
            returnUri = returnUri + `?fromMyMedax=true&taskId=${this.taskResource.id}`;
        }
        if (this.isMobile) {
            returnUri = null;
        }
        const fillLink = await this.therapyMyMedaxService.getMyMedaxFillLink(
            this.patient.username,
            this.taskResource.id.toString(),
            returnUri,
            QuestionnaireType.TASK,
        );
        this.browser.openTargetSelf(fillLink.url);
    }

    isFormButtonDisable() {
        if (
            this.taskResource.preconditionsSummary.hasPreconditions &&
            !this.taskResource.preconditionsSummary.arePreconditionsCompleted
        ) {
            return true;
        } else {
            return !this.authorizationPipe.transform(this.taskResource, 'myMedax', 'read');
        }
    }

    async initMyMedaxQuestionnaireSubmissions(): Promise<void> {
        try {
            const submissions = await this.myMedaxService.getQuestionnaireSubmissionsByUser(
                this.patient.username,
                0,
                null,
                SortBy.UPDATED_AT,
                SortOrder.DESC,
            );
            if (submissions?.items?.length > 0) {
                const contentUuid = this.taskResource?.exerciseSessionUserResults.find((x) =>
                    x.content?.mimeType?.includes('pdf'),
                )?.contentUuid;
                const versioningUuid = submissions.items.find(
                    (x) => x.pdfContent === contentUuid,
                )?.submissionVersioningUuid;
                this.myMedaxQuestionnaireSubmissions = submissions.items.filter(
                    (x) => x.submissionVersioningUuid === versioningUuid,
                );
                this.myMedaxQuestionnaireSubmissions.sort((a, b) => {
                    if (isBefore(new Date(a.created_at), new Date(b.created_at))) {
                        return 1;
                    } else if (isAfter(new Date(a.created_at), new Date(b.created_at))) {
                        return -1;
                    }
                    return 0;
                });
            }
        } catch (e) {
            this.log.error('Error in initMyMedaxQuestionnaireSubmissions', e);
            await this.toastService.showToast(this.translateService.instant('ERROR_LOADING_FORM'), IonicColor.danger);
        }
    }

    async showSubmissionVersions(): Promise<void> {
        if (this.myMedaxQuestionnaireSubmissions === undefined) {
            this.myMedaxQuestionnaireSubmissions =
                await this.therapyMyMedaxService.fetchMyMedaxQuestionnaireSubmissions(this.taskResource);
        }
        if (this.myMedaxQuestionnaireSubmissions?.length >= 0) {
            const modal = await this.modalCtrl.create({
                component: QuestionnaireListModalComponent,
                backdropDismiss: true,
                cssClass: 'full-width-modal',
                componentProps: {
                    myMedaxQuestionnaireSubmissions: this.myMedaxQuestionnaireSubmissions,
                    questionnaireTitle: this.myMedaxQuestionnaireSubmissions[0].title,
                },
            });
            await modal.present();
            const { data } = await modal.onDidDismiss();
            return data;
        }
    }

    async showQuestionnaireModal(): Promise<void> {
        this.myMedaxQuestionnaireSubmissions = await this.therapyMyMedaxService.fetchMyMedaxQuestionnaireSubmissions(
            this.taskResource,
        );
        const modal = await this.modalCtrl.create({
            component: MyMedaxQuestionnaireSubmissionComponent,
            backdropDismiss: true,
            cssClass: 'full-width-modal',
            componentProps: {
                myMedaxQuestionnaireSubmission: this.myMedaxQuestionnaireSubmissions[0],
                allowFillNewVersion: this.taskResource.exerciseSessionUserState != ExerciseSessionUserState.FINISHED,
                taskResource: this.taskResource,
                showIcons: !this.userRoles.includes(UserRoles.PATIENT),
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        return data;
    }

    async finishTask(): Promise<void> {
        this.loadingService.startLoadingModal();
        try {
            if (this.taskResource.exerciseSessionUserState === ExerciseSessionUserState.PLANNED) {
                await this.taskService.postTaskAction(this.taskResource, TaskActionLinkName.RESPONSIBLE_ACTIVATE);
            }
            await this.taskService.postTaskAction(this.taskResource, TaskActionLinkName.RESPONSIBLE_FINISH);
            this.taskResource.exerciseSessionUserState = ExerciseSessionUserState.FINISHED;
            await this.toastService.showToast(this.translateService.instant('FINISH_TASK_SUCCESS'), IonicColor.success);
        } catch (e) {
            this.log.error('Error in finishTask', e);
            await this.toastService.showToast(this.translateService.instant('FINISH_TASK_FAILURE'), IonicColor.danger);
        } finally {
            this.loadingService.stopLoadingModal();
            await this.dismissModal();
        }
    }

    async dismissModal(): Promise<void> {
        const queryParams: Params = { fromMyMedax: null, taskId: null };
        await this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams,
            queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
        await this.modalCtrl.dismiss(this.taskResource);
    }

    async openOtherAction(event: MouseEvent): Promise<void> {
        const actionItems: ActionMenuItem[] = [];
        actionItems.push(
            new ActionButton(ActionItemType.BUTTON, this.translateService.instant('CANCEL_TASK'), ActionType.CANCELED),
        );
        const popover = await this.popoverController.create({
            component: CurafidaPopoverSelectionComponent,
            cssClass: 'my-custom-class',
            translucent: true,
            event: event,
            componentProps: {
                actionItems: actionItems,
            },
        });
        await popover.present();
    }

    async ngOnInit(): Promise<void> {
        await this.initComponent();
        if (this.taskResource.exerciseSubType === ExerciseSubType.QUESTIONNAIRE) {
            this.myMedaxQuestionnaireSubmissions =
                await this.therapyMyMedaxService.fetchMyMedaxQuestionnaireSubmissions(this.taskResource);
        }
        if (this.taskResource.exerciseSubType === ExerciseSubType.FREETEXT) await this.initFreeTextContent();
        if (this.taskResource.exerciseSubType === ExerciseSubType.LEARNING) {
            this.articulateContentsService.init(this.taskResource, true);
        }
        this.hasContent = (this.taskResource?.contents || []).some(
            (content) =>
                !(
                    Content.isMyMedaxType(content.mimeType) ||
                    Content.isArticulateMimeType(content.mimeType) ||
                    Content.isArticulateZipMimeType(content.mimeType)
                ),
        );
    }

    showContent() {
        this.areContentVisible = !this.areContentVisible;
    }

    isExerciseActiveOrFinished() {
        return (
            this.taskResource.exerciseSessionUserState === ExerciseSessionUserState.ACTIVE ||
            (this.taskResource.exerciseSessionUserState === ExerciseSessionUserState.PLANNED &&
                !this.isStartTimeInFuture) ||
            this.taskResource.exerciseSessionUserState === ExerciseSessionUserState.FINISHED
        );
    }

    openArticulateCourse(event: MouseEvent): void {
        event.stopPropagation();
        this.addUpdateInterval();

        const browser = this.articulateContentsService.openArticulateCourseWithIab(
            this.articulateContentsService.url$.getValue().url.toString(),
        );

        browser.on('exit').subscribe(() => {
            this.removeUpdateInterval();
            this.articulateContentsService.updateLessons(this.taskResource);
            this.taskService.fetchTask(Number(this.id)).then((x) => (this.taskResource = x));
        });
    }

    removeUpdateInterval() {
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
        }
    }

    addUpdateInterval() {
        if (!this.updateInterval) {
            this.updateInterval = setInterval(async () => {
                this.articulateContentsService.updateLessons(this.taskResource);
                this.taskResource = await this.taskService.fetchTask(Number(this.id));
            }, 10000);
        }
    }

    ngOnDestroy(): void {
        this.removeUpdateInterval();
    }

    private async initComponent(): Promise<void> {
        this.taskResource = await this.taskService.fetchTask(Number(this.id));
        this.userRoles = this.authService.getSession()?.user.roles;
        if (this.taskResource.calendarEvent) this.showCalendarEvent = true;
        if (this.taskResource.appointment?.startTime || this.taskResource.appointment?.endTime) {
            this.showAppointment = true;
        }
        let startDate = startOfDay(new Date()).toISOString();
        let endDate = endOfDay(subSeconds(new Date(), 1)).toISOString();
        if (this.taskResource.appointment) {
            startDate = this.taskResource.appointment?.startTime;
            endDate = this.taskResource.appointment?.endTime;
        }
        if (startDate) this.isStartTimeInFuture = startDate !== '' ? isFuture(new Date(startDate)) : false;
        if (endDate) this.isEndTimeInPast = endDate !== '' ? isPast(new Date(endDate)) : false;
    }
}
