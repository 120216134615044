import { Component, Input } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { ButtonsModalDefinition, KioskActionButton } from '../../entities/buttons-modal-definition';
import { NgForOf, NgIf } from '@angular/common';
import { TranslationModule } from '../../../translation/translation.module';
import { CommonComponentsModule } from '../../../common/components/common-components.module';

@Component({
    standalone: true,
    selector: 'lib-kiosk-action-element-modal',
    templateUrl: './kiosk-action-element-modal.component.html',
    styleUrls: ['./kiosk-action-element-modal.component.scss'],
    imports: [IonicModule, NgIf, TranslationModule, NgForOf, CommonComponentsModule],
})
export class KioskActionElementModalComponent {
    @Input()
    buttonsModalDefinition: ButtonsModalDefinition;
    @Input()
    pauseTraining: boolean;

    constructor(private modalCtrl: ModalController) {}

    async dismissModal() {
        await this.modalCtrl.dismiss();
    }

    openAction(button: KioskActionButton) {
        this.modalCtrl.dismiss({ action: button.action });
    }
}
